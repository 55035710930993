import { createSlice } from '@reduxjs/toolkit';
import { LAYERS } from '../map-viewer/config.js';

//populates map click info from config products & adds point_forecast
function initializeMapClickInfo() {
    let newMapClickInfo = {};
    for(const product in LAYERS) {
        newMapClickInfo[product] = {coords: {x: 0, y: 0}, data: {}};
    }
    newMapClickInfo["point_forecast"] = {coords: {x: 0, y: 0}, data: {}};
    return newMapClickInfo;
}

/**
* mapClickSlice - handles all state variables related to map click info
*
* clickCoordinates (obj) - coordinates stored when user clicks map
* mapClickInfo (obj) - *coordinates only updated once data has been retrieved from URL*
*       - stores map click info for each product in the following format:
*       product1: {
*            coords: {x: 0, y: 0},
*            data: {*parsed JSON data retrieved from URL*}
*       },
*       product2: {
*            coords: {x: 0, y: 0},
*            data: {layer1: {*parsed JSON data retrieved from URL*}, layer2: {...}}
*       }
* mapClickURLs (obj) - *previously contained in mapClickInfo; separation enables 2 sets of coordinates for comparison to see if data must be retrieved*
*       - stores URLs generated on click for each product in the following format:
*       product1: {
*           urls: {*product info URL*},
*           coords: {x: 0, y: 0}
*       },
*       product2: {
*           urls: { layer1: {*layer1 info URL*}, layer2: {*layer2 info URL*} },
*           coords: {x: 0, y: 0}
*       }
* mapClickFeatures (obj) - stores Openlayers features retrieved from map click, used for WFS (i.e., surface obs) in this format:
*       { layer1 : [feature1, feature2, ...], layer2 : [], coords : {x: 0, y: 0}}
* mapClickEvent (OL Event) - stores most recent map click event, used to get the pixel and coordinates of the click location
* mapClickTime (timestamp) - *NOT CURRENTLY IN USE* stores time slider-selected time at most recent map click event to display in popup
* surfaceCurrentsLayersOn (array) - list of surface currents layers that are "on", this is used to display checkboxes accurately when region toggling
*       turns layers off - replaces old method of passing multiple states among the surface currents menu components
**/
export const mapClickSlice = createSlice({
    name: 'mapClick',
    initialState: {
        clickCoordinates: {x: 0, y: 0},
        mapClickInfo: initializeMapClickInfo(),
        mapClickURLs: {},
        mapClickFeatures: {},
        mapClickEvent: null,
        mapClickTime: null,
        surfaceCurrentsLayersOn: []
    },
    reducers: {
        mapClicked: (state, action) => {
            state.clickCoordinates = {x: action.payload.x, y: action.payload.y};
            state.mapClickEvent = action.payload.event;
        },
        mapClickInfoUpdated : (state, action) => {
            state.mapClickInfo = Object.assign(state.mapClickInfo, action.payload);
        },
        mapClickURLsUpdated : (state, action) => {
            state.mapClickURLs = action.payload;
        },
        mapClickFeaturesUpdated : (state, action) => {
            state.mapClickFeatures = action.payload;
        },
        mapClickTimeUpdated : (state, action) => {
            state.mapClickTime = action.payload;
        },
        surfaceCurrentsOn : (state, action) => {
            if(!state.surfaceCurrentsLayersOn.includes(action.payload)) {
                state.surfaceCurrentsLayersOn.push(action.payload);
            }
        },
        toggleSurfaceCurrents : (state, action) => {
            if(state.surfaceCurrentsLayersOn.includes(action.payload)) {
                state.surfaceCurrentsLayersOn = state.surfaceCurrentsLayersOn.filter(layer => layer !== action.payload);
            } else {
                state.surfaceCurrentsLayersOn.push(action.payload);
            }
        }
    },
    selectors: {
        getMapClickInfo: (state) => state.mapClickInfo,
        getMapClickCoords: (state) => state.clickCoordinates,
        getMapClickURLs: (state) => state.mapClickURLs,
        getMapClickEvent: (state) => state.mapClickEvent,
        getMapClickFeatures: (state) => state.mapClickFeatures,
        getSurfaceCurrentLayersOn: (state) => state.surfaceCurrentsLayersOn
    }
});

export const { mapClicked, mapClickInfoUpdated, mapClickURLsUpdated, mapClickFeaturesUpdated, surfaceCurrentsOn, toggleSurfaceCurrents } = mapClickSlice.actions;

export const { getMapClickInfo, getMapClickCoords, getMapClickURLs, getMapClickEvent, getMapClickFeatures, getSurfaceCurrentLayersOn } = mapClickSlice.selectors;

export default mapClickSlice.reducer;