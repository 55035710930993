import { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import SSTLegend from '../menu/legend-menu-items/sst-legend.js';
import { timeStringFromRequestUrl } from '../../utilities/utilities.js';
import { getDataMultiple } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const classes ={
    SSTFeatureInfoContainer: {
        padding: '10px',
          width: '90%',
    },

};

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) styleInfo - legend info that pertains only to SST (derived from top-level state obj: styleInfo)
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
**/
export default function SSTFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["sst"].data;
    const clickCoords = useSelector(getMapClickInfo)["sst"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["sst"].urls) return;

        const fetchData = async () => {
            getDataMultiple(mapClickURLs["sst"].urls)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"sst" : {"data" : data, "coords" : mapClickURLs["sst"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        if(clickCoords.x !== mapClickURLs["sst"].coords.x || clickCoords.y !== mapClickURLs["sst"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"sst" : {"data" : {features: []}, "coords" : mapClickURLs["sst"].coords}}));
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    const dataList = [];

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    // Get all selected layers
    const tempTypes = new Set();
    for (const layer in clickData) {
        if (clickData[layer].features) {
            if (props.olLayerState[layer].on) {
                tempTypes.add(layer);
            }
        }
    }

    for (const dataSource in clickData) {
        const dataObject = clickData[dataSource];
        const dataUrl = mapClickURLs["sst"][dataSource];

        let idxPos = null
        let titleText = null
        if (String(dataSource) === "nasa_sport_sea_surface_temperature") {
            titleText = "NASA SPoRT Global Sea Surface Temperature Analysis (Data of Opportunity)"
            idxPos = 0
        } else if (String(dataSource) === "global_sea_surface_temperature") {
            titleText = "NWS Global Near Sea Surface Temperature Analysis"
            idxPos = 1
        }

        let timeText = timeStringFromRequestUrl(dataUrl)

        if (tempTypes.has(String(dataSource)) ) {
            if (dataObject.features && dataObject.features.length > 0 && dataObject.numberReturned === 1) {
                if (dataObject.features[0].properties['Band1'] >= 25.5 && dataObject.features[0].properties['Band1'] !== 9999.0) {
                    const pointDataValue = Number.parseFloat(dataObject.features[0].properties['Band1']).toFixed(1)
                    const celsius = (dataObject.features[0].properties['Band1'] - 32) * 5/9;
                    let tempData =
                        <div key={idxPos}>
                            <Typography sx={{fontSize: '0.8em', 'fontWeight': 'bold'}} mb={1} align="center">{titleText}</Typography>
                            <Typography sx={{fontSize: '0.8em'}} mb={1} align="center">{pointDataValue} °F / {Number.parseFloat(celsius).toFixed(1)} °C   ({timeText})</Typography>
                        </div>
                    dataList[idxPos] = tempData
                } else {
                    let tempData =
                        <div key={idxPos}>
                            <Typography sx={{fontSize: '0.8em', 'fontWeight': 'bold'}} mb={1} align="center">{titleText}</Typography>
                            <Typography sx={{fontSize: '0.8em'}} mb={1} align="center">No feature info found at the specified location. Please try again in another location.</Typography>
                        </div>
                    dataList[idxPos] = tempData
                }
            } else {
                let tempData =
                    <div key={idxPos}>
                        <Typography sx={{fontSize: '0.8em', 'fontWeight': 'bold'}} mb={1} align="center">{titleText}</Typography>
                        <Typography sx={{fontSize: '0.8em'}} mb={1} align="center">No feature info found at the specified location. Please try again in another location.</Typography>
                    </div>
                dataList[idxPos] = tempData
            }
        }
    }


    if (dataList.length !== 0 && !isDataPending){
        return(
            <div style={classes.SSTFeatureInfoContainer} align='center'>
                { (props.styleInfo) ?
                    <SSTLegend
                        SSTStyleInfo={props.styleInfo}
                    />
                    : null
                }
                {dataList.map((item) => (item))}
                <br />

            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}
