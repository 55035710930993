import { Select, InputLabel, MenuItem, Typography } from '@mui/material';

import { SATELLITE_INFO } from '../../../config.js';
import LayerOptionsContainer from './layer-options-container.js';
//import SatelliteLegend from '../legend-menu-items/satellite-legend.js';
import LayerMenuItem from './layer-menu-item.js';

// NOTE: once we have developed more content for the menu item body contents, it may be useful to
// create a NowCoastMenuItemBody component that can be shared by all nowcoast menu items to make this
// formatting reusable for each menu item. (assuming they are uniform enough)
const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* SatelliteSelect: Selects current variable to be displayed for satellite
*
*   @prop (obj) currentLayer - Current geoserver layer in use by satellite
*   @prop (obj) updateOlLayerState - callback for updating olLayerState
*/

function SatelliteSelect(props) {
    const satelliteLayerTypeOptions = Object.entries(SATELLITE_INFO).map(([layerType, info],i) => {
        return (
            <MenuItem key={i} value={info.layerName}>{info.label}</MenuItem>
        );
    });

    return (
    <div>
        <InputLabel id="satellite_select_label" sx={{fontSize: '90%'}}>Select Imagery</InputLabel>

        <Select
            autoWidth
            size="small"
            labelId="satellite_select_label"
            value={props.currentLayer}
            onChange={(e) => props.updateOlLayerState({'layersParam': e.target.value, 'currentSource': e.target.value}, 'satellite')}
        >
            {satelliteLayerTypeOptions}
        </Select>
    </div>
    );
}

/**
* SatelliteLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) satelliteActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setSatelliteActive - callback for setting satelliteActive
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) styleInfo - obj containing style info for just satellite
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state, style and layer params, as well as current source
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/

function SatelliteLayerMenuItem(props){
    return (
        <LayerMenuItem
            layerName={"satellite"}
            label={"Weather Satellite"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.satelliteActive}
            setLayerIsActive={props.setSatelliteActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"satellite"}
                    infoContent={<><Typography variant="caption" style={{display: 'inline-block'}}>
                        Weather satellite imagery includes visible, water vapor, shortwave, longwave, and snow/ice composited from various geostationary satellites orbiting the globe. The NOAA/NESDIS Global Mosaic of Geostationary Satellite Imagery (GMGSI) composites imagery from Meteosat, GOES East/West, and Himawari-8 satellites at a 8 km resolution. For NOAA/NASA Geostationary Operational Environmental Satellites (GOES) nowCOAST composites imagery from GOES 16/18 for visible imagery at a 0.5 km resolution, shortwave infrared, longwave infrared, and water vapor are ~2 km and snow/ice ~1 km. The visible imagery indicates cloud cover and ice and snow cover. The shortwave, mid-infrared, indicates cloud cover and fog at night. The longwave, thermal infrared, depicts cloud cover and land/sea temperature patterns. The water vapor imagery indicates the amount of water vapor contained in the mid to upper levels of the troposphere, with the darker grays indicating drier air and the brighter grays/whites indicating more saturated air. The service is updated with new GOES East/West composite images every 5 minutes and GMGSI global composite images every hour.
                    </Typography><br /><Typography variant="caption">Source: NOAA/NASA/NESDIS</Typography></>}
                    capUrlsContent={props.capUrlsContent}
                >
                    <Typography>Geostationary Satellites</Typography>
                    <SatelliteSelect
                        currentLayer={props.olLayerState['satellite'].layersParam} // layersParam is a typically a string for products that dont need dynamicLayerList behavior
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default SatelliteLayerMenuItem;