import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

// SST uses one legend for all layers thus only one layer is parsed from capabilities
// the layer used as a key for the legend info is hard coded here, this should always match the parse found in capabilities-handler-wms.js
const LAYER_KEY = "nasa_sport_sea_surface_temperature";

/**
* Displays legend image with a label above it
*
* @prop (obj) SSTStyleInfo - legend info that pertains only to SST (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function SSTLegend(props){
    if (!props.SSTStyleInfo) return null;

    return (
        <ListSubheader component="div">
            <Typography sx={sxStyles.label}>Sea Surface Temperatures</Typography>
            <img style={{ margin: '0px 0px 0px -15px'}} alt="" src={props.SSTStyleInfo[LAYER_KEY][0].url}/>
        </ListSubheader>
    );
}

export default SSTLegend;