import { useState, useEffect } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import FeatureInfoAccordion from './feature-info-accordion';
import useHighlightLayer from './hooks/use-highlight-layer';

import { OL_ZINDEXES } from '../../config';
import { S100_PRODUCT_INFO } from '../../config';
import S100Legend from '../menu/legend-menu-items/s100-legend.js';
import { getDataMultiple } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const sxStyles = {
    tableContainer: {
        paddingLeft: '10px',
    },
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        border: 'none',
    },
    link: {
        fontSize: '0.9em',
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        }
    },
};

const classes = {
    S100FeatureInfoContainer: {
        padding: '10px',
    },
    S100LegendContainer: {
        maxWidth: '80%'
    },
};

/**
* Component for rendering results from getFeatureInfo wms requests for s100 product coverages
*
* @prop (obj) map - OL map object used for drawing feature info highlights
* @prop (obj) styleInfo - style info obj for S100
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
**/
export default function S100FeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["s100"].data;
    const clickCoords = useSelector(getMapClickInfo)["s100"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const [productsOpen, setProductsOpen] = useState({});

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.s100_highlight_layer);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["s100"].urls) return;

        const fetchData = async () => {
            getDataMultiple(mapClickURLs["s100"].urls)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"s100" : {"data" : data, "coords" : mapClickURLs["s100"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        setProductsOpen({});

        if(clickCoords.x !== mapClickURLs["s100"].coords.x || clickCoords.y !== mapClickURLs["s100"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"s100" : {"data" : {features: []}, "coords" : mapClickURLs["s100"].coords}}));
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    useEffect(() => {
        // Handle drawing of GeoJSON features returned by getFeatureInfo
        clearHighlight();
        if (Object.keys(clickData).length > 0) {
            for (const layer in clickData) {
                if (clickData && clickData[layer] && clickData[layer].features) {
                    for (const feature of clickData[layer].features) {
                        if(productsOpen[layer] && productsOpen[layer] === true) {
                            drawFeatureHighlight(feature);
                        }
                    }
                }
            }
        }

    }, [productsOpen, clearHighlight, clickData, drawFeatureHighlight]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    const S111_REGIONS = {
          'NYOFS': {
            'region': 'New York & New Jersey Harbor',
            'center': [-74.009, 40.551],
            'zoom': 10,
            'forecastCycles' : '05, 11, 17, 23 UTC',
            'forecastHorizon' : '54 Hours',
            'spatialRes': '300 m',
          },
          'GOMOFS': {
            'region': 'Gulf of Maine',
            'center': [-69.833, 42.223],
            'zoom': 8,
            'spatialRes': '700 m',
            'forecastCycles' : '00, 06, 12, 18 UTC',
            'forecastHorizon' : '72 Hours',
          },
          'CBOFS': {
            'region': 'Chesapeake Bay',
            'center': [-76.011423,  36.993435],
            'zoom': 11,
            'spatialRes': '500 m',
            'forecastCycles' : '00, 06, 12, 18 UTC',
            'forecastHorizon' : '48 Hours',
          },
          'DBOFS': {
            'region': 'Delaware Bay',
            'center': [-74.957, 38.862],
            'zoom': 10,
            'spatialRes': '500 m',
            'forecastCycles' : '00, 06, 12, 18 UTC',
            'forecastHorizon' : '48 Hours',
          },
          'NGOFS2': {
            'region': 'Northern Gulf of America',
            'center': [-90.925, 25.960],
            'zoom': 6,
            'spatialRes': '500 m',
            'forecastCycles' : '03, 09, 15, 21 UTC',
            'forecastHorizon' : '48 Hours',
          },
          'LEOFS': {
            'region': 'Lake Erie',
            'center': [-81.019, 42.281],
            'zoom': 9,
            'spatialRes': '500m',
            'forecastCycles' : '00, 06, 12, 18 UTC',
            'forecastHorizon' : '120 Hours',
          },
          'LMHOFS': {
            'region': 'Lake Michigan & Huron',
            'center': [-84.512, 44.190],
            'zoom': 6,
            'spatialRes': '500 m',
            'forecastCycles' : '00, 06, 12, 18 UTC',
            'forecastHorizon' : '120 Hours',
          },
          'LOOFS': {
            'region': 'Lake Ontario',
            'center': [-77.750, 43.658],
            'zoom': 9,
            'spatialRes': '500 m',
            'forecastCycles' : '00, 06, 12, 18 UTC',
            'forecastHorizon' : '120 Hours',
          },
          'LSOFS': {
            'region': '111 - Lake Superior',
            'center': [-87.539, 47.702],
            'zoom': 7,
            'spatialRes': '500 m',
            'forecastCycles' : '00, 06, 12, 18 UTC',
            'forecastHorizon' : '120 Hours',
          },
          'SFBOFS': {
            'region': 'San Francisco Bay',
            'center': [-122.489, 37.808],
            'zoom': 12,
            'spatialRes': '300 m',
            'forecastCycles' : '03, 09, 15, 21 UTC',
            'forecastHorizon' : '48 Hours',
          },
          'TBOFS': {
            'region': 'Tampa Bay',
            'center': [-82.773, 27.557],
            'zoom': 12,
            'spatialRes': '500 m',
            'forecastCycles' : '00, 06, 12, 18 UTC',
            'forecastHorizon' : '48 Hours',
          },
          'RTOFS EAST': {
            'region': 'US East',
            'center': [-72.285, 35.675],
            'zoom': 5,
            'spatialRes': '8.5 km',
            'forecastCycles' : '00 UTC',
            'forecastHorizon' : '96 Hours',
            },
          'RTOFS WEST': {
            'region': 'US West',
            'center': [-139.1, 38.958],
            'zoom': 4,
            'spatialRes': '8.5 km',
            'forecastCycles' : '00 UTC',
            'forecastHorizon' : '96 Hours',
         },
          'WCOFS': {
            'region': 'West Coast',
            'center': [-120.1, 38.958],
            'zoom': 4,
            'spatialRes': '4 km',
            'forecastCycles' : '03 UTC',
            'forecastHorizon' : '72 Hours',
             },

         };

    const s102HarborReturnedFeatures = Object.entries(clickData).map(([layerName, info],i) => {

        if (!clickData[layerName] || !props.olLayerState[layerName].on) {
            return null;
        }

        if (clickData[layerName].features && clickData[layerName].features.length > 0 && layerName.includes('harbor')) {

            let url = clickData[layerName].features[0].properties.s102_url
             if (!url){
                return null;
            }
            const cellname = clickData[layerName].features[0].properties.cellname

            let region = ""
            let version = ""
            let aws_region = ""
            let resolution = ""

            if (url.includes("s102")) {
               const s102_url_array = url.split("/")
               if (s102_url_array.length > 0) {
                    version = s102_url_array[3].charAt(0).toUpperCase() + s102_url_array[3].slice(1);
                    aws_region = s102_url_array[5]
                    region = s102_url_array[5].replaceAll("_", " ");
               }

                if (cellname.includes('US4')) {
                    resolution = "16 m"
                }
                if (cellname.includes('US5')) {
                    resolution = "4 m"
                }

            }

            const s102_url = url
            const link_name = "S102US00_" + cellname + ".h5"
            const s102_region_url = "https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/" + aws_region + "/dcf2/tiles/"

            return(
                <FeatureInfoAccordion key={0}
                    styleOverride={{borderLeft: "10px solid " + S100_PRODUCT_INFO["s100_harbor_coverage"]["color"]}}
                    featureName={<Typography sx={{lineHeight: 1}} variant='overline'>Available S-102 Data (4 m)</Typography>}
                    setFeatureIsOn={() => setProductsOpen((prevState) => {return({...prevState, "s100_harbor_coverage": !prevState["s100_harbor_coverage"]})})}
                >
                <div style={classes.S100FeatureInfoContainer} key={i}>
                    <TableContainer sx={sxStyles.tableContainer}>
                    <Table size="small">
                        <TableBody>
                           <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>S-102 Version:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{version}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>ENC Cellname:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{cellname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Region:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{region}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Horizontal Resolution:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{resolution}</TableCell>
                            </TableRow>
                            <TableRow>
                                 <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Direct Download</TableCell>
                                <TableCell><Link href={s102_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">{link_name}</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                 <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Access S-100 Exchange Catalogue</TableCell>
                                <TableCell ><Link href={s102_region_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">Discovery Metadata</Link></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
            </FeatureInfoAccordion>
            );
            } else { return null; }
       });

    const s102ApproachReturnedFeatures = Object.entries(clickData).map(([layerName, info],i) => {

        if (!clickData[layerName] || !props.olLayerState[layerName].on) {
            return null;
        }

        if (clickData[layerName].features && clickData[layerName].features.length > 0 && layerName.includes('approach')) {

            let url = clickData[layerName].features[0].properties.s102_url
             if (!url){
                return null;
            }
            const cellname = clickData[layerName].features[0].properties.cellname

            let region = ""
            let version = ""
            let aws_region = ""
            let resolution = ""

            if (url.includes("s102")) {
               const s102_url_array = url.split("/")
               if (s102_url_array.length > 0) {
                    version = s102_url_array[3].charAt(0).toUpperCase() + s102_url_array[3].slice(1);
                    aws_region = s102_url_array[5]
                    region = aws_region.replaceAll("_", " ");
               }

                if (cellname.includes('US4')) {
                    resolution = "16 m"
                }
                if (cellname.includes('US5')) {
                    resolution = "4 m"
                }

            }

            const s102_url = url
            const link_name = "S102US00_" + cellname + ".h5"
            const s102_region_url = "https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/" + aws_region + "/dcf2/tiles/"

            return(
                <FeatureInfoAccordion key={0}
                    styleOverride={{borderLeft: "10px solid " + S100_PRODUCT_INFO["s102_approach_coverage"]["color"]}}
                    featureName={<Typography sx={{lineHeight: 1}} variant='overline'>Available S-102 Data (16 m)</Typography>}
                    setFeatureIsOn={() => setProductsOpen((prevState) => {return({...prevState, "s100_approach_coverage": !prevState["s100_approach_coverage"]})})}
                >
                <div style={classes.S100FeatureInfoContainer} key={i}>
                    <TableContainer sx={sxStyles.tableContainer}>
                    <Table size="small">
                        <TableBody>
                           <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>S-102 Version:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{version}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>ENC Cellname:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{cellname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Region:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{region}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Horizontal Resolution:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{resolution}</TableCell>
                            </TableRow>
                            <TableRow>
                                 <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Direct Download</TableCell>
                                <TableCell><Link href={s102_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">{link_name}</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                 <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Access S-100 Exchange Catalogue</TableCell>
                                <TableCell><Link href={s102_region_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">Discovery Metadata</Link></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
                </FeatureInfoAccordion>
            );
            } else { return null; }
       });

    const s111GeneralReturnedFeatures = Object.entries(clickData).map(([layerName, info],i) => {

        if (!clickData[layerName] || !props.olLayerState[layerName].on) {
            return null;
        }

        if (clickData[layerName].features && clickData[layerName].features.length > 0 && layerName.includes('general')) {
            let url = clickData[layerName].features[0].properties.s111_url
            if (!url) {
               return null;
            }

            let system = ""
            let version = ""
            let fcst_horizon = ""
            let fcst_cycle = ""
            let resolution = ""
            let region = ""
            let exchange_set_url = ""

            if (url.includes("s111")) {
               const s111_url_array = url.split("/")
               if (s111_url_array.length > 0){
                   system = s111_url_array[5].replaceAll("_", " ").toUpperCase();
                   exchange_set_url = "https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/" + s111_url_array[5] + "/"
                   version = s111_url_array[3].replace("index.html#ed", "Ed ")
                   fcst_horizon = S111_REGIONS[system]['forecastHorizon']
                   fcst_cycle = S111_REGIONS[system]['forecastCycles']
                   resolution = S111_REGIONS[system]['spatialRes']
                   region = S111_REGIONS[system]['region']
                   if (system === 'RTOFS WEST' || system === 'RTOFS EAST' ) {
                      system ='GRTOFS'
                   }
               }

            }

            let s111_url = url
            let s111_overlap_url = ""
            let overlap_system = ""

            if (url.includes(",")) {
                url = url.split(",")
                s111_overlap_url = url[1]
                s111_url = url[0]
                overlap_system = s111_overlap_url.split("/")[5].toUpperCase();
            }

            const cellname = clickData[layerName].features[0].properties.cellname
            //const uscg = clickData[layerName].features[0].properties.uscg_distr
            const product_type = "Model Forecast Guidance"
            const depth = "4.5 m"

            return(
                <FeatureInfoAccordion key={0}
                    styleOverride={{borderLeft: "10px solid " + S100_PRODUCT_INFO["s100_general_coverage"]["color"]}}
                    featureName={<Typography sx={{lineHeight: 1}} variant='overline'>Available S-111 Data (4 - 8.5 km)</Typography>}
                    setFeatureIsOn={() => setProductsOpen((prevState) => {return({...prevState, "s100_general_coverage": !prevState["s100_general_coverage"]})})}
                >
                <div style={classes.S100FeatureInfoContainer} key={i}>
                    <TableContainer sx={sxStyles.tableContainer}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>S-111 Version:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{version}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>ENC Cellname:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{cellname}</TableCell>
                            </TableRow>
                             <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Forecast System:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{system}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Region:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{region}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Forecast Cycles:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{fcst_cycle}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Forecast Horizon:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{fcst_horizon}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Horizontal Resolution:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{resolution}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Product Type:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{product_type}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Depth:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{depth}</TableCell>
                            </TableRow>
                            <TableRow>
                                 <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Access Regional Tiles</TableCell>
                                <TableCell><Link href={s111_url} sx={sxStyles.link}  align="left" target="_blank" rel="noopener noreferrer">{system}</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Access Overlapping Regional Tiles</TableCell>
                                <TableCell><Link href={s111_overlap_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">{overlap_system}</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                 <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Access S-100 Exchange Catalogue</TableCell>
                                <TableCell><Link href={exchange_set_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">Discovery Metadata</Link></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
                </FeatureInfoAccordion>
                );
            } else { return null; }
       });

    const s111ApproachReturnedFeatures = Object.entries(clickData).map(([layerName, info],i) => {

        if (!clickData[layerName] || !props.olLayerState[layerName].on) {
            return null;
        }

        if (clickData[layerName].features && clickData[layerName].features.length > 0 && layerName.includes('approach')) {
            let url = clickData[layerName].features[0].properties.s111_url
            if (!url) {
               return null;
            }

            let system = ""
            let version = ""
            let fcst_horizon = ""
            let fcst_cycle = ""
            let resolution = ""
            let region = ""
            let exchange_set_url = ""

            if (url.includes("s111")) {
               const s111_url_array = url.split("/")
               if (s111_url_array.length > 0){
                   system = s111_url_array[5].replaceAll("_", " ").toUpperCase();
                   exchange_set_url = "https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/" + s111_url_array[5] + "/"
                   version = s111_url_array[3].replace("index.html#ed", "Ed ")
                   fcst_horizon = S111_REGIONS[system]['forecastHorizon']
                   fcst_cycle = S111_REGIONS[system]['forecastCycles']
                   resolution = S111_REGIONS[system]['spatialRes']
                   region = S111_REGIONS[system]['region']
               }

            }

            let s111_url = url
            let s111_overlap_url = ""
            let overlap_system = ""

            if (url.includes(",")) {
                url = url.split(",")
                s111_overlap_url = url[1]

                s111_url = url[0]
                overlap_system = s111_overlap_url.split("/")[5].toUpperCase();
            }

            const cellname = clickData[layerName].features[0].properties.cellname
            //const uscg = clickData[layerName].features[0].properties.uscg_distr
            const product_type = "Model Forecast Guidance"
            const depth = "4.5 m"

            return(
                <FeatureInfoAccordion key={0}
                    styleOverride={{borderLeft: "10px solid " + S100_PRODUCT_INFO["s111_approach_coverage"]["color"]}}
                    featureName={<Typography sx={{lineHeight: 1}} variant='overline'>Available S-111 Data (300 - 700 m)</Typography>}
                    setFeatureIsOn={() => setProductsOpen((prevState) => {return({...prevState, "s100_approach_coverage": !prevState["s100_approach_coverage"]})})}
                >
                <div style={classes.S100FeatureInfoContainer} key={i}>
                    <TableContainer sx={sxStyles.tableContainer}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>S-111 Version:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{version}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>ENC Cellname:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{cellname}</TableCell>
                            </TableRow>
                             <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Forecast System:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{system}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Region:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{region}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Forecast Cycles:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{fcst_cycle}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Forecast Horizon:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{fcst_horizon}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Horizontal Resolution:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{resolution}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Product Type:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{product_type}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Depth:</TableCell>
                                <TableCell align="left" sx={sxStyles.tableCell}>{depth}</TableCell>
                            </TableRow>
                            <TableRow>
                                 <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Access Regional Tiles</TableCell>
                                <TableCell><Link href={s111_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">{system}</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Access Overlapping Regional Tiles</TableCell>
                                <TableCell><Link href={s111_overlap_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">{overlap_system}</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                 <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Access S-100 Exchange Catalogue</TableCell>
                                <TableCell><Link href={exchange_set_url} sx={sxStyles.link} align="left" target="_blank" rel="noopener noreferrer">Discovery Metadata</Link></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
              </FeatureInfoAccordion>
            );

            } else { return null; }
       });

    if (s111GeneralReturnedFeatures[0] || s111ApproachReturnedFeatures[1] || s102ApproachReturnedFeatures[1] || s102HarborReturnedFeatures[2] )  {

        return(
            <div style={classes.S100FeatureInfoContainer}>
                <Typography sx={{fontSize: '1em'}} color="#005194" align="center" fontWeight="bold">Precision Marine Navigation</Typography>
                <div style={{
                    marginLeft: 10, marginRight: 10, marginBottom: 5
                    }}
                >
                </div>
                <center><div style={classes.S100LegendContainer}>
                { (props.styleInfo) ?
                    <S100Legend
                        S100StyleInfo={props.styleInfo}
                    />
                    : null
                }
                </div></center>
                {s102HarborReturnedFeatures}
                {s102ApproachReturnedFeatures}
                {s111ApproachReturnedFeatures}
                {s111GeneralReturnedFeatures}
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }

}
