import { Typography, Grid } from '@mui/material';

const sxStyles = {
    header: {
        color: '#252525',
        fontSize: '90%',
        mb: '10px'
    },
    legendLabel: {
        color: '#252525',
        fontSize: '85%',
    }
};

const RASTER_FUNC_TO_LABEL = {
    "mrms_qpe:rft_1hr": "1 Hour",
    "mrms_qpe:rft_3hr": "3 Hour",
    "mrms_qpe:rft_6hr": "6 Hour",
    "mrms_qpe:rft_12hr": "12 Hour",
    "mrms_qpe:rft_24hr": "24 Hour",
    "mrms_qpe:rft_48hr": "48 Hour",
    "mrms_qpe:rft_72hr": "72 Hour",
}

/**
* Displays legend image with a label above it
*
* @prop (obj) MRMSQPEStyleInfo - Contains legend info for mrms qpe
* @prop (obj) olLayerState - Maps layer names from config.js to various state (on, currentSource, layersParam, stylesParam)
* @prop (bool) hideHeader - Optional - false or undefined to show header, true to not show it
*/
function MRMSQPELegend(props){
    if (!props.MRMSQPEStyleInfo) return null;

    const currentLayerRasterFunc = props.olLayerState.mrms_qpe.layersParam;
    const currentRasterFunc = currentLayerRasterFunc.split(':')[1];

    let ratInfo = (props.MRMSQPEStyleInfo.ratFeatures && props.MRMSQPEStyleInfo.ratFeatures[currentRasterFunc]) ? props.MRMSQPEStyleInfo.ratFeatures[currentRasterFunc] : null;
    let legendParts = null;

    if (ratInfo) {
        // Expect ratInfo to contain an array of length 24
        legendParts = props.MRMSQPEStyleInfo.ratFeatures[currentRasterFunc].map((legendLevel,i) => {
            let label = legendLevel.attributes.ClassName;
            if (i === 0) {
                // Remove " (inches)" from end of first ClassName
                label = legendLevel.attributes.ClassName.split('(')[0].slice(0,-1);
            }
            // remove .00 because it takes up so much space
            if (i < ratInfo.length-1) {
                let lowerBound = legendLevel.attributes.ClassName.split(' ')[0];
                let upperBound = legendLevel.attributes.ClassName.split(' ')[2];
                //let truncatedLowerBound = false
                //let truncatedUpperBound = false
                //if (lowerBound.split('.')[1] === "00") {
                //    lowerBound = lowerBound.split('.')[0];
                //    truncatedLowerBound = true;
                //}
                //if (upperBound.split('.')[1] === "00") {
                //    upperBound = upperBound.split('.')[0];
                //    truncatedUpperBound = true;
                //}
                label = lowerBound + " - " + upperBound;
            }else {
                // Special case for parsing last value
                let bound = legendLevel.attributes.ClassName.split(' ')[1].split('.')[0];
                const above = legendLevel.attributes.ClassName.split(' ')[0]
                label = above + " " + bound;
            }

            const redVal = legendLevel.attributes.Red;
            const greenVal = legendLevel.attributes.Green;
            const blueVal = legendLevel.attributes.Blue;
            const alphaVal = legendLevel.attributes.Alpha;
            const rgbaVal = "rgba(" + redVal + "," + greenVal + "," + blueVal + "," + alphaVal + ")";
            return(
                <Grid item key={i} xs={12} sx={{border: "1px solid black"}}>
                    <Typography align="center" sx={{
                        backgroundColor: rgbaVal,
                        fontSize: '70%',
                        fontWeight: 'bold'
                    }}>
                        {label}
                    </Typography>
                </Grid>
            );
        });
    }

    const header = (props.hideHeader) ? null : <Typography sx={sxStyles.header}>MRMS Quantitative Precipitation Estimates (QPE)</Typography>;

    return (
        <div style={{marginLeft:'0.5em' }}>
            {header}
            <Typography sx={sxStyles.legendLabel}>{RASTER_FUNC_TO_LABEL[currentLayerRasterFunc]} Accumulation (inches)</Typography>
            <Grid container spacing={0} sx={{ mt: '5px'}}>
                <Grid item xs={4}>
                    <Grid container direction="column" spacing={0}>
                        {(legendParts) ? legendParts.slice(0,8) : null}
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction="column" spacing={0}>
                        {(legendParts) ? legendParts.slice(8,16) : null}
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction="column" spacing={0}>
                        {(legendParts) ? legendParts.slice(16) : null}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );

}

export default MRMSQPELegend;