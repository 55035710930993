import { Typography, CircularProgress } from '@mui/material';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import WWALegend from '../legend-menu-items/wwa-legend.js';

// NOTE: once we have developed more content for the menu item body contents, it may be useful to
// create a NowCoastMenuItemBody component that can be shared by all nowcoast menu items to make this
// formatting reusable for each menu item. (assuming they are uniform enough)
const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* WWALayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) wwaActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setWwaActive - callback for setting wwaActive
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) styleInfo - Contains legend info just for WWA
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function WWALayerMenuItem(props){

    return (
        <LayerMenuItem
            layerName={"wwa"}
            label={"Active Alerts"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.wwaActive}
            setLayerIsActive={props.setWwaActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"wwa"}
                    infoContent={<Typography variant="caption" style={{display: 'inline-block'}}>
                     Latest National Weather Service (NWS) Watches, Warnings, Advisories, and Statements for hazardous weather,
                     marine weather, hydrologic, oceanographic, wildfire, air quality, and ecological conditions which may affect or
                     are presently affecting inland, lake, coastal, and offshore areas and also alerts from state agencies regarding air
                     quality and state and federal avalanche centers’ Avalanche Watches/Warnings disseminated by the NWS .
                     These various hazardous messages are issued on an event-driven basis. Data Source: NWS
                    </Typography>}
                    legendContent={
                        props.styleInfo ?
                            <WWALegend
                                WWAStyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                />
            </div>
        </LayerMenuItem>
    );
}

export default WWALayerMenuItem;