import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Chip, Grid, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import MapIcon from '@mui/icons-material/Map';
import LayersIcon from '@mui/icons-material/Layers';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import UpdateIcon from '@mui/icons-material/Update';
import PeopleIcon from '@mui/icons-material/People';
import RestoreIcon from '@mui/icons-material/Restore';
import SensorsIcon from '@mui/icons-material/Sensors';

import useDebounce from '../../utilities/hooks/use-debounce.js';
import { LAYERS, OCEAN_CATEGORY, FUTURE_CATEGORY, HAZARD_CATEGORY, PRESENT_CATEGORY, OVERLAYS_CATEGORY,GUIDANCE_CATEGORY, ANALYSIS_CATEGORY, OUTLOOKS_CATEGORY, LAYER_MENU_ITEM_DISPLAY_ORDER } from '../../config.js';

//Note: Could not get desired background color and icon color to work during style refactoring
const sxStyles = {
    filterChip: {
        padding: '2px',
        margin: '2px',
        //border: "1px solid 'primary.main'",
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'primary.main',
        '&:hover': {
            color: 'primary.main',
        },
    },
    hideSearchButton: {
        position: 'absolute',
        right: 15,
        top: 5,
        fontSize: 10,
        textDecoration: 'underline',
        cursor: 'pointer',
        ':hover': {
            color: 'primary.main',
        }
    },
};

const classes = {
    searchField: {
        width: '19em',
    }
};

/**
* Render a filter chip/button that will toggle a filter on/off
*
*   @prop (func) setFilterOn - Callback to turn on filter for this chip
*   @prop (bool) filterOn - true if this filter is active, else false
*   @prop (str) name - Name of filter to be displayed
*   @prop (component) icon - Mui icon to display
*/
function FilterChip(props) {
    let sxStylesOverrides = {};
    if (props.filterOn) {
        sxStylesOverrides = {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
        }
    }

    return(
        <Chip
            variant="outlined"
            size="small"
            label={props.name}
            onClick={() => props.setFilterOn()}
            icon={props.icon}
            sx={{
                ...sxStyles.filterChip,
                ...sxStylesOverrides
            }}
        />
    );
}

//backgroundColor: props.filterOn ? theme.palette.primary.main : null,

/**
* Render search text input field and manage its state and debouncing behavior
*   @prop (func) setSearchValue - Callback for setting search value to whatever the user inputs
*/
function SearchField(props) {
    // Store current value of search input field
    const [searchInput, setSearchInput] = useState("");

    // Handle debounce behavior for search input field
    // Execute the function passed only if searchValue does not change again in the next 750 ms
    useDebounce(() => {
        props.setSearchValue(searchInput);
    }, 500, [searchInput]);

    return(
        <input
            style={classes.searchField}
            type="text"
            onChange={(e) => setSearchInput(e.target.value)}
        />
    );
}

/**
* Render Search Input and chips/buttons as well as manage state of search inputs to filter proper layer-menu-items
*
* @prop (state) categoryFilter - persists category filter when switching between submenus
* @prop (func) setCategoryFilter - callback for changing category filter
* @prop (func) setFilteredMenuItems- Callback for setting filteredMenuItems array as search inputs change
*   (filteredMenuItems is an array of layer name strings indicating which layer menu items should be displayed in the menu when a filter is on)
* @prop (obj) layerToggles - object mapping layer names to their on/off(true/false) state
*/
function LayerSearchSubmenu(props) {
    const theme = useTheme();
//    // Originally I wanted filters to be used together so whatever is on is on, and if they overlap whatever
//    // but it turned out to feel unintuitive, its annoying to be have all on, click future, and have nothing change
//    // because all is still on. Also barring the use of some way to identify categorie membership within layer menu items,
//    // if you have two filters on like future and present at the same time, you dont know which layers are future and
//    // which are present which i found confusing/annoying.... SO, im trying out having the filters be mutually exclusive
//    // (but combined with the text search term always which has authority). And since they are mutually exclusive,
//    // It may as well just be a single state obj holding a string with the name of the filter... But saving this setup
//    // for now just in case i want to switch back
//    //
//    // Store state of "all" chip. If true, display all layer menu items
//    const [filterNone, setFilterNone] = useState(true);
//
//    // Store state of additional filter chips covering main product categories
//    const [filterOcean, setFilterOcean] = useState(false);
//    const [filterFuture, setFilterFuture] = useState(false);
//    const [filterHazard, setFilterHazard] = useState(false);
//    const [filterPresent, setFilterPresent] = useState(false);
//
//    // Store filter for user-added layers if any exist
//    const [filterCustom, setFilterCustom] = useState(false);

    // Store string containing the value from the search field (text input)
    // If this is anything other than an empty string, it will display as a chip
    // and enforce a filter to exactly match this value
    const [searchValue, setSearchValue] = useState("");

    // Store state for hiding the search controls to save space for the user
    // true for hidden, false for not hidden
    const [searchHidden, setSearchHidden] = useState(false);

    // Handle changes to search filters and call setFilteredMenuItems
    useEffect(() => {
        let newSearchResults = []
        newSearchResults = Object.keys(props.layerToggles).filter((productName) => {
            let matchFound = false;
            if (searchValue.length > 0) { // An active search filter was typed by the user
                if (productName.startsWith(searchValue.toLowerCase())) { // Keep this around for user-added, which will not have entries in LAYERS or search words for that matter
                    matchFound = true;
                } else if (productName in props.productInfo) {

                    for (const keyword of props.productInfo[productName].keywords) {
                        if (keyword.toLowerCase().startsWith(searchValue.toLowerCase())) {
                            matchFound = true;
                            break;
                        };
                    }
                }
                // No need to check category filters if it fails text/search filter
                if (!matchFound) return(false);
            }

            if (props.categoryFilter === "all") return(true); // Matches search term and "all" is selected so its a match

            if (productName in LAYERS) {
                if (props.categoryFilter === "ocean") return(LAYERS[productName].categories.includes(OCEAN_CATEGORY));
                if (props.categoryFilter === "future") return(LAYERS[productName].categories.includes(FUTURE_CATEGORY));
                if (props.categoryFilter === "hazard") return(LAYERS[productName].categories.includes(HAZARD_CATEGORY));
                if (props.categoryFilter === "present") return(LAYERS[productName].categories.includes(PRESENT_CATEGORY));
                if (props.categoryFilter === "overlays") return(LAYERS[productName].categories.includes(OVERLAYS_CATEGORY));
                if (props.categoryFilter === "guidance") return(LAYERS[productName].categories.includes(GUIDANCE_CATEGORY));
                if (props.categoryFilter === "analysis") return(LAYERS[productName].categories.includes(ANALYSIS_CATEGORY));
                if (props.categoryFilter === "outlooks") return(LAYERS[productName].categories.includes(OUTLOOKS_CATEGORY));
            }

            return(matchFound); // So far custom layers would return true here, they would only be matched based on layer_group name. I dont have a way to register their category yet since they are not going to be included in config.js (edit: idk if custom layers will work here. It was gonna be in layerToggles at some point but ended up managing custom layers with a separate object, customLayerInfo)
        });

        // Sort new search results according to display order set in config
        let sortedResults = []
        for (const layer of LAYER_MENU_ITEM_DISPLAY_ORDER) {
            if (newSearchResults.includes(layer)) {
                sortedResults.push(layer);
            }
        }

        props.setFilteredMenuItems(sortedResults);

    }, [searchValue, props.categoryFilter, props.setFilteredMenuItems]);

    // Takes bool - hidden - true to hide, false to show
    const hideSearchControls = (hidden) => {
        setSearchHidden(hidden);
        props.setSubMenuContainerHeight((hidden) ? 1.5 : 11.5);
    };

    if (searchHidden) {
        return (
            <Grid container sx={{padding: '0.8em', position: "relative"}}>
                <Typography
                    sx={sxStyles.hideSearchButton}
                    onClick={() => hideSearchControls(false)}
                >Show Search</Typography>
            </Grid>
        );
    }

    return(
        <Grid container sx={{padding: '5px', position: "relative"}}>
            <Typography
                sx={sxStyles.hideSearchButton}
                onClick={() => hideSearchControls(true)}
            >Hide Search</Typography>

            <Grid item sx={{padding: '5px'}}>
                <Typography align="left" sx={{fontSize: '0.8em'}}>Search for a Layer:</Typography>
                <SearchField setSearchValue={setSearchValue}/>
            </Grid>
            <Grid item sx={{padding: '5px'}}>
                <Typography sx={{fontSize: '0.8em'}} >Filter Your Search by Category:</Typography>
            </Grid>
            <Grid item sx={{padding: '5px'}}>
                <FilterChip name={"All"} filterOn={props.categoryFilter === "all"} setFilterOn={() => props.setCategoryFilter("all")} icon={<LayersIcon color="primary" sx={(props.categoryFilter === "all") ? {color: theme.palette.primary.contrastText} : {}} />} />
                <FilterChip name={"Coastal & Ocean Mapping"} filterOn={props.categoryFilter === "ocean"} setFilterOn={() => props.setCategoryFilter("ocean")} icon={<MapIcon color="primary" sx={(props.categoryFilter === "ocean") ? {color: theme.palette.primary.contrastText} : {}} />} />
                <FilterChip name={"Forecasts"} filterOn={props.categoryFilter === "future"} setFilterOn={() => props.setCategoryFilter("future")} icon={<PeopleIcon color="primary" sx={{color: (props.categoryFilter === "future") ? theme.palette.primary.contrastText : null }} />} />
                <FilterChip name={"Hazards"} filterOn={props.categoryFilter === "hazard"} setFilterOn={() => props.setCategoryFilter("hazard")} icon={<WarningIcon color="primary" sx={(props.categoryFilter === "hazard") ? {color: theme.palette.primary.contrastText} : {}} />} />
                <FilterChip name={"Present"} filterOn={props.categoryFilter === "present"} setFilterOn={() => props.setCategoryFilter("present")} icon={<SensorsIcon color="primary" sx={(props.categoryFilter === "present") ? {color: theme.palette.primary.contrastText} : {}} />} />
                <FilterChip name={"Guidance"} filterOn={props.categoryFilter === "guidance"} setFilterOn={() => props.setCategoryFilter("guidance")} icon={<UpdateIcon color="primary" sx={(props.categoryFilter === "guidance") ? {color: theme.palette.primary.contrastText} : {}} />} />
                <FilterChip name={"Overlays"} filterOn={props.categoryFilter === "overlays"} setFilterOn={() => props.setCategoryFilter("overlays")} icon={<LibraryAddIcon color="primary" sx={(props.categoryFilter === "overlays") ? {color: theme.palette.primary.contrastText} : {}} />} />
                <FilterChip name={"Analysis"} filterOn={props.categoryFilter === "analysis"} setFilterOn={() => props.setCategoryFilter("analysis")} icon={<RestoreIcon color="primary" sx={(props.categoryFilter === "analysis") ? {color: theme.palette.primary.contrastText} : {}} />} />
                <FilterChip name={"Outlooks"} filterOn={props.categoryFilter === "outlooks"} setFilterOn={() => props.setCategoryFilter("outlooks")} icon={<UpdateIcon color="primary" sx={(props.categoryFilter === "outlooks") ? {color: theme.palette.primary.contrastText} : {}} />} />
            </Grid>
            {
                (searchValue.length !== 0) ?
                <Grid item sx={{padding: '5px'}}>
                    <Typography sx={{fontSize: '0.8em'}}>Showing layers matching: {searchValue}...</Typography>
                </Grid>
                : null
            }
        </Grid>
    );
}

export default LayerSearchSubmenu;