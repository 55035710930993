import { Fragment, useState, useEffect, useCallback } from 'react';
import { Select, FormGroup, Typography, FormControl, FormControlLabel, MenuItem, Checkbox, Radio } from '@mui/material';
import LayerOptionsContainer from './layer-options-container.js';
import LayerMenuItem from './layer-menu-item.js';
import ConvectiveLegend from '../legend-menu-items/convective-legend.js';
import { CONVECTIVE_LAYERS } from '../../../config.js';

const classes = {
    menuItemBodyContainer: {
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
    },
    layerCheckBoxLabel: {
        fontSize: '80%'
    },
    menuProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250
        }
    }
};

const sxStyles = {
    inputLabel: {
        marginTop: -1
    },
    dayCheckBoxLabel: {
        fontSize: '80%',
        color: '#777',
    },
    layerCheckBox: {
        marginLeft: '1.3em',
        marginBottom: -1,
    },
    formControl: {
        marginBottom: 3,
        m: 1,
        hiddenLabel: 'true'
    },
    subcategory_header: {
        fontSize: '90%',
        color: '#181818'
    },
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
    radioButton: {
        ml: 1
    },
    checkbox: {
        ml: 3
    },
    formControlLabel: {
        mb: -1
    },
    checkbox2: {
        mt: -2,
        mb: -1
    }
}

/**
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/

function ConvectiveSelect(props){
    const [day4thru8MenuOpen, setDay4thru8MenuOpen] = useState(false);
    const [layerEmptyDay1, setLayerEmptyDay1] = useState(false);
    const [layerEmptyDay2, setLayerEmptyDay2] = useState(false);
    const [layerEmptyDay3, setLayerEmptyDay3] = useState(false);
    const [layerEmptyDay4to8, setLayerEmptyDay4to8] = useState(false);

    const getLayerNameList = (layers) => {
        const layerNameList = [];
        for(const layer of layers) {
            layerNameList.push(layer.layer_name);
        }
        return layerNameList;
    }

    const day1Layers = Object.values(CONVECTIVE_LAYERS).filter(obj => {return obj.day === "1"});
    const day2Layers = Object.values(CONVECTIVE_LAYERS).filter(obj => {return obj.day === "2"});
    const day3Layers = Object.values(CONVECTIVE_LAYERS).filter(obj => {return obj.day === "3"});
    const day4thru8Layers = Object.values(CONVECTIVE_LAYERS).filter(obj => {return parseInt(obj.day) > 3});

    const day1LayerNames = getLayerNameList(day1Layers);
    const day2LayerNames = getLayerNameList(day2Layers);
    const day3LayerNames = getLayerNameList(day3Layers);
    const day4thru8LayerNames = getLayerNameList(day4thru8Layers);
    const allLayers = [
        day1LayerNames,
        day2LayerNames,
        day3LayerNames,
        day4thru8LayerNames
    ];

    const getLayersOn = useCallback((layerList) => {
        const layersOn = [];
        for(const layer of layerList) {
            if(props.olLayerState[layer].on) {
                layersOn.push(layer);
            }
        }
        return layersOn;
    }, [props.olLayerState]);

    useEffect(() => {
        async function checkIfEmpty(layerNames, setLayerEmpty) {
            let layersOn = getLayersOn(layerNames);
            if(layersOn[0]) {
                let statusList = [];
                for(const layer of layersOn) {
                    var layerID = CONVECTIVE_LAYERS[layer].arc_id
                    var queryUrl = "https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/SPC_wx_outlks/MapServer/" + layerID + "/query?where=dn>0&returnCountOnly=true&f=json";
                    let response = await fetch(queryUrl)
                    let responseData = await response.text();
                    let parsedData = JSON.parse(responseData);
                    if(parsedData.count === 0) {
                        statusList.push(true);
                    } else { statusList.push(false); }
                }
                const empty = statusList.some((status) => status === false) ? false : true;
                setLayerEmpty(empty);
            }
        }
        checkIfEmpty(day1LayerNames, setLayerEmptyDay1);
        checkIfEmpty(day2LayerNames, setLayerEmptyDay2);
        checkIfEmpty(day3LayerNames, setLayerEmptyDay3);
        checkIfEmpty(day4thru8LayerNames, setLayerEmptyDay4to8);
    }, [day1LayerNames, day2LayerNames, day3LayerNames, day4thru8LayerNames, getLayersOn, props.olLayerState]);

    const getRadioOptions = (layers) => {
        const radioOptions = Object.entries(layers).map(([layer, info],i) => {
            if(info.layer_name.includes('categorical_day') || parseInt(CONVECTIVE_LAYERS[info.layer_name].day) > 3) {
                return (
                    <FormGroup key={i}>
                        <FormControlLabel sx={sxStyles.formControlLabel} key={i}
                        control={<Radio sx={sxStyles.radioButton}
                            value={[info.layer_name]}
                            onChange={(e) => handleRadioChange(e, layers)}
                            checked={props.olLayerState[info.layer_name].on}/>}
                        label={parseInt(CONVECTIVE_LAYERS[info.layer_name].day) > 3 ? info.specific_label : info.label}/>
                    </FormGroup>
                );
            } else if (info.layer_name.includes('significant_')) {
                let counterpartLayer = info.layer_name.replace("significant", "probabilistic")
                return (
                    <FormGroup key={i}>
                        <FormControlLabel sx={sxStyles.formControlLabel} key={info.layer_name}
                            control={<Radio sx={sxStyles.radioButton}
                                value={[info.layer_name, counterpartLayer]}
                                checked={(props.olLayerState[info.layer_name].on || props.olLayerState[counterpartLayer].on)}
                                onChange={(e) => handleRadioChange(e, layers)}/>}
                            label={info.hazard_group}/>
                        <FormControlLabel sx={sxStyles.formControlLabel} key={i}
                            control={<Checkbox sx={sxStyles.checkbox} value={info.layer_name}
                                checked={props.olLayerState[info.layer_name].on}
                                onChange={(e) => toggleSingleLayer(e, layers)}/>}
                            label="Significant"/>
                        <FormControlLabel sx={sxStyles.formControlLabel} key={i+1}
                            control={<Checkbox sx={sxStyles.checkbox} value={counterpartLayer}
                                checked={props.olLayerState[counterpartLayer].on}
                                onChange={(e) => toggleSingleLayer(e, layers)}/>}
                            label="Probabilistic"/>
                    </FormGroup>
                )
            } else {return null}
        });
        return radioOptions
    }

    const handleRadioChange = (e, layers) => {
        const {
            target: { value },
        } = e;
        let dayLayerNames = getLayerNameList(layers);
        let newLayerList = value.split(',');
        let newOlLayerState = Object.assign({}, props.olLayerState);
        for(const layer in newLayerList) {
            Object.assign(newOlLayerState, {
                [newLayerList[layer]] : {
                    ...props.olLayerState[newLayerList[layer]],
                    'on': true
                }
            });
        };
        for(const dayList of allLayers) {
            if(dayList.toString() === dayLayerNames.toString()) {
                let newList = dayLayerNames;
                for(const new_layer in newLayerList) {
                    newList.splice(newList.indexOf(newLayerList[new_layer]), 1);
                }
                newOlLayerState = turnLayersOff(newList, newOlLayerState);
            } else { newOlLayerState = turnLayersOff(dayList, newOlLayerState); }
        };
        if(CONVECTIVE_LAYERS[newLayerList[0]].day !== "1") {
            props.updateLayerToggles({'wwa' : false, 'mrms' : false});
        }
        props.updateOlLayerState(newOlLayerState);
    }

    const toggleSingleLayer = (e, layers) => {
        const {
            target: { value },
        } = e;
        let dayLayerNames = getLayerNameList(layers);
        let newLayerList = value.split(',');
        let newOlLayerState = Object.assign({}, props.olLayerState);
        for(const layer in newLayerList) {
            Object.assign(newOlLayerState, {
                [newLayerList[layer]] : {
                    ...props.olLayerState[newLayerList[layer]],
                    'on': !props.olLayerState[newLayerList[layer]].on
                }
            })
        };
        for(const dayList of allLayers) {
            if(dayList.toString() === dayLayerNames.toString()) {
                let newList = dayLayerNames;
                if(newLayerList[0].includes("wind") || newLayerList[0].includes("hail") || newLayerList[0].includes("tornado") || newLayerList[0].includes("severe")) {
                    if(parseInt(CONVECTIVE_LAYERS[newLayerList[0]].day) < 4) {
                        let counterpartLayer = '';
                        if(newLayerList[0].includes("significant")) {
                            counterpartLayer = newLayerList[0].replace("significant", "probabilistic");
                        } else {
                            counterpartLayer = newLayerList[0].replace("probabilistic", "significant");
                        }
                        newList.splice(newList.indexOf(counterpartLayer), 1);
                    }
                }
                newList.splice(newList.indexOf(newLayerList[0]), 1);
                newOlLayerState = turnLayersOff(newList, newOlLayerState);
            } else { newOlLayerState = turnLayersOff(dayList, newOlLayerState); }
        };
        props.updateOlLayerState(newOlLayerState);
        if(CONVECTIVE_LAYERS[newLayerList[0]].day !== "1") {
            props.updateLayerToggles({'wwa' : false});
            props.updateLayerToggles({'mrms' : false});
        }
    }

    const turnLayersOff = (dayLayerList, newOlLayerState) => {
        for(const layer in dayLayerList) {
            Object.assign(newOlLayerState, {
                [dayLayerList[layer]] : {
                    ...props.olLayerState[dayLayerList[layer]],
                    'on': false
                }
            })
        }
        return newOlLayerState;
    }

    const isOn = (layerList) => {
        let layerOn = false;
        for (const layer of layerList) {
            if(props.olLayerState[layer].on) {
                layerOn = true;
                break;
            }
        }
        return layerOn;
    }

    const getDataUnavailableText = (layerList) => {
        const layersOn = getLayersOn(layerList);
        if (layersOn.some((layer) => layer.includes("significant")) && !layersOn.some((layer) => layer.includes("probabilistic"))) {
            if (layersOn.some((layer) => layer.includes("severe"))) {
                return "No Significant Areas - Probability Too Low";
            } else { return "No Significant Areas - Probability < 10%" }
        } else if (layersOn.some((layer) => layer.includes("categorical"))) {
            return "No Significant Severe Weather Areas - Probability < 10%";
        } else { return "Not Available - Probability Too Low"; }
    }

    return (
        <div>
            <div>
            <FormControl sx={sxStyles.formControl}>
                <Select displayEmpty
                    autoWidth
                    size="small"
                    multiple
                    style={isOn(day1LayerNames) ? {backgroundColor:'#005194'} : {}}
                    value={getLayersOn(day1LayerNames)}
                    renderValue={(selected) => {
                        if(selected[0] === undefined || !isOn(day1LayerNames)) {
                            return <Typography sx={{fontWeight: 'light'}}>Day 1 Outlooks</Typography>
                        }
                        else {return <Typography sx={{fontWeight: 'bold', color: 'white'}}>Day 1 {CONVECTIVE_LAYERS[selected[0]].hazard_group}</Typography>}
                    }}
                >
                    <MenuItem disabled value="">
                        Day 1 Outlooks
                    </MenuItem>
                    {getRadioOptions(day1Layers)}
                </Select>
            </FormControl>
            {isOn(day1LayerNames) && layerEmptyDay1 ? 
                <Typography sx={{fontWeight: 'bold', fontSize: '80%'}}>{getDataUnavailableText(day1LayerNames)}</Typography> : null}
            </div>
            <div>
            <FormControl sx={sxStyles.formControl}>
                <Select displayEmpty
                    autoWidth
                    size="small"
                    multiple
                    style={isOn(day2LayerNames) ? {backgroundColor:'#005194'} : {}}
                    value={getLayersOn(day2LayerNames)}
                    renderValue={(selected) => {
                        if(selected[0] === undefined || !isOn(day2LayerNames)) {
                            return <Typography sx={{fontWeight: 'light'}}>Day 2 Outlooks</Typography>
                        }
                        else {return <Typography sx={{fontWeight: 'bold', color: 'white'}}>Day 2 {CONVECTIVE_LAYERS[selected[0]].hazard_group}</Typography>}
                    }}
                >
                    <MenuItem disabled value="">
                        Day 2 Outlooks
                    </MenuItem>
                    {getRadioOptions(day2Layers)}
                </Select>
            </FormControl>
            {isOn(day2LayerNames) && layerEmptyDay2 ?
                <Typography sx={{fontWeight: 'bold', fontSize: '80%'}}>{getDataUnavailableText(day2LayerNames)}</Typography> : null}
            </div>
            <div>
            <FormControl sx={sxStyles.formControl}>
                <Select displayEmpty
                    autoWidth
                    size="small"
                    multiple
                    style={isOn(day3LayerNames) ? {backgroundColor:'#005194'} : {}}
                    value={getLayersOn(day3LayerNames)}
                    renderValue={(selected) => {
                        if(selected[0] === undefined || !isOn(day3LayerNames)) {
                            return <Typography sx={{fontWeight: 'light'}}>Day 3 Outlooks</Typography>
                        }
                        else {return <Typography sx={{fontWeight: 'bold', color: 'white'}}>Day 3 {CONVECTIVE_LAYERS[selected[0]].hazard_group}</Typography>}
                    }}
                >
                    <MenuItem disabled value="">
                        Day 3 Outlooks
                    </MenuItem>
                    {getRadioOptions(day3Layers)}
                </Select>
            </FormControl>
            {isOn(day3LayerNames) && layerEmptyDay3 ?
                <Typography sx={{fontWeight: 'bold', fontSize: '80%'}}>{getDataUnavailableText(day3LayerNames)}</Typography> : null}
            </div>
            <div>
            <FormControl sx={sxStyles.formControl}>
                <Select displayEmpty
                    autoWidth
                    size="small"
                    multiple
                    open={day4thru8MenuOpen}
                    onClick={() => setDay4thru8MenuOpen(!day4thru8MenuOpen)}
                    style={isOn(day4thru8LayerNames) ? {backgroundColor:'#005194'} : {}}
                    value={getLayersOn(day4thru8LayerNames)}
                    renderValue={(selected) => {
                        if(selected[0] === undefined || !isOn(day4thru8LayerNames)) {
                            return <Typography sx={{fontWeight: 'light'}}>Day 4 to 8 Outlooks</Typography>
                        }
                        else {return <Typography sx={{fontWeight: 'bold', color: 'white'}}>{CONVECTIVE_LAYERS[selected[0]].specific_label}</Typography>}
                    }}
                >
                    <MenuItem disabled value="">
                        Day 4-8 Outlooks
                    </MenuItem>
                    {getRadioOptions(day4thru8Layers)}
                </Select>
            </FormControl>
            {isOn(day4thru8LayerNames) && layerEmptyDay4to8 ?
                <Typography sx={{fontWeight: 'bold', fontSize: '80%'}}>Predictability or Potential Too Low</Typography> : null}
            </div>
        </div>
    );
}

/**
* ConvectiveLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) convectiveActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setConvectiveActive - callback for setting s111Active
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function ConvectiveLayerMenuItem(props){

    const links = <>
        <Typography variant='caption'>External Services</Typography>
        <br />
        <Fragment>
            <Typography variant='caption' sx={sxStyles.links}>
                <a href={"https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/SPC_wx_outlks/MapServer/"}
                target="_blank" rel="noopener noreferrer">Storm Prediction Center Outlooks</a>
            </Typography>
            <br />
        </Fragment>
    </>;

    return (
        <LayerMenuItem
            layerName={"convective_outlooks"}
            label={"Convective Outlooks"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.convectiveActive}
            setLayerIsActive={props.setConvectiveActive}
            olLayerState={props.olLayerState}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"convective_outlooks"}
                    infoContent={<Typography variant="caption">Latest NWS Storm Prediction Center’s Convective Categorical and Probabilistic Outlooks for Days 1, 2, and 3
                     and the Days 4-8 Severe Weather Outlook. For Days 1-3, categorical types and probabilistic graphics are used to depict severe and general
                     thunderstorm threats across the contiguous U.S. along with links to text narratives. The six categorical types are: general thunderstorm threat areas
                     (TSTM-light green) and up to five severe thunderstorm risk threat levels (1-MRGL-dark green, 2-SLGT-yellow, 3-ENH-orange, 4-MDT-red, and
                     5-HIGH-magenta) based on the coverage and intensity of organized severe weather such as supercells, squall lines, and multicell thunderstorm complexes.
                     For Days 1 and 2, NWS provides individual severe probabilities outlooks for tornadoes, wind, and hail. With greater uncertainty about severe-storm
                     type into the future, the outlook for Day 3 only forecasts the combined probability of all three types of severe weather.<br></br>
                    For the Days 4-8 Severe Weather Outlook, probabilistic forecasts depict the severe weather threat with a separate graphic for each day, along with a text
                     narrative for the entire period. The categorical risk threat levels listed above are replaced by two thresholds: areas with 15% or greater chance of
                     well-organized severe thunderstorms are depicted in orange and areas with 30% or greater chance are depicted in red.<br></br>
                    Sources: NWS/NCEP Storm Prediction Center
                    </Typography>}
                    legendContent={
                        <ConvectiveLegend
                            olLayerState={props.olLayerState}
                        />
                    }
                    capUrlsContent={links}
                >
                    <ConvectiveSelect
                        updateLayerToggles={props.updateLayerToggles}
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default ConvectiveLayerMenuItem;
