import { Typography } from '@mui/material';


const sxStyles = {
    footer : {
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        fontSize: {
            lg: '90%',
            xs: '80%',
        },
        position: 'absolute',
        bottom: 0,
        right: '3.25em',
        paddingRight: '5px',
        paddingLeft: '5px',
        opacity: '65%',
    },
};

/*
* @prop zIndexVal (int) - Value to be used for z-index
*/
function NCFooter(props){
    return(
        <Typography sx={{...sxStyles.footer, zIndex: props.zIndexVal}}>Developed by NOAA/NOS/OCS</Typography>
    );
};

export default NCFooter;
