import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
        marginBottom: "10px"
    },
};

const classes = {
    legendIMG:{
        maxWidth: '55%',
        maxHeight: '95em',
        marginLeft: "6.5px"
    },
     approachlegendIMG:{
        maxWidth: '95%',
    },

};

/**
* Displays geoserver legend
* @prop (obj) S100StyleInfo - legend info that pertains only to S100 (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function S100Legend(props){

    if (!props.S100StyleInfo) return null;

    return (
        <ListSubheader component="div">
            <Typography sx={sxStyles.label}>NOAA S-100 Product Coverages</Typography>
             <img style={classes.legendIMG} align="left" alt="" src={props.S100StyleInfo["s100_general_coverage"][0].url + '&legend_options=dpi:270'} />
             <img style={classes.legendIMG} align="left" alt="" src={props.S100StyleInfo["s100_harbor_coverage"][0].url + '&legend_options=dpi:270'} />
             <img style={classes.approachlegendIMG} alt="" src={props.S100StyleInfo["s100_approach_coverage"][0].url + '&legend_options=dpi:270'} />
        </ListSubheader>
    );
}

export default S100Legend;