import { Select, InputLabel, MenuItem, Typography } from '@mui/material';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';

const sxStyles = {
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
};

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

const SOURCE_INFO = {
    ncds_source_ft: {
        type: 'ncds',
        units: 'Feet'
    },
    enc_source_ft: {
        type: 'enc',
        units: 'Feet',
    },
    ncds_source_m: {
        type: 'ncds',
        units: 'Meters'
    },
    enc_source_m: {
        type: 'enc',
        units: 'Meters'
    },
    ncds_source_ftm: {
        type: 'ncds',
        units: 'Fathoms'
    },
    enc_source_ftm: {
        type: 'enc',
        units: 'Fathoms',
    },
};

/**
* ChartSelect: Selects current chart to be displayed for nautical_charts
*
*   @prop (obj) currentSource - Current geoserver layer (ol source) in use by nautical_charts
*   @prop (obj) updateOlLayerState - callback for updating olLayerState
*/

function ChartSelect(props) {
    return (
    <div>
        <InputLabel id="chart_select_label" sx={{fontSize: '90%'}}>Select Chart Overlay</InputLabel>
        <Select
            autoWidth
            size="small"
            labelId="chart_select_label"
            value={SOURCE_INFO[props.currentSource].type}
            onChange={(e) => changeChartType(e, props)}
        >
            <MenuItem key={1} value={'ncds'}>NOAA Chart Display</MenuItem>
            <MenuItem key={2} value={'enc'}>ECDIS Display</MenuItem>
        </Select>
        <InputLabel id="unit_select_label" sx={{fontSize: '90%'}}>Select Units</InputLabel>
        <Select
            autoWidth
            size="small"
            labelId="unit_select_label"
            value={SOURCE_INFO[props.currentSource].units}
            onChange={(e) => changeUnitType(e, props)}
        >
            <MenuItem key={'ft'} value={"Feet"}>Feet</MenuItem>
            <MenuItem key={'m'} value={"Meters"}>Meters</MenuItem>
            <MenuItem key={'ftm'} value={"Fathoms"}>Fathoms</MenuItem>
        </Select>
    </div>
    );
}

function changeChartType (e, props) {
    const newSource = Object.entries(SOURCE_INFO)
        .find(([sourceName, attributes]) => attributes.units === SOURCE_INFO[props.currentSource].units && attributes.type === e.target.value)
    props.updateOlLayerState({'currentSource': newSource[0]}, 'nautical_charts')
}

function changeUnitType (e, props) {
    const newSource = Object.entries(SOURCE_INFO)
        .find(([soureName, attributes]) => attributes.units === e.target.value && attributes.type === SOURCE_INFO[props.currentSource].type)
    props.updateOlLayerState({'currentSource': newSource[0]}, 'nautical_charts')
}

/**
* NauticalChartsLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) nauticalChartsActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setNauticalChartsActive - callback for setting encActive
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state, style and layer params, as well as current source
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function NauticalChartsLayerMenuItem(props){

    let currentAbstract =
        <div>
            <Typography variant="caption">
                The external NOAA Chart Display Service renders NOAA ENC data with "traditional paper chart"
                symbols, labels, and color schemes familiar to those who have used NOAA paper nautical
                charts or the NOAA Custom Chart application.
            </Typography>
            <br /><br />
            <Typography variant="caption">
                The external ECDIS Display Service uses symbology developed by the International
                Hydrographic Organization (IHO) for the display of ENC data on Electronic
                Chart Display and Information Systems (ECDIS) that large ocean- going vessels
                and many smaller commercial ships use for navigation. This symbol set is
                commonly referred to by its IHO specification number, "S-52," or as "ECDIS symbology."
            </Typography>
        </div>

    const displayParamString = '{"ECDISParameters":{"version":"10.9.1","DynamicParameters":{"Parameter":[{"name":"DisplayDepthUnits","value":2}]}}}'

    const links = <>
        <Typography variant='caption' sx={sxStyles.links}>External Services</Typography><br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://gis.charttools.noaa.gov/arcgis/rest/services/MCS/ENCOnline/MapServer/exts/MaritimeChartService/MapServer" target="_blank" rel="noopener noreferrer">Electronic Nautical Chart Display ESRI REST</a></Typography><br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://gis.charttools.noaa.gov/arcgis/rest/services/MCS/ENCOnline/MapServer/exts/MaritimeChartService/WMSServer" target="_blank" rel="noopener noreferrer">Electronic Nautical Chart Display OGC WMS</a></Typography><br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://gis.charttools.noaa.gov/arcgis/rest/services/MCS/NOAAChartDisplay/MapServer/exts/MaritimeChartService/MapServer" target="_blank" rel="noopener noreferrer">NOAA Chart Display ESRI REST</a></Typography><br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://gis.charttools.noaa.gov/arcgis/rest/services/MCS/NOAAChartDisplay/MapServer/exts/MaritimeChartService/WMSServer" target="_blank" rel="noopener noreferrer">NOAA Chart Display OGC WMS</a></Typography><br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://gis.charttools.noaa.gov/arcgis/rest/services/MarineChart_Services/NOAACharts/MapServer/WMTS" target="_blank" rel="noopener noreferrer">NOAA Chart Display OGC WMTS</a></Typography>
        <br /><br />
        <Typography variant='caption'><i>Note: The ECDIS Display service and NOAA Chart Display service default depth units are in meters.</i></Typography>
        <Typography variant='caption'><i>To display depth units in feet, add the following parameter to the ArcGIS REST Service request:
            display_params={displayParamString}</i></Typography>
    </>;

    return (
        <LayerMenuItem
            layerName={"nautical_charts"}
            label={"Nautical Charts"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.nauticalChartsActive}
            setLayerIsActive={props.setNauticalChartsActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"nautical_charts"}
                    infoContent={currentAbstract}
                    capUrlsContent={links}

                >
                <Typography>External Nautical Chart Services</Typography>
                    <ChartSelect
                        currentSource={props.olLayerState['nautical_charts'].currentSource}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default NauticalChartsLayerMenuItem;