import { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { OFS_SFC_CURRENTS_INFO } from '../../config';
import S111Legend from '../menu/legend-menu-items/ofs-surface-currents-legend';
import { getDataMultiple } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const classes = {
    OFSFeatureInfoContainer: {
        padding: '10px',
    }
};

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) styleInfo - style info obj for s111/ofs sfc currents
* @prop (obj) olLayerState - state info for each layer
**/
export default function OFSFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["s111"].data;
    const clickCoords = useSelector(getMapClickInfo)["s111"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["s111"].urls) return;

        const fetchData = async () => {
            getDataMultiple(mapClickURLs["s111"].urls)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"s111" : {"data" : data, "coords" : mapClickURLs["s111"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        if(clickCoords.x !== mapClickURLs["s111"].coords.x || clickCoords.y !== mapClickURLs["s111"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"s111" : {"data" : {features: []}, "coords" : mapClickURLs["s111"].coords}}));
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    const ofsReturnedFeatures = Object.entries(props.olLayerState).map(([layerName, info],i) => {
        if (!clickData[layerName] || !props.olLayerState[layerName].on ) {
            return null;
        }

        if (clickData[layerName] && clickData[layerName].features && clickData[layerName].features.length > 0) {
            const speed = Number.parseFloat(clickData[layerName].features[0].properties.Band1).toFixed(0);
            const direction = Number.parseFloat(clickData[layerName].features[0].properties.Band2).toFixed(0);
            return (
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10, paddingBottom: 20
                    }}
                    key={i}
                >
                    <Typography>{OFS_SFC_CURRENTS_INFO[layerName].label}</Typography>
                    <Typography sx={{fontSize: '0.8em'}}>Speed (knots): {(speed === "-9999") ? "Missing/NaN" : speed}</Typography>
                    <Typography sx={{fontSize: '0.8em'}}>Direction (degrees): {(direction === "-9999") ? "Missing/NaN" : direction}</Typography>
                </div>
            );
        } else {
            return (
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10, paddingBottom: 20
                    }}
                    key={i}
                >
                    <Typography>{OFS_SFC_CURRENTS_INFO[layerName].label}</Typography>
                    <Typography sx={{fontSize: '0.8em'}}>No feature info returned for this time/location.</Typography>
                </div>
            );
        }
    });

    if (ofsReturnedFeatures.length > 0) {
        return(
            <div style={classes.OFSFeatureInfoContainer}>
                <Typography >Surface Currents</Typography>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10, paddingBottom: 10
                    }}
                >
                </div>
                <br />
                {ofsReturnedFeatures}
                <br />
                {   (props.styleInfo) ?
                    <S111Legend
                        S111StyleInfo={props.styleInfo}
                    />
                    : null
                }
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}
