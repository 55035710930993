import { useEffect, useRef } from "react";

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import { GeoJSON } from 'ol/format';

/**
* Hook that manages an OpenLayers layer drawing highlighted geojson shapes
*
* Args:
*   olMap - an OpenLayers map object on which to display the layer
*   zIndex - OpenLayers Z-index value to be used for the layer
*
* Returns:
*   clearHighlight (func): function that clears the layer
*   drawFeatureHighlight (func): function that draws any feature passed into it on the layer
*/

export default function useHighlightLayer(olMap, zIndex) {

    const map = useRef(olMap);

    const highlightSource = useRef(new VectorSource());
    const highlightLayer = useRef(new VectorLayer({
        style: new Style({
            stroke: new Stroke({
                color: 'rgba(255,255,0,1.0)',
                width: 2,
            }),
            fill: new Fill({
                color: 'rgba(0,0,0,0.15)',
            }),
        })
    }));

    useEffect(() => {
        const olMap = map.current;
        const highlight = highlightLayer.current;
        highlight.setSource(highlightSource.current);
        highlight.setZIndex(zIndex);
        olMap.addLayer(highlight);
        return(() => {olMap.removeLayer(highlight)});
    }, [highlightSource, highlightLayer, zIndex]);

    const clearHighlight = () => {
        highlightSource.current.clear();
    };

    const drawFeatureHighlight = (feature) => {
        const geojsonFeature = new GeoJSON().readFeature(feature);
        highlightSource.current.addFeature(geojsonFeature);
    }

    return([clearHighlight, drawFeatureHighlight]);
}
