import { useEffect, useRef } from "react";

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Circle as CircleStyle, Fill, Style} from 'ol/style';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';

/**
* Hook that manages an OpenLayers layer that shows the click dot (for marking latest map click)
*
* Args:
*   olMap - an OpenLayers map object on which to display the layer
*   zIndex - OpenLayers Z-index value to be used for the layer
*
* Returns:
*   clearDot (func): function that clears the layer
*   drawDot (func): function that draws the dot on the layer at the position of the coords passed to it ([x, y])
*/

export default function useDrawDot(olMap, zIndex) {

    const map = useRef(olMap);

    const dotSource = useRef(new VectorSource());
    const dotLayer = useRef(new VectorLayer({
        style: [
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: '#005194',
                    }),
                })
            }),
            new Style({
                image: new CircleStyle({
                    radius: 4,
                    fill: new Fill({
                        color: 'rgba(255,255,0,1.0)',
                    }),
                })
            })
        ]
    }));

    useEffect(() => {
        const olMap = map.current;
        const dot = dotLayer.current;
        dot.setSource(dotSource.current);
        dot.setZIndex(zIndex);
        olMap.addLayer(dot);
        return(() => {olMap.removeLayer(dot)});
    }, [dotSource, dotLayer, zIndex]);

    const clearDot = () => {
        dotSource.current.clear();
    };

    const drawDot = ([x, y]) => {
        dotSource.current.addFeature(new Feature({
            geometry: new Point([x, y]),
            })
        );
    }

    return([clearDot, drawDot]);
}
