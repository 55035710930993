import { useEffect, useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import FeatureInfoAccordion from './feature-info-accordion';
import useHighlightLayer from './hooks/use-highlight-layer';

import { OL_ZINDEXES, WWA_COLORS } from '../../config.js';
import { getData } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

/**
* Parse vtec codes. For detailed info on NWS' VTEC Codes see: https://www.weather.gov/vtec/
*
* Args:
*   code (`str`) - vtec code from tropical cyclones get feature info (ex. "/O.NEW.KLIX.FA.W.0002.220610T1943Z-220610T2145Z/")
*   NOTE: Code can be null (certain advisories/statements will not have vtec codes)
*
* Returns:
*   String containing just the Phenomena and Significance symbols from the vtec code, combined together
*       Significance values: W (Warning), A (Watch), Y (Advisory)
*       Phenomena values consist of 1-2 letter abbreviations for various weather events
*       In the example code above, the output would be FAW
*/
function parseVTEC(code) {
    try {
        if (code.length===3) {
            return(code)
        }
        else{
            const tokens = code.split(".");
            return(tokens[3] + tokens[4]);
        }
    } catch {
        return("n/a");
    }
}

const sxStyles = {
    tableContainer: {
    },
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        border: 'none',
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        }
    },
};

// JSS styles for non-mui components
const classes = {
    wwaFeatureInfoContainer: {
        marginTop: '10px',
    },
}

//memoized selector caches product types between renders
const selectProductTypes = createSelector([getMapClickInfo], (info) => {
    const data = info["wwa"].data;
    if(data && data.features) {
        // Get all unique product types
        const newProductTypes = new Set();
        for (const feature of data.features) {
            newProductTypes.add(feature.properties.prod_type);
        }
        return newProductTypes;
    } else { return new Set(); }
});

/**
* Component for retrieving & rendering results from getFeatureInfo wms requests
*
* @prop (obj) map - OL map object used for drawing feature info highlights
**/
export default function WWAFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["wwa"].data;
    const clickCoords = useSelector(getMapClickInfo)["wwa"].coords;
    const productTypes = useSelector(selectProductTypes);
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const [productsOpen, setProductsOpen] = useState({});

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.wwa_highlight_layer);
    const dispatch = useDispatch();

    // WWA info retrieved when click coordinates change
    useEffect(() => {
        if(!mapClickURLs["wwa"].url) return;

        const fetchData = async () => {
            getData(mapClickURLs["wwa"].url)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"wwa" : {"data" : data, "coords" : mapClickURLs["wwa"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        setProductsOpen({});
        if(clickCoords.x !== mapClickURLs["wwa"].coords.x || clickCoords.y !== mapClickURLs["wwa"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"wwa" : {"data" : {features: ["error"]}, "coords" : mapClickURLs["wwa"].coords}}));
                setDataPending(false);
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    useEffect(() => {
        let newProductsOpen = {};
        for(const type in productTypes) {
            newProductsOpen = Object.assign(newProductsOpen, {[type] : false});
        }
        setProductsOpen(newProductsOpen);
    }, [productTypes]);

    useEffect(() => {
        // Handle drawing of GeoJSON features returned by getFeatureInfo
        // updates only when product accordion is opened/closed
        clearHighlight();
        if (Object.keys(clickData).length > 0) {
            if (clickData && clickData.features ) {
                for (const feature of clickData.features) {
                    if (productsOpen[feature.properties.prod_type] && productsOpen[feature.properties.prod_type] === true) {
                        drawFeatureHighlight(feature);
                    }
                }
            }
        }
    }, [productsOpen, clearHighlight, clickData, drawFeatureHighlight]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    if (clickData.features && clickData.features.length === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location. Please try another location, toggle desired layers on/off, or adjust the time control, then try again.</Typography>
            </div>
        );
    }

    if (clickData.features && clickData.features[0] === "error") {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>An error occurred.</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>Please try again.</Typography>
            </div>
        );
    }

    let content = [...productTypes].map((prodType,index) => {
        let status = true;
        if(!productsOpen[prodType] || productsOpen[prodType] === false) {
            status = false;
        }
        let color;
        if(WWA_COLORS[CapitolToCamelCase(prodType)]) {
            color = WWA_COLORS[CapitolToCamelCase(prodType)].color;
        } else {
            color = "#FFFFFF";
        }
        return (
            <FeatureInfoAccordion key={index}
                styleOverride={{borderLeft: "10px solid " + color, boxShadow: "3px 1px 0px -2px rgba(0,0,0,0.15) inset"}}
                featureName={WWA_COLORS[CapitolToCamelCase(prodType)] ? prodType : "NWS Altered Alert - " + prodType}
                expanded={status}
                setFeatureIsOn={() => {setProductsOpen((prevState) => {return({...prevState, [prodType]: !status})})}}
            >
                <div style={{padding: '5px 0px 10px 0px'}}>
                {
                    clickData.features.map((feature, index) => {
                        if (prodType === feature.properties.prod_type) {
                            return(
                                <TableContainer sx={sxStyles.tableContainer} key={index}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow sx={sxStyles.tableHeader}>
                                                <TableCell><Link href={feature.properties.url} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Text Bulletin</Link></TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Issuance Time/Date:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.issuance}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Expected (or actual) Event Beginning Time/Date:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.onset}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Expected Event Ending Time/Date:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.ends}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Issuing NWS WFO or Center (ID):</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.wfo}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>VTEC Code:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{parseVTEC(feature.properties.vtec)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Message Type:</TableCell>
                                                <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.msg_type}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            );
                        } else { return null; }
                    })
                }
                </div>
            </FeatureInfoAccordion>
        )
    });

    let errorProducts = [];
    for(const prodType of productTypes) {
        if(!WWA_COLORS[CapitolToCamelCase(prodType)] && !errorProducts.includes(prodType)) {
            errorProducts.push(prodType)
        }
    }
    let errorMsg = "Unexpected NWS Altered Alert(s): " + errorProducts.join(", ") + ". Please contact ";

    return(
        <div style={classes.wwaFeatureInfoContainer}>
            {  errorProducts.length > 0 ?
                <TableRow>
                    <TableCell align="left">{errorMsg}<Link href={'mailto:nowcoast.team@noaa.gov'} target="_blank" rel="noopener noreferrer">nowcoast.team@noaa.gov</Link></TableCell>
                </TableRow>
                : null
            }
            {content}
        </div>
    );
}

/**
* Convert a string from "Capitol Case" to "camelCase"
* Takes string consisting of space-separated words that start with capital letters
* Returns the same string in camel case
*/
function CapitolToCamelCase(word) {
    let first_iter = true;
    let newWord = "";
    for (const part of word.split(" ")) {
        let newPart = part;
        if (first_iter) {
            newPart = part.toLowerCase();
            first_iter = false;
        }
        newWord = newWord + newPart;
    }
    return newWord;
}