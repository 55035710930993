import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import RestoreIcon from '@mui/icons-material/Restore';
import IconButton from '@mui/material/IconButton';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import GpsOffIcon from '@mui/icons-material/GpsOff';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';

const classes = {
    mapButton: {
        display: 'block',
        position: 'absolute',
        zIndex: '100',
        minWidth: 0,
        left: '0.333em',
        marginLeft: 1,
        marginRight: 1,
        padding: 0,
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderRadius: '0 0 2px 2px',
        boxShadow: '0px 0px 2px 2px rgba(255, 255, 255, 0.3)'
    }
};

const sxStyles = {
    bookmarkButton: {
        top: {
            xs: '2.5em',
            md: '3.5em'
        }
    },
    resetButton: {
        top: {
            xs: '3.75em',
            md: '4.5em'
        }
    },
    geolocationButton: {
        top: {
            xs: '5em',
            md: '5.5em'
        }
    },
    mapButtonSize: {
        width: {
            xs: 28,
            md: 22
        },
        height: {
            xs: 28,
            md: 22
        },
    },
    mapButtonIcon: {
        paddingBottom: 1,
        width: {
            xs: 24,
            md: 14
        },
        height: {
            xs: 30,
            md: 20
        }
    }
}

function BookmarkToggleButton(props) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        if(!props.bookmark) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const action = (
        <>
        <IconButton
            size="small"
            onClick={handleClose}
            color="inherit"
        >
            <CloseIcon fontSize="small" />
        </IconButton>
        </>
    );

    return (
        <>
            <CellPhoneButtonToolTip longTitle="Toggle Bookmarking">
                <IconButton
                    style={classes.mapButton}
                    sx={{...sxStyles.mapButtonSize, ...sxStyles.bookmarkButton}}
                    onClick={() => {props.toggleBookmarking(); handleClick()}}>
                    { props.bookmark ?
                        <TurnedInIcon sx={sxStyles.mapButtonIcon} />
                        :
                        <TurnedInNotIcon sx={sxStyles.mapButtonIcon} />
                    }
                </IconButton>
            </CellPhoneButtonToolTip>
            <Snackbar
                style={{zIndex: '5000', position: 'absolute', top: '10em', left: '0.333em'}}
                open={open}
                autoHideDuration={12000}
                onClose={handleClose}
                anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                message="Bookmarking mode is ON. You can now bookmark this page with your selected nowCOAST map settings (e.g., layers, transparency, geographic location) in your web browser."
                sx={{width: '12em'}}
                action={action}
            />
        </>
    );
}

function ResetPositionButton(props) {
    return (
        <CellPhoneButtonToolTip longTitle="Reset Map View">
            <IconButton
                style={classes.mapButton}
                sx={{...sxStyles.mapButtonSize, ...sxStyles.resetButton}}
                onClick={props.resetPosition}>
                <RestoreIcon sx={sxStyles.mapButtonIcon} />
            </IconButton>
        </CellPhoneButtonToolTip>
    );
}

function GeolocationButton(props) {
    const handleClick = () => {
        props.geolocate();
    };

    return (
        <>
            { props.geolocationError ?
                <CellPhoneButtonToolTip shortTitle="Location Disabled" longTitle="Location Disabled">
                    <IconButton style={classes.mapButton} sx={{...sxStyles.mapButtonSize, ...sxStyles.geolocationButton}}>
                        <GpsOffIcon style={{'color': 'black'}} sx={sxStyles.mapButtonIcon} />
                    </IconButton>
                </CellPhoneButtonToolTip>
                :
                <CellPhoneButtonToolTip longTitle="Your Location">
                    <IconButton
                        style={classes.mapButton}
                        sx={{...sxStyles.mapButtonSize, ...sxStyles.geolocationButton}}
                        onClick={handleClick}>
                        <MyLocationIcon style={{'color': 'white'}} sx={sxStyles.mapButtonIcon} />
                    </IconButton>
                </CellPhoneButtonToolTip>
            }
        </>
    );
}

/**
* Wrapper for conditionally rendering a tooltip only at cellphone size
*/
function CellPhoneButtonToolTip(props) {
    return(
        <IconLabelTooltip title={props.longTitle} placement="right-end">
            {props.children}
        </IconLabelTooltip>
    );
}

const IconLabelTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'black',
        color: 'white',
        boxShadow: theme.shadows[2],
        fontSize: '75%',
        '& *' : {
            color: 'white',
        }
    },
}));

export { BookmarkToggleButton, ResetPositionButton, GeolocationButton };