import { Fragment } from 'react';
import { Typography, InputLabel } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import ZoneForecastsLegend from '../legend-menu-items/zone-forecasts-legend.js';
import { ZONE_FORECAST_LAYERS } from '../../../config.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

const sxStyles = {
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
    subcategory_header: {
        fontSize: '90%',
        color: '#181818'
    },
    groupCheckBox: {
        marginLeft: '0.5em',
        marginBottom: -1,
    },
    groupHeader: {
        marginTop: 1,
        variant: "caption",
        align: 'left'
    }
};

/**
* BoundaryOptions: Check boxes for toggling layers from federal_agency_boundaries
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/

function BoundaryOptions(props) {

    let marineContent = Object.keys(ZONE_FORECAST_LAYERS).map((forecastType,index) => {
        if (ZONE_FORECAST_LAYERS[forecastType].group === 'marine' ) {
            return (
                <FormControlLabel key={ZONE_FORECAST_LAYERS[forecastType].id}
                    control={<Checkbox size="small" checked={props.olLayerState[forecastType].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState[forecastType].on}, forecastType)}} />}
                    label={<Typography variant="caption">{ZONE_FORECAST_LAYERS[forecastType].label}</Typography>}
                />
            )
        } else {
            return (null)
        }
    })

    let inlandContent = Object.keys(ZONE_FORECAST_LAYERS).map((forecastType,index) => {
        if (ZONE_FORECAST_LAYERS[forecastType].group === 'inland') {
            return (
                <FormControlLabel key={ZONE_FORECAST_LAYERS[forecastType].id}
                    control={<Checkbox size="small" checked={props.olLayerState[forecastType].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState[forecastType].on}, forecastType)}} />}
                    label={<Typography variant="caption">{ZONE_FORECAST_LAYERS[forecastType].label}</Typography>}
                />
            )
        } else {
            return (null)
        }
    })

    return(
        <FormGroup>
            <FormLabel><Typography variant="overline">External Services<br></br></Typography></FormLabel>
            <InputLabel sx={sxStyles.subcategory_header}>Marine Weather Forecasts</InputLabel>
            {getBeachMenu(props)}
            {marineContent}
            <InputLabel sx={sxStyles.subcategory_header}>Inland Weather Forecasts</InputLabel>
            {inlandContent}
        </FormGroup>
    );
}

/**
* ZoneForecastsLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) productActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setProductActive - callback for setting layer active
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state, style and layer params, as well as current source
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function ZoneForecastsLayerMenuItem(props){

    let zoneAbstracts = Object.keys(ZONE_FORECAST_LAYERS).map((forecastType,index) => {
        if( ZONE_FORECAST_LAYERS[forecastType].group !== 'beach' || (ZONE_FORECAST_LAYERS[forecastType].group === 'beach' && ZONE_FORECAST_LAYERS[forecastType].id < 1)) {
            return(
                <Fragment key={ZONE_FORECAST_LAYERS[forecastType].id}>
                    <Typography variant="caption">{ZONE_FORECAST_LAYERS[forecastType].abstract}</Typography>
                    <br /><br />
                </Fragment>
            )
        } else {
            return (null)
        }
    })

    const currentAbstract = <>
        {zoneAbstracts}
    </>;

    let zoneLinks = Object.keys(ZONE_FORECAST_LAYERS).map((forecastType,index) => {
        return (
            <Fragment key={ZONE_FORECAST_LAYERS[forecastType].id}>
                <Typography variant='caption' sx={sxStyles.links}>
                    {ZONE_FORECAST_LAYERS[forecastType].group === 'beach' ?
                    <a href={ "https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/marine_beachforecast_summary/MapServer/" + ZONE_FORECAST_LAYERS[forecastType].id}
                    target="_blank" rel="noopener noreferrer">{ZONE_FORECAST_LAYERS[forecastType].label}</a>
                    : <a href={ "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/MapServer/" + ZONE_FORECAST_LAYERS[forecastType].id}
                    target="_blank" rel="noopener noreferrer">{ZONE_FORECAST_LAYERS[forecastType].label}</a> }
                </Typography>
                <br />
            </Fragment>
        )
    })

    const links = <>
        <Typography variant='caption'>External Services</Typography>
        <br />
        {zoneLinks}
    </>;

    return (
        <LayerMenuItem
            layerName={"zone_forecasts"}
            label={"Zone Weather Forecasts"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.productActive}
            setLayerIsActive={props.setProductActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"zone_forecasts"}
                    infoContent={currentAbstract}
                    legendContent={
                        <ZoneForecastsLegend
                            olLayerState={props.olLayerState}
                        />
                    }
                    capUrlsContent={links}
                >
                    <BoundaryOptions
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default ZoneForecastsLayerMenuItem;

function getBeachMenu(props) {

    return (
        <FormGroup>
            <Typography variant="caption" sx={sxStyles.groupHeader} align='left'>Beach and Surf Zones</Typography>
            <FormGroup row={true}>
                <FormControlLabel
                    label={<Typography variant="caption">Today</Typography>}
                    control={
                        <Checkbox
                            checked={props.olLayerState["beach_forecasts_day1"].on}
                            onChange={() => {updateOlLayerStates(props, ["beach_forecasts_day1"], ["beach_forecasts_day2"])}}
                            size="small"
                        />
                    }
                    sx={sxStyles.groupCheckBox}
                />
                <FormControlLabel
                    label={<Typography variant="caption">Tomorrow</Typography>}
                    control={
                        <Checkbox
                            checked={props.olLayerState["beach_forecasts_day2"].on}
                            onChange={() => {updateOlLayerStates(props, ["beach_forecasts_day2"], ["beach_forecasts_day1"])}}
                            size="small"
                        />
                    }
                    sx={sxStyles.groupCheckBox}
                />
            </FormGroup>
        </FormGroup>
    )
}

function updateOlLayerStates(props, layerListOn, layerListOff) {
    for (const layer in layerListOn) {
        props.updateOlLayerState({'on': !props.olLayerState[layerListOn[layer]].on}, layerListOn[layer]);
    }
    for (const layer in layerListOff) {
        props.updateOlLayerState({'on': false}, layerListOff[layer])
    }
}