import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

// NBS provides multiple legends for a SINGLE layer via by changing style parameters (via dynamicStylesList)
// the layer used as a key for the legend info is hard coded here, this should always match the parse found in capabilities-handler-wms.js
// as well as well as styleLayerNames in config.js
const LAYER_KEY = "bathymetry";

/**
* Displays legend image with a label above it
*
* @prop (string) activeStyle - current style to be used
* @prop (obj) nbsStyleInfo - legend info that pertains only to nbs (derived from top-level state obj: styleInfo)
*
* NOTE: Dynamic Styles ARE enabled. The styleList and layerList are related by index. Each style should share an index with the layer in layerList
*       that the style belongs to --- but current implementation does not rely on layerList or styleList, but consider this concept with future revisions
*
* Since Dynamic layers are NOT enabled, a hardcoded key is still used here to select the layer whos style information should
* be used when accessing style info
*/
function NBSLegend(props){

    if (!props.nbsStyleInfo) return null;

    // Identify correct style information and render legend
    for (let styleInfo of props.nbsStyleInfo[LAYER_KEY]) {
        const nbsLegendOpt = '&legend_options=layout:horizontal';

        var title = props.activeStyle

        if (title.includes("_")) {
                const titleCaps = title.replaceAll("_"," ").split(' ').map(
                    w => w[0].toUpperCase() + w.substring(1).toLowerCase())
                   .join(' ');
                title = titleCaps
        }
        if (title.includes("Nbs")) {
               title = title.replace("Nbs", "")
        }
        var style_obj = props.nbsStyleInfo['bathymetry']
        var style_obj_url = ""

        if (props.activeStyle === 'nbs_elevation') {

            return (

                <ListSubheader component="div">
                    <Typography sx={sxStyles.label}>BlueTopo: {title}</Typography>
                    <img style={{ margin: '0px 0px 0px -18px'}} alt="" src={style_obj[0].url + nbsLegendOpt}/>
                </ListSubheader>
            );
        }

        if (props.activeStyle === 'nbs_uncertainty') {
            Object.keys(style_obj).forEach(function(key,index) {
                if (style_obj[index]['url'].includes('nbs_uncertainty')) {
                    style_obj_url = style_obj[index]['url']
                }

            });

            return (

                <ListSubheader component="div">
                    <Typography sx={sxStyles.label}>BlueTopo: {title}</Typography>
                    <img style={{ margin: '0px 0px 0px -18px'}} alt="" src={style_obj_url + nbsLegendOpt}/>
                </ListSubheader>
            );
        }
        if (props.activeStyle !== 'nbs_elevation' || props.activeStyle !== 'nbs_uncertainty') {

            return (

                <ListSubheader component="div">
                    <Typography sx={sxStyles.label}>BlueTopo: {title}</Typography>
                    <Typography variant='caption'>Legend Currently Unavailable</Typography>
                </ListSubheader>
            );
        }

    }

    return null;
}

export default NBSLegend;