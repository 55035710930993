import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { getTimeZone, shortDateString } from '../../utilities/utilities.js';

/**
*   TimeSlider controls/communicates current time and available times
*
*   Time values must be integers
*
*   @prop (func) setSelectedTime - callback function for setting selected/current time
*   @prop (int) selectedTimeIndex - Index of current time value from timeValues array (Authority on current time)
*   @prop (func) setSelectedTimeIndex - callback function for setting selectedTimeIndex
*   @prop ([int]) timeValues - array of all possible time values/steps for the slider
*   @prop (bool) playing - true if viewer is currently animating
*   @prop (func) togglePlay - callback for toggling playing state
*   @prop (boo) mapClickPopupOn - (optional) true if map-click popup is open, else false
*	@prop (int) prevPresentTime - the actual time as included in the time values list
*
* Slider Component Reference (complete API reference at https://material-ui.com/api/slider/)
*
* value={int} - cant use this because it locks the slider so the user cant move it
* defaultValue={int} - use this for animations just render the slider with new default at each step
* onChange - triggered when value of timeslider changes (triggered rapidly when sliding)
* onChangeCommitted - give callback function triggered on mouseUp (use to return value to update dimension control)
* valueLabelFormat={()=>{return '12:35'}}
* step={int} - use to make discrete slider, value determines size of increments
*              (pass null and the steps will be at any values provided by marks <--using this method)
* marks={[{},..]} - sets labels at given points on the slider. Calculate a list of objects containing value(int) and
*                   label(string) to put those labels under thos vals
*                   EX: [{
*                           value: 0,
*                           label: '0°C',
*                        },...
*                       ]
* valueLabelDisplay="on/off/auto" - the bubble displaying current val is either "on" (always on), "off" (always), or "auto" (only when mouse over)
* min={int} - starting/min val for time slider
* max={int} - ending/max val for time slider
* ValueLabelComponent={reactcomponent} - substiture component to hold the current value label (the balloon piece)
*   NOTE: NOT just the balloon piece, this component must match component api to Mui's default component
* ThumbComponent={reactcomponent} = substitute component for the button/circle that is clicked/dragged around
*
* NOTE: This could have been implemented with props.selectedTime as value for slider and just have onChange trigger
*       props.setSelectedTime. That was working fine, however it triggers a lot of map renders because onChange fires
*       rapidly (attempts at throttle/debounce failed but for on change they arn't perfect because when onChange
*       is delayed, the slider does not move, which the user will not appreciate. Ultimately a hook for local state
*       was used and tied to the slider value, and onChange was tide to the local state. This way the slider slides
*       freely and the many re-renders required for that are at least local only to the slider component.
*           When a new props.selectedTime is passed from above (as from animation) it has authority and overwrites the
*       local state, thus moving the slider/ giving the map programatic control over the slider. The map is only
*       updated by slider value when the user lets go of the mouse (onChangeComitted)
*
* CHANGE UPDATE:
*   Following updated requirements to match old nowcoast exactly. This slider now uses Array index values for its
*   ticks. This allows us to have evenly spaced gaps between ticks, regardless of how much time exists between them.
*   This is required for showing data like MRMS (2-4 minute gaps) and OFS (1-6 hour gaps) on the same slider in a
*   usable way. The time values still need to be known for labeling and for setting the time. To get the time value
*   corresponding to any tick, just enter the tick as an index in props.timeValues array. This new system is also more
*   cohesive with the step functions for advancing time (either through buttons or animation). It is no longer necessary
*   to null out the selectedTimeIndex, as the value is maintained everywhere as the authority on time for the app
*   including, now, for this slider. (Note that dimension controller still goes by selectedTime updates)
*
*/

const ValueLabelTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '90%',
        '& *' : {
            color: theme.palette.primary.main,
        }
    },
}));

function TimeSliderValueLabel(props) {
    const { children, open, value } = props;

    return (
        <ValueLabelTooltip arrow open={open} enterTouchDelay={0} placement="top" title={value} >
            {children}
        </ValueLabelTooltip>
    );
}


TimeSliderValueLabel.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired, //had type number before
};


const classes = {
    sliderDiv: {
        width: '100%', // Slider grows to fill its container - container size is dictated here, but its nested in a grid item whos size should be relied on more
    },
    sliderLoading: {
        height: '2.7em', // This height/margin carefully selected so that buttons and loading animation dont not move when time slider appears...
        marginTop: '0.75em'
    }
};

const NowCoastTimeSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-markLabel': { //labels at the bottom of the slider
        color: '#ffffff',
        //fontWeight: 'bold',
        textShadow: "-1px 0 black, 0 1px black,1px 0 black, 0 -1px black",
        marginTop: '0.25em',
    },
    '& .MuiSlider-thumb': { // circle that is draggable
        height: 20,
        width: 20,
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0.6)',
        transition: 'none',
    },
    '& .MuiSlider-mark': { // tick mark (inactive)
        height: 0,
        backgroundColor: 'rgba(0,0,0,0.4)',
        borderRadius: 2,
    },
    '& .MuiSlider-markActive': { // tick mark (active as in when you've pulled past it to the right)
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    '& .MuiSlider-rail': { // background of slider (the actual slider)
        height: 10,
        backgroundColor: 'rgba(0,0,0,0.6)',
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0.6)',
        opacity: 1,
    },
    '& 	.MuiSlider-track': { // bar running along the rail, the color change leading up to the thumb circle to show progress
        height: 10,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0.6)',
    }
}));

function TimeSlider(props) {
    // Create media query to detect current breakpoint from theme for conditional rendering
    const theme = useTheme();
    const screenIsSmall = useMediaQuery(theme.breakpoints.down('md'));

    // Store slider val (and prev val passed from time control)
    // Note: slider values are indexes corresponding to the timeValues array
    const [state, setState] = useState({
        sliderVal: 0,
        prevPropSliderVal: 0
    });

    // Local callback function, changes local state, but does not change system time (onChangeCommitted is used for that)
    const handleSliderOnChange = (e, value) => {
        if (props.playing) {
            props.togglePlay(); // If slider is playing, stop it
        }
        setState({
            ...state,
            'sliderVal': value
        });
        props.setSelectedTimeIndex(value);
        props.setSelectedTime(props.timeValues[value]);
    };

    // Used by time slider to make the thumb label. Previously the timeslider held actual time values, but it has since
    // been updated to hold indexes corresponding to timeValues so that must be translated in this function
    // ie. val param is an index, and must be converted to a time value
    const makeValueLabel = (val) => {
        // Hopefully just pass in prevPresentTime and compare val to that, if they match then add present/now
        val = props.timeValues[val];
        const dateVal = new Date(val);
        const presentString = ((val === props.prevPresentTime) ? ' - Present' : '');
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const dayOfWeek = daysOfWeek[dateVal.getDay()];
        return (dayOfWeek + " " + shortDateString(val) + " " + getTimeZone() + presentString);
    };

    // Adjust local state connected to slider on render to match time passed in by props
    // NOTE: This condition may not seem important but it is required to avoid an infinite rendering loop
    if (typeof props.selectedTimeIndex === "number") {
        if (props.selectedTimeIndex !== state.prevPropSliderVal) {
            setState({
                'sliderVal': props.selectedTimeIndex,
                'prevPropSliderVal': props.selectedTimeIndex
            });
        }
    }

    // Create marks from timeValues array
    // Note: time slider values correspond to indexes of props.timeValues (the actual time values)
    let marks = null;
    if (props.timeValues) {
        const numLabels = screenIsSmall ? 3 : 6;
        let nextLabelIndex = 0;
        const labelSteps = Math.floor(props.timeValues.length / (numLabels - 1));
        let labelCount = 0;
        marks = props.timeValues.map((value, index, array) => {
            let label = ''
            if (nextLabelIndex === index && labelCount < numLabels - 1) {
                label = shortDateString(value);
                labelCount++;
                nextLabelIndex += labelSteps;
            }else if(index === array.length - 1){
                label = shortDateString(value); //always add label for last value
            }

            return {'value': index, 'label': label};
        });
    }

    // Do not display timeslider tooltip if we are at xs breakpoint and the mapClickPopup is open
    let displayToolTip = true;
    if (useMediaQuery(theme.breakpoints.only('xs')) && props.mapClickPopupOn) {
        displayToolTip = false;
    }

    // If there are no time values yet, then the app is initializing so displaying loading animation
    if (!marks) {
        return (
            <div style={classes.sliderLoading}>
                <LinearProgress />
            </div>
        );
    }

    return (
        <div style={classes.sliderDiv} >
            <NowCoastTimeSlider
                defaultValue={marks ? marks[0].value : 0}
                value={typeof state.sliderVal === "number" ? state.sliderVal : 0}
                valueLabelFormat={makeValueLabel}
                step={null}
                marks={marks}
                valueLabelDisplay={(displayToolTip) ? "on" : "off"}
                min={0}
                max={props.timeValues.length-1}
                onChange={handleSliderOnChange}
                components={{
                    ValueLabel: TimeSliderValueLabel,
                }}
            />
        </div>
    );
}

export default TimeSlider
