import { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { NDFD_LAYER_INFO } from '../../config';
import NDFDLegend from '../menu/legend-menu-items/ndfd-legend';
import { getDataMultiple } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const classes = {
    NDFDFeatureInfoContainer: {
        padding: '10px',
    }
};

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (str) activeVariable - name of ndfd variable that currently selected (found in currentSources state)
*   This should be a sourceName value from config.js which is managed by currentSources
*   Due to there being 5 OL layers (one for each region) this source name needs to have "_regionName" chopped
*   off the end off it before it can be used as a key i NDFD_LAYER_INFO which is how we translate source names
*   to layer names. So for example "ndfd_wind_speed_conus" -> "ndfd_wind_speed" (do this before passing the
*   prop to this component)
* @prop (obj) styleInfo - legend info that pertains only to NDFD (derived from top-level state obj: styleInfo)
*
* NOTE: The challenge with this component is figuring which region that user clicked (without testing
* our coords against region polygons which seems like bad form) If we allow the assumption that our regions
* are mutually exclusive then we can just look through all the keys of the data object until we find one that
* that has features (because you can never click in more than one region at a time)
*
* NOTE: There is a unique issue with how this data object is updated (there are 5 asynchronus requests that
* each add a key/value to the data object. So there are a couple renders usually where not all 5 keys exist
* which causes a brief display of no-data message because all the keys that do exist are considered done loading
* and yet they have no data, the key with data hasnt arrived yet.) The keys could be added prior to the async requests
* or they could be hard coded here so we can check that all keys are in before setting dataDoneLoading to true
**/
export default function NDFDFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["ndfd"].data;
    const clickCoords = useSelector(getMapClickInfo)["ndfd"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["ndfd"].urls) return;

        const fetchData = async () => {
            getDataMultiple(mapClickURLs["ndfd"].urls)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"ndfd" : {"data" : data, "coords" : mapClickURLs["ndfd"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        if(clickCoords.x !== mapClickURLs["ndfd"].coords.x || clickCoords.y !== mapClickURLs["ndfd"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"ndfd" : {"data" : {features: []}, "coords" : mapClickURLs["ndfd"].coords}}));
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    // Identify unit for current variable
    let dataUnit = NDFD_LAYER_INFO[props.activeVariable].unit;
    // Set precision (in this case the number of significant digits to the right of the decimal)
    let precision = NDFD_LAYER_INFO[props.activeVariable].decimals;

    let pointDataValue = null;
    let pointData = null;
    for (const regionKey in clickData) {
        if (!clickData[regionKey].hasOwnProperty('features')) {
            // features is not set
            break;
        }

        if (clickData[regionKey] && clickData[regionKey].features && clickData[regionKey].features.length > 0) {
            // This region has data, so this must be where the click occurred
            pointDataValue = Number.parseFloat(clickData[regionKey].features[0].properties.Band1).toFixed(precision); // Assuming only 1 feature of interest
            pointData = <Typography align="center">{String(pointDataValue) + " " + dataUnit}</Typography>;

            // Special case for wind flags (speed & direction)
            if (props.activeVariable === "wind_velocity") {
                const [speed, dir] = getWindSpeedDir(clickData[regionKey].features[0].properties.Band1, clickData[regionKey].features[0].properties.Band2);
                pointData = <>
                    <Typography align="center">{String(Number.parseFloat(speed).toFixed(0)) + " knots"}</Typography>
                    <Typography align="center">{String(Number.parseFloat(dir).toFixed(0)) + " degrees"}</Typography>
                </>;
            }
            break;
        }
    }

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    // if there is a feature but Band1 value is 9999, then no data
    if (Math.trunc(pointDataValue) === 9999) {
        return(
            <div style={{padding: '50px 20px'}} >
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }

    let variableLabel = props.activeVariable;
    if (NDFD_LAYER_INFO[props.activeVariable]) {
        variableLabel = NDFD_LAYER_INFO[props.activeVariable].label;
    }


    if (pointData) {
        return(
            <div className={classes.NDFDFeatureInfoContainer}>
                <Typography align="center">{variableLabel}</Typography>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10,
                    }}
                >
                </div>
                <br />
                    {pointData}
                <br />
                {   (props.styleInfo && props.activeVariable) ?
                    <NDFDLegend
                        activeVariable={props.activeVariable + "_conus"} // Re-add a region to meet component's expectations
                        NDFDStyleInfo={props.styleInfo}
                    />
                    : null
                }
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}

/**
* calculate wind speed and dir from u/v vector components
*
* @param (number) u - u component
* @param (number) v - v component
*
* Returns length 2 list [speed, direction]
*/
function getWindSpeedDir(u, v) {
    if (u === 9999 || v === 9999) return [0,0]; // Check for no data values
    const magnitude = Math.sqrt(u*u + v*v);
    if (u === 0) return [magnitude,0];
    const angleRadians = Math.atan(v/u);
    let angleDegrees = Math.abs(angleRadians * (180/Math.PI));

    // Adjust degrees based on quadrant of vector
    if (u >= 0 & v < 0) angleDegrees = (90 - angleDegrees) + 270; // Second Quadrant
    if (u < 0 & v < 0) angleDegrees = + 180; // Third Quadrant
    if (u < 0 & v >= 0) angleDegrees = (90 - angleDegrees) + 90; // Fourth Quadrant
    angleDegrees = angleDegrees % 360;

    //convert from angle of vector to angle of wind flag
    let weatherWindDir = 270 - angleDegrees;
    if (weatherWindDir < 0) weatherWindDir = (weatherWindDir + 360);
    return [magnitude, weatherWindDir];
}
