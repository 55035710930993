import { useEffect, useState } from "react";

/**
* React component responsible for creating all OLLayer components within map
*
* @prop (obj) layer - the OL Layer object this source belongs to
* @prop (obj) olLayerState - maps ol layer names to obj containing "on", "layerParam", "stylesParam, "currentSource"
* @prop (obj) sourceObj - the openLayers source obj to use
* @prop (bool) sourceOn - if true, assign this source to layer
* @prop (str) layerList - value to use for "LAYERS" parameter (null or [] if not using dynamic source layers scheme for this source)
* @prop (str) styleList - value to use for "STYLES" parameter (null or [] if not using dynamic styles for this source)
* @prop (str) time - current time for source (ISOString)
* @prop (str) refTime - current reference time for source (ISOString)
*/

const OLSource = (props) => {
    // Store OL Source object
    const source = useState(props.sourceObj)[0];

    // Toggle source on/off
    useEffect(() => {

        if (!props.layer || !source) return;

        if (props.sourceOn) {
            props.layer.setSource(source);
        } //source is only turned off by another source turning itself on
    }, [props.sourceOn, props.layer, source, props.olLayerState]);

    // Update time and/or reference time
    // This should be revisited
    useEffect(() => {
        if (!source || !props.time) return;

        const newParams = source.getParams();
        newParams.time = props.time;
        newParams.dim_reference_time = props.refTime;
        source.updateParams(newParams);
    }, [props.time, props.refTime, source]);

    // Update LAYERS and STYLES parameters
    useEffect(() => {
        if (!source || (props.layerList === null && props.styleList === null)) return;

        try { // For WMS source (common case)
            const newParams = source.getParams();
            if (props.layerList !== null) {
                newParams.LAYERS = (props.layerList) ? props.layerList: null;
            }
            if (props.styleList !== null) {
                newParams.STYLES = (props.styleList) ? props.styleList: null;
            }
            source.updateParams(newParams);
        } catch (e) { // For WMTS source
            //if (e instanceof TypeError) {} // Type of error we expect to catch when source is WMTS
            if (props.styleList !== null) {
                //source.style_ = props.styleList; // Possibly incorrect usage. May need to find a setter that works
                //source.set('style_', props.styleList, false);
                // We expect a response to a change in style. But we get a WMS request (?how?) when it is changed in
                // this way. (instead of a wave of new wmts requests for everything on the screen but with the
                // new style filled in

            }
        }

    }, [props.layerList, props.styleList, source, props.olLayerState])


	return((props.children) ? <div>{props.children}</div> : null);
};

export default OLSource;