import { useEffect } from "react"
import useTimeout from "./use-timeout"

/**
* Simple for causing debounce behavior (From custom hooks at https://github.com/WebDevSimplified/useful-custom-react-hooks)
* DO ARGS IF U KEEP THIS
*/
export default function useDebounce(callback, delay, dependencies) {
    const { reset, clear } = useTimeout(callback, delay)
    useEffect(reset, [...dependencies, reset])
    useEffect(clear, [clear])
}