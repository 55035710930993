import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

// the layer used as a key for the legend info is hard coded here, this should always match the parse found in capabilities-handler-wms.js
const LAYER_KEY = "lightning_density";

/**
* Displays legend image with a label above it
*
* @prop (obj) LightningDensityStyleInfo - legend info that pertains only to lightning density (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function LightningDensityLegend(props){

    if (!props.LightningDensityStyleInfo) return null;

    return (
        <ListSubheader component="div" >
            <Typography sx={sxStyles.label}>{props.LightningDensityStyleInfo[LAYER_KEY][0].title}</Typography>
            <img style={{ margin: '0px 0px 0px -18px'}} alt="" src={props.LightningDensityStyleInfo[LAYER_KEY][0].url} />
        </ListSubheader>
    );
}

export default LightningDensityLegend;