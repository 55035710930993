import { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import OLLayerManager from '../layers/layer-manager.js';
import TimeControl from '../time-slider/time-control.js';
import 'ol/ol.css';
import eventListeners from '../../utilities/event-listeners.js';
import { MULTIPLE_STYLE_PRODUCTS } from '../../app.js';

/**
* React wrapper for OL map object
*
* @prop (obj) map - Stable instance of OL map to use
* @prop (obj) mapElement - map reference
* @prop (obj) zIndexes - z indexes for viewer React components
* @prop ([float, float]) center - center of map viewer
* @prop (int) zoom - zoom level of viewer
* @prop (func) showZoom - remove zoom control for cellphones
* @prop (obj) timeValues - maps toggle-able animation layers objects that map layer names to their list
*                          of time values from capabilities
* @prop (func) updateSelectedTime - setter function for selectedTime
* @prop (obj) timeStateController - DimensionControl to handle time
* @prop (obj) initializedCaps - contains initialization status of datasets that must wait on Get Capabilities requests to be enabled
* @prop (func) setInitializedCaps - setter function  for initializedCaps
* @prop (obj) capHandlers - Contains all capability handlers for each product
* @prop (func) updateTimeValues - setter function for timeValues
* @prop (func) updateStyleInfo - setter function for styleInfo
* @prop (func) updateProductInfo - setter function for productInfo
* @prop (obj) customLayerInfo - All info related to user-added layers - not currently used
* @prop (obj) productToggles - contains layer group names (products) mapped to true/fales values for on/off
* @prop (obj) layerOpacities - maps layer group names to opacity values (0-100)
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state as well as "layersParam", "stylesParam", and "currentSource"
* @prop (bool) refreshLayers - Use to trigger the re-adding of all layers to the map when toggled
* @prop (bool) mapClickPopupOn - (optional) true if map-click popup is open, else false
* @prop (obj) children - react jsx passed to OLMap invocation
*
*/

const DivMapContainer = styled("div")({
    flex: 1,
    position: 'relative',
    height: '100%',
    width: '100%',
});

// TODO: Restore or otherwise improve behavior/appearance of OpenLayers map elements following move to v9
const DivOlMapWindow = styled("div")({
    position: 'absolute',
    height: '100%',
    width: '100%',
    margin: 0,
    padding: 0,
    '& .ol-control': {
        '& button': {
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'rgba(255,255,255)',
            boxShadow: '0px 0px 2px 2px rgba(255, 255, 255, 0.3)',
        },
    },
    '& .ol-scale-line': {
        backgroundColor: 'rgba(0,0,0,0.5)',
        bottom: '0.1em',
    },
    '& .ol-scale-line-inner': {
        color: 'rgba(255,255,255)',
        fontWeight: 'bold',
    },
    '& .ol-attribution': {
        bottom: 0,
    },
});

const OLMap = (props) => {
    const theme = useTheme();

    // Store current Time Dimension State (proper time val for all sources in app based on selected time in time slider)
    const [timeState, setTimeState] = useState(null);

    // Store union of all possible time values across all sources based on latest known capabilities
    const [timeValuesUnion, setTimeValuesUnion] = useState(null);

	// componentDidMount / Initialize Capabilities (runs once after first render)
	useEffect(() => {
        if(props.capHandlers.current) {
            // Register Listeners for Cap Handlers
            props.capHandlers.current.forEach((capHandler) => {
                if (capHandler.events.includes('capabilitiesUpdated')) {
                    capHandler.handler.addEventListener('capabilitiesUpdated', eventListeners.capabilitiesUpdated(props.timeStateController.updateCapabilities, props.updateTimeValues));
                }
                if (capHandler.events.includes('stylesUpdated')) {
                    capHandler.handler.addEventListener('stylesUpdated', eventListeners.stylesUpdated(props.updateStyleInfo));
                }
                if (capHandler.events.includes('infoUpdated')) {
                    capHandler.handler.addEventListener('infoUpdated', eventListeners.infoUpdated(props.updateProductInfo));
                }
            });

            // Register Listeners for Time Dimension Controller
            props.timeStateController.addEventListener('timeValuesUpdated', eventListeners.timeValuesUpdated(setTimeValuesUnion));
            props.timeStateController.addEventListener('dimensionStateUpdated', eventListeners.dimensionStateUpdated(setTimeState));

            // Make a callback out of this...
            // Init Capabilities
            props.capHandlers.current.forEach(async (capHandler) => {
                await capHandler.handler.getCapabilities();
                if(MULTIPLE_STYLE_PRODUCTS.includes(capHandler.handler._product)) {
                    // checks style info against what is in olLayerState
                    // replaces invalid styles with default
                    props.validateOlStyle(capHandler.handler._styleInfo);
                }
            });
        }
	}, [props.timeStateController, props.capHandlers, props.updateTimeValues, props.updateProductInfo, props.updateStyleInfo]);

	// Handle change to zoom/center
	useEffect(() => {
		if (!props.map) return;
        props.map.getView().setCenter(props.center);
		props.map.getView().setZoom(props.zoom);
	}, [props.center, props.zoom, props.map]);

    //remove zoom control for cellphones
    if (useMediaQuery(theme.breakpoints.down('md'))) {
        props.showZoom(false);
    } else {
        props.showZoom(true);
    }

	return (
        <DivMapContainer>
            <DivOlMapWindow ref={props.mapElement}>
                {props.children}
                <OLLayerManager
                    map={props.map}
                    dimensionState={timeState}
                    initializedCaps={props.initializedCaps}
                    setInitializedCaps={props.setInitializedCaps}
                    customLayerInfo={props.customLayerInfo}
                    layerToggles={props.productToggles}
                    layerOpacities={props.layerOpacities}
                    olLayerState={props.olLayerState}
                    refreshLayers={props.refreshLayers}
                />
                <TimeControl
                    timeValues={props.timeValues}
                    updateTime={props.timeStateController ? props.timeStateController.updateState : null}
                    timeValuesUnion={timeValuesUnion}
                    zIndexVal={props.zIndexes.animation_control}
                    productToggles={props.productToggles}
                    updateSelectedTime={props.updateSelectedTime}
                    mapClickPopupOn={(props.mapClickPopupOn) ? props.mapClickPopupOn : false}
                    olLayerState={props.olLayerState}
                    capHandlers={props.capHandlers}
                />
            </DivOlMapWindow>
        </DivMapContainer>
	);
}

export default OLMap;
