import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { CircularProgress, Typography, Link, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import HorizontalSlider from '../ui-controls/horizontal-slide';
import { getData } from '../../utilities/utilities.js';
import { ZONE_FORECAST_LAYERS } from '../../config.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const METERS_PER_MILE = 1609.344;

const MARINE_ZONE_PREFIXES = [
    "AMZ",
    "ANZ",
    "GMZ",
    "PHZ",
    "PKZ",
    "PMZ",
    "PZZ"
]

const sxStyles = {
    forecastLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        }
    },
};

const classes = {
    forecastTable: {
        display: 'inline-table',
        userSelect: 'none',
        '&:active:hover': {
            cursor: 'grabbing',
        },
    },
    forecastDay: {
        padding: '10px',
        borderRadius: '6px',
        //backgroundColor: '#eee',
        '&:hover': {
            cursor: 'pointer', // selector not working atm
        },
        height: '330px',
    },
    forecastImage: {
        marginLeft: '10px',
        marginRight: '10px',
        borderRadius: '6px',
    },
    forecastImageCellphone: {
        marginLeft: 'calc(50% - 2em)',
        borderRadius: '5px',
        width: '4em',

    },
    contentHeader: {
        padding: '10px',
    },
    divider: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginLeft: 10,
        marginRight: 10,
    },
};

/**
* Component for rendering results from getFeatureInfo wms requests
*
**/
export default function PointForecast(props) {
    let refLocation, distMiles, compassDir;

    const theme = useTheme();
    const [currentForecastNum, setCurrentForecastNum] = useState(1);
    const cellphone = useMediaQuery(theme.breakpoints.only('xs'));
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["point_forecast"].data;
    const clickCoords = useSelector(getMapClickInfo)["point_forecast"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["point_forecast"].url) return;
        const errorMsg = {"error": "A point forecast is currently unavailable at the specified location. Please try again in another location or visit https://www.weather.gov for more information."};

        const attemptPointForecast = async () => {
            let clickUpdate = {};
            let pointData = null;
            let forecastData = null;
            for (let x = 0; x < 3; x++) {
                pointData = await getData(mapClickURLs["point_forecast"].url);
                if(pointData.properties) {
                    if(pointData.properties.forecast) {
                        forecastData = await getData(pointData.properties.forecast);
                        if(forecastData.status !== 500 && forecastData.status !== 404 && forecastData.status !== 503) {
                            clickUpdate["point"] = pointData;
                            clickUpdate["forecast"] = forecastData;
                            break;
                        }
                    }
                }
            }
            if(!clickUpdate["forecast"]) {
                let layerCoords = mapClickURLs["point_forecast"].coords.x.toString() + "," + mapClickURLs["point_forecast"].coords.y.toString();
                const featureServerUrl = "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/FeatureServer/";
                let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=false&f=geojson&outSR=102100&outFields=*&geometry=" + layerCoords;
                const offshoreUrl = featureServerUrl + ZONE_FORECAST_LAYERS['offshore_zone_forecasts'].id + queryUrl;
                const coastalUrl = featureServerUrl + ZONE_FORECAST_LAYERS['coastal_marine_zone_forecasts'].id + queryUrl;
                const highSeasUrl = featureServerUrl + ZONE_FORECAST_LAYERS['high_seas_zone_forecasts'].id + queryUrl;
                let zoneID = null;
                if(pointData.properties) {
                    if(pointData.properties.forecastZone) {
                        zoneID = pointData.properties.forecastZone.split("/").slice(-1);
                    }
                }
                if(forecastData && forecastData.title) { //if forecast error message
                    if(forecastData.title === "Marine Forecast Not Supported") {
                        //point is either offshore or coastal
                        const offshoreData = await getData(offshoreUrl);
                        if(offshoreData.features[0]) {
                            clickUpdate["offshore"] = offshoreData;
                        } else {
                            //if coastal, feature info popup will include link for marine point forecast
                            clickUpdate["point"] = pointData;
                            clickUpdate["marine_point"] = forecastData;
                        }
                    } else if(forecastData.title === "Unexpected Problem" || forecastData.title === "Service Unavailable") {
                        //if point API doesn't work, get the zone forecast
                        //check if offshore or coastal, else return zone info
                        if(MARINE_ZONE_PREFIXES.includes(zoneID[0].slice(0,3))) {
                            const offshoreData = await getData(offshoreUrl);
                            if(offshoreData.features[0]) {
                                clickUpdate["offshore"] = offshoreData;
                            } else {
                                const coastalData = await getData(coastalUrl);
                                if(coastalData.features[0]) {
                                    clickUpdate["coastal"] = coastalData;
                                } else {
                                    clickUpdate["zone"] = pointData;
                                }
                            }
                        } else { clickUpdate["zone"] = pointData; }
                    }
                } else if(pointData.properties && pointData.properties.forecastZone) { //if no forecast error message, but point has forecast zone
                    if(MARINE_ZONE_PREFIXES.includes(zoneID[0].slice(0,3))) {
                        const offshoreData = await getData(offshoreUrl);
                        if(offshoreData.features[0]) {
                            clickUpdate["offshore"] = offshoreData;
                        } else {
                            const coastalData = await getData(coastalUrl);
                            if(coastalData.features[0]) {
                                clickUpdate["coastal"] = coastalData;
                            } else { clickUpdate["zone"] = pointData; }
                        }
                    } else { clickUpdate["zone"] = pointData; }
                } else if(pointData.title) { // if point data error message
                    if(pointData.title === "Unexpected Problem") {
                        const offshoreData = await getData(offshoreUrl);
                        if(offshoreData.features[0]) {
                            clickUpdate["offshore"] = offshoreData;
                        } else {
                            const coastalData = await getData(coastalUrl);
                            if(coastalData.features[0]) {
                                clickUpdate["coastal"] = coastalData;
                            } else {
                                const highSeasData = await getData(highSeasUrl);
                                if(highSeasData.features[0]) {
                                    clickUpdate["highSeas"] = highSeasData;
                                }
                            }
                        }
                    } else if(pointData.title === "Data Unavailable For Requested Point") {
                        const highSeasData = await getData(highSeasUrl);
                        if(highSeasData.features[0]) {
                            clickUpdate["highSeas"] = highSeasData;
                        } else {
                            clickUpdate = errorMsg;
                        }
                    }
                } else {
                    clickUpdate = errorMsg;
                }
            }
            dispatch(mapClickInfoUpdated({"point_forecast" : {"data" : clickUpdate, "coords" : mapClickURLs["point_forecast"].coords}}));
            setDataPending(false);
        };

        if(clickCoords.x !== mapClickURLs["point_forecast"].coords.x || clickCoords.y !== mapClickURLs["point_forecast"].coords.y) {
            try {
                setDataPending(true);
                attemptPointForecast();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"point_forecast" : {"data" : errorMsg, "coords" : mapClickURLs["point_forecast"].coords}}));
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    // Check contents of clickData for empty, error, etc.
    if ("error" in clickData) {
        return(
            <div style={{padding: '50px 20px'}} >
                <Typography sx={{fontSize: '0.8em'}} >{clickData.error}</Typography>
            </div>
        );
    } else if("zone" in clickData) {
        let zoneAPILink = clickData.zone.properties.forecastZone;
        const zoneID = zoneAPILink.split("/").slice(-1);
        const forecastLink = "https://forecast.weather.gov/MapClick.php?zoneid=" + zoneID;
        return(
            <div>
                <div style={classes.contentHeader}>
                    <Typography align="center">NWS Zone Forecast</Typography>
                    <Typography sx={{fontSize: '0.8em'}} align="center">Forecast Zone {zoneID}</Typography>
                </div>
                <div style={{padding: '10px'}}>
                    <Typography sx={{fontSize: '0.8em'}} align="center"><Link href={forecastLink} sx={sxStyles.forecastLink} target="_blank" rel="noopener noreferrer">View Zone Forecast on NWS Website</Link></Typography>
                </div>
            </div>
        );
    } else if ("offshore" in clickData) {
        const offshoreLink = clickData.offshore.features[0].properties.url;
        return(
            <div>
                <div style={classes.contentHeader}>
                    <Typography align="center">NWS Offshore Marine Forecast</Typography>
                    <Typography sx={{fontSize: '0.8em'}} align="center">{clickData.offshore.features[0].properties.name}</Typography>
                </div>
                <div style={{padding: '10px'}}>
                    <Typography sx={{fontSize: '0.8em'}} align="center"><Link href={offshoreLink} sx={sxStyles.forecastLink} target="_blank" rel="noopener noreferrer">View Offshore Marine Zone Forecast on NWS Website</Link></Typography>
                </div>
            </div>
        );
    } else if ("marine_point" in clickData) {
        refLocation = {
            city: clickData.point.properties.relativeLocation.properties.city,
            state: clickData.point.properties.relativeLocation.properties.state,
            dist: clickData.point.properties.relativeLocation.properties.distance.value,
            dir: clickData.point.properties.relativeLocation.properties.bearing.value,
        };
        distMiles = Math.round(refLocation.dist / METERS_PER_MILE);
        compassDir = getCardinalFromDegrees(refLocation.dir);
        const marineForecastLink = "https://marine.weather.gov/MapClick.php?lat=" + clickData.point.geometry.coordinates[1] + "&lon=" + clickData.point.geometry.coordinates[0];
        return(
            <div>
                <div style={classes.contentHeader}>
                    <Typography align="center">NWS Coastal Marine Forecast</Typography>
                    <Typography sx={{fontSize: '0.8em'}} align="center">{distMiles} Miles {compassDir} of {refLocation.city}, {refLocation.state}</Typography>
                </div>
                <div style={{padding: '10px'}}>
                    <Typography sx={{fontSize: '0.8em'}} align="center"><Link href={marineForecastLink} sx={sxStyles.forecastLink} target="_blank" rel="noopener noreferrer">View Coastal Marine Forecast on NWS Website</Link></Typography>
                </div>
            </div>
        );
    } else if ("coastal" in clickData) {
        const coastalLink = clickData.coastal.features[0].properties.url;
        return(
            <div>
                <div style={classes.contentHeader}>
                    <Typography align="center">NWS Coastal Marine Forecast</Typography>
                    <Typography sx={{fontSize: '0.8em'}} align="center">{clickData.coastal.features[0].properties.name}</Typography>
                </div>
                <div style={{padding: '10px'}}>
                    <Typography sx={{fontSize: '0.8em'}} align="center"><Link href={coastalLink} sx={sxStyles.forecastLink} target="_blank" rel="noopener noreferrer">View Coastal Marine Zone Forecast on NWS Website</Link></Typography>
                </div>
            </div>
        );
    } else if ("highSeas" in clickData) {
        const highSeasLink = clickData.highSeas.features[0].properties.url;
        return(
            <div>
                <div style={classes.contentHeader}>
                    <Typography align="center">NWS High Seas Forecast</Typography>
                    <Typography sx={{fontSize: '0.8em'}} align="center">{clickData.highSeas.features[0].properties.name}</Typography>
                </div>
                <div style={{padding: '10px'}}>
                    <Typography sx={{fontSize: '0.8em'}} align="center"><Link href={highSeasLink} sx={sxStyles.forecastLink} target="_blank" rel="noopener noreferrer">View High Seas Forecast on NWS Website</Link></Typography>
                </div>
            </div>
        );
    } else {
        try {
        // Generate description of reference location (ie. "<miles> <cardinal-direction> of city, state")
            refLocation = {
                city: clickData.point.properties.relativeLocation.properties.city,
                state: clickData.point.properties.relativeLocation.properties.state,
                dist: clickData.point.properties.relativeLocation.properties.distance.value,
                dir: clickData.point.properties.relativeLocation.properties.bearing.value,
            }
            distMiles = Math.round(refLocation.dist / METERS_PER_MILE);

            compassDir = getCardinalFromDegrees(refLocation.dir);
            const forecastBarContent = clickData.forecast.properties.periods.map((forecastDay, index) => {
                if (cellphone) {
                    return(
                        <td key={index}>
                            <div
                                key={index}
                                onClick={() => {setCurrentForecastNum(forecastDay.number)}}
                                style={{
                                    ...classes.forecastDay,
                                    backgroundColor: (currentForecastNum === forecastDay.number) ? theme.palette.primary.main : '#eee',
                                    color: (currentForecastNum === forecastDay.number) ? theme.palette.primary.contrastText : '',
                                    height: 'auto',
                                    width: '7em'
                                }}
                            >
                                <div style={{height: 'auto'}}>
                                    <Typography align="center" variant="body2" sx={{fontSize: '0.8em', marginBottom: '4px'}}>{forecastDay.name}</Typography>
                                </div>
                                <div>
                                    <img style={classes.forecastImageCellphone} src={(forecastDay['icon'].includes('https://api.weather.gov')) ? forecastDay.icon : "https://api.weather.gov" + forecastDay.icon} alt={forecastDay.shortForecast} />
                                </div>
                                <div >
                                    <Typography sx={{fontSize: '0.8em'}} align="center">{forecastDay.temperature} &#176;{forecastDay.temperatureUnit}</Typography>
                                </div>
                                <div style={{height: 'auto'}}>
                                    <Typography sx={{fontSize: '0.7em'}} align="center" >{forecastDay.windSpeed} {forecastDay.windDirection}</Typography>
                                </div>
                            </div>
                        </td>
                    );
                }
                return (
                    <td key={index}>
                        <div
                            key={index}
                            onClick={() => {setCurrentForecastNum(forecastDay.number)}}
                            style={{
                                ...classes.forecastDay,
                                backgroundColor: (currentForecastNum === forecastDay.number) ? theme.palette.primary.main : '#eee',
                                color: (currentForecastNum === forecastDay.number) ? theme.palette.primary.contrastText : '',
                            }}
                        >
                            <div style={{height: '40px'}}>
                            <Typography align="center" variant="body2" >{forecastDay.name}</Typography>
                            </div>
                            <div>
                                <img style={classes.forecastImage} src={(forecastDay['icon'].includes('https://api.weather.gov')) ? forecastDay.icon : "https://api.weather.gov" + forecastDay.icon} alt={forecastDay.shortForecast} />
                            </div>
                            <div style={{height: '130px'}} >
                                <Typography sx={{fontSize: '0.8em'}}>{forecastDay.shortForecast}</Typography>
                            </div>
                            <div style={{height: '40px'}}>
                                <Typography sx={{paddingTop: '10px', fontSize: '0.8em'}} align="center" >Wind: {forecastDay.windSpeed} {forecastDay.windDirection}</Typography>
                            </div>
                            <div >
                                <Typography sx={{paddingTop: '10px'}} align="center">{(forecastDay['name'].toLowerCase().includes('night')) ? "Low" : "Hi" } {forecastDay.temperature} &#176;{forecastDay.temperatureUnit}</Typography>
                            </div>
                        </div>
                    </td>
                );
            });

            const nwsForecastLink = "https://forecast.weather.gov/MapClick.php?lon=" + clickData.point.geometry.coordinates[0] + "&lat=" + clickData.point.geometry.coordinates[1]

            return(
                <div style={classes.pointForecastContainer}>
                    <div style={classes.contentHeader}>
                        <Typography align="center">NWS Point Forecast</Typography>
                        <Typography sx={{fontSize: '0.8em'}} align="center">{distMiles} Miles {compassDir} of {refLocation.city}, {refLocation.state}</Typography>
                        <Typography sx={{fontSize: '0.8em'}} align="center">Last Update: {clickData.forecast.properties.updateTime}</Typography>
                        <div style={classes.divider}></div>
                    </div>
                    <div style={{overflowX: 'hidden'}}>
                        <HorizontalSlider sliderType={(cellphone) ? "cellphone-forecast" : "forecast"}>
                            <table style={classes.forecastTable}>
                                <tbody>
                                    <tr>
                                        {forecastBarContent}
                                    </tr>
                                </tbody>
                            </table>
                        </HorizontalSlider>
                    </div>
                    <div style={{padding: '10px'}}>
                        <div style={{...classes.divider, marginBottom: '10px'}}></div>
                        <Typography sx={{fontWeight: 'bold', fontSize: '0.8em'}}>{clickData.forecast.properties.periods[currentForecastNum - 1].name}:</Typography>
                        <Typography sx={{fontSize: '0.8em'}}>{clickData.forecast.properties.periods[currentForecastNum - 1].detailedForecast}</Typography>
                    </div>
                    <div style={{padding: '10px'}}>
                        <Typography sx={{fontSize: '0.8em'}}><Link href={nwsForecastLink} sx={sxStyles.forecastLink} target="_blank" rel="noopener noreferrer">View on NWS Website</Link></Typography>
                    </div>
                </div>
            );
        } catch (error) {
            if(clickData.forecast.title) {
                return(
                    <div>
                        <div style={classes.contentHeader}>
                            <Typography align="center">{clickData.forecast.title}</Typography></div>
                        <div style={{padding: '10px'}}>
                            <Typography sx={{fontSize: '0.8em'}} align="left">{clickData.forecast.detail}</Typography>
                        </div>
                    </div>
                );
            } else {
                return(
                    <div style={{padding: '50px 20px'}}>
                        <Typography sx={{fontSize: '0.8em'}} >A point forecast is currently unavailable at the specified location. Please try again in another location or visit https://www.weather.gov for more information.</Typography>
                    </div>
                );
            }
        }
    }
}

/**
* Function that takes a number indicating a direction in degrees
* where N is 0 and W is 270
*
* Returns string containing abbreviation for cardinal direction (N, NNE, NE, ENE, E, etc)
*/
function getCardinalFromDegrees(directionDegress) {
    let angle = directionDegress % 360;
    if ((angle > 348.75 && angle < 360 ) || (angle >= 0 && angle <= 11.25 )) {
        return "N";
    } else if (angle > 11.25 && angle <= 33.75) {
        return "NNE";
    } else if (angle > 33.75 && angle <= 56.25) {
        return "NE";
    } else if (angle > 56.25 && angle <= 78.75) {
        return "ENE";
    } else if (angle > 78.75 && angle <= 101.25 ) {
        return "E";
    } else if (angle > 101.25 && angle <= 123.75) {
        return "ESE";
    } else if (angle > 123.75 && angle <= 146.25) {
        return "SE";
    } else if (angle > 146.25 && angle <= 168.75 ) {
        return "SSE";
    } else if (angle > 168.75 && angle <= 191.25) {
        return "S";
    } else if (angle > 191.25 && angle <= 213.75) {
        return "SSW";
    } else if (angle > 213.75 && angle <= 236.25) {
        return "SW";
    } else if (angle > 236.25 && angle <= 258.75) {
        return "WSW";
    } else if (angle > 258.75 && angle <= 281.25) {
        return "W";
    } else if (angle > 281.25 && angle <= 303.75 ) {
        return "WNW";
    } else if (angle > 303.75 && angle <= 326.25) {
        return "NW";
    } else { // (angle > 326.25 && angle <= 348.75)
        return "NNW";
    }
}