import { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import STOFSLegend from '../menu/legend-menu-items/stofs-legend.js';
import { getData } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const classes ={
    STOFSFeatureInfoContainer: {
        padding: '10px',
    }
};


/**
* Component for rendering results from getFreatureInfo wms requests
*
* @prop (obj) styleInfo - legend info that pertains only to MRMS (derived from top-level state obj: styleInfo)
**/
export default function STOFSFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["stofs"].data;
    const clickCoords = useSelector(getMapClickInfo)["stofs"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["stofs"].url) return;

        const fetchData = async () => {
            getData(mapClickURLs["stofs"].url)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"stofs" : {"data" : data, "coords" : mapClickURLs["stofs"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        if(clickCoords.x !== mapClickURLs["stofs"].coords.x || clickCoords.y !== mapClickURLs["stofs"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"stofs" : {"data" : {features: []}, "coords" : mapClickURLs["stofs"].coords}}));
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    if (clickData.features && clickData.features.length > 0) {
        return(
            <div style={classes.STOFSFeatureInfoContainer}>
                <Typography align="center">STOFS3D Inland-Coastal Flooding</Typography>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10,
                    }}
                >
                </div>
                <br />
                <Typography sx={{fontSize: '0.8em'}} align="center">Vertical Datum: {clickData.features[0].properties.verticaldatum}</Typography>
                <br />
                {clickData.features[0].properties.minwaterlevel < -1000 ?
                    <Typography sx={{fontSize: '0.8em'}} align="center">Disturbance Min: Not Available</Typography>
                    : <Typography sx={{fontSize: '0.8em'}} align="center">Disturbance Min: {clickData.features[0].properties.minwaterlevel} meters</Typography>
                }
                <Typography sx={{fontSize: '0.8em'}} align="center">Disturbance Max: {clickData.features[0].properties.maxwaterlevel} meters</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}} align="center">Issuance Time: {clickData.features[0].properties.issuance}</Typography>
                <Typography sx={{fontSize: '0.8em'}} align="center">Valid Forecast Cycle: {clickData.features[0].properties.reference}</Typography>
                <Typography sx={{fontSize: '0.8em'}} align="center">Valid Forecast Hour: {clickData.features[0].properties.valid}</Typography>
                <br />
                { (props.styleInfo) ?
                    <center><STOFSLegend
                        STOFSStyleInfo={props.styleInfo}
                    /></center>
                    : null
                }
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}