import { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { getDataMultiple } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const classes ={
    VLMFeatureInfoContainer: {
        padding: '10px'
    }
};

const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    }
};

/**
* Component for rendering results from getFeatureInfo wms requests
*
**/
export default function VLMFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["vlm"].data;
    const clickCoords = useSelector(getMapClickInfo)["vlm"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["vlm"].urls) return;

        const fetchData = async () => {
            getDataMultiple(mapClickURLs["vlm"].urls)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"vlm" : {"data" : data, "coords" : mapClickURLs["vlm"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        if(clickCoords.x !== mapClickURLs["vlm"].coords.x || clickCoords.y !== mapClickURLs["vlm"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"vlm" : {"data" : {features: []}, "coords" : mapClickURLs["vlm"].coords}}));
                setDataPending(false);
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    if (clickData["vlm_velocity"].features[0] && (clickData["vlm_velocity"].features[0].properties['Band1'] > -1000)) {
        return(
            <div className={classes.VLMFeatureInfoContainer}>
                <Typography align="center">Vertical Land Motion (2017-2023)</Typography>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10,
                    }}
                >
                </div>
                <br />
                {clickData["vlm_velocity"] ?
                <div className={classes.VLMFeatureInfoContainer}>
                    <Typography sx={sxStyles.label} align="center">Average Annual Velocity</Typography>
                    <Typography sx={{fontSize: '1.0em'}} align="center">
                        {clickData["vlm_velocity"].features[0].properties['Band1'].toFixed(2)} mm/yr
                    </Typography>
                </div>
                : null}
                <br />
                {clickData["vlm_acceleration"] ?
                <div className={classes.VLMFeatureInfoContainer}>
                    <Typography sx={sxStyles.label} align="center">Average Annual Acceleration</Typography>
                    <Typography sx={{fontSize: '1.0em'}} align="center">
                        {clickData["vlm_acceleration"].features[0].properties['Band1'].toFixed(2)} mm/yr<sup>2</sup>
                    </Typography>
                </div>
                : null}
                <br />
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}