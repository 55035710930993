import { useState, useEffect } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Link, TableHead } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import FeatureInfoAccordion from './feature-info-accordion'
import useHighlightLayer from './hooks/use-highlight-layer'
import { OL_ZINDEXES, ZONE_FORECAST_LAYERS, RIP_CURRENT_COLORS } from '../../config';
import { beachLegend } from '../menu/legend-menu-items/zone-forecasts-legend.js';
import { getDataMultiple } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const sxStyles = {
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        border: 'none',
    },
    legendCell: {
        marginLeft: '8px'
    },
    linkCell: {
        whiteSpace: 'nowrap',
        fontSize: '0.8em',
    },
    subheaderCell: {
        fontSize: '1em',
        fontWeight: 'bold'
    },
    rowHeader: {
        fontSize: '0.8em',
        whiteSpace: 'nowrap',
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        },
        whiteSpace: 'nowrap'
    }
};

// JSS styles for non-mui components
const classes = {
    zoneForecastsFeatureInfoContainer: {
        marginTop: '10px',
    },
};

const dateFormat = {
    dateStyle: "medium",
    timeStyle: "long",
    timeZone: "UTC"
};

//memoized selector caches forecast types between renders
const selectForecastTypes = createSelector([getMapClickInfo, (state, olLayerState) => olLayerState], (info, olLayerState) => {
    // Get all unique zone types
    const data = info["zone_forecasts"].data;
    const newForecastTypes = new Set();
    for(const layer in data) {
        if(data[layer] && data[layer].features && olLayerState[layer].on) {
            if(data[layer].features.length > 0 && ZONE_FORECAST_LAYERS[layer].group !== 'beach') {
                newForecastTypes.add(layer);
            }
        }
    }

    if(newForecastTypes.has("offshore_zone_forecasts")) {
        newForecastTypes.delete("coastal_marine_zone_forecasts");
    }
    return newForecastTypes;
});

const selectBeachTypes = createSelector([getMapClickInfo, (state, olLayerState) => olLayerState], (info, olLayerState) => {
    const data = info["zone_forecasts"].data;
    const newBeachTypes = new Set();
    for(const layer in data) {
        if(data[layer] && data[layer].features && olLayerState[layer].on) {
            if (data[layer].features.length > 0 && ZONE_FORECAST_LAYERS[layer].group === 'beach') {
                newBeachTypes.add(layer);
            }
        }
    }
    return newBeachTypes;
});

/**
* Component for rendering results from getFeatureInfo requests
*
* @prop (obj) map - OL map object used for drawing feature info highlights
**/
export default function ZoneForecastsFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["zone_forecasts"].data;
    const clickCoords = useSelector(getMapClickInfo)["zone_forecasts"].coords;
    const forecastTypes = useSelector(state => selectForecastTypes(state, props.olLayerState));
    const beachTypes = useSelector(state => selectBeachTypes(state, props.olLayerState));
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const [productsOpen, setProductsOpen] = useState({});

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.zone_forecasts_highlight_layer);
    const dispatch = useDispatch();

    // Zone info retrieved when click coordinates change
    useEffect(() => {
        if(!mapClickURLs["zone_forecasts"].urls) return;

        const fetchData = async () => {
            getDataMultiple(mapClickURLs["zone_forecasts"].urls)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"zone_forecasts" : {"data" : data, "coords" : mapClickURLs["zone_forecasts"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        setProductsOpen({});

        if(clickCoords.x !== mapClickURLs["zone_forecasts"].coords.x || clickCoords.y !== mapClickURLs["zone_forecasts"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"zone_forecasts" : {"data" : {features: []}, "coords" : mapClickURLs["zone_forecasts"].coords}}));
                setDataPending(false);
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    useEffect(() => {
        let newProductsOpen = {};
        for(const type in forecastTypes) {
            newProductsOpen = Object.assign(newProductsOpen, {[type] : false});
        }
        for(const type in beachTypes) {
            newProductsOpen = Object.assign(newProductsOpen, {[type] : false});
        }
        setProductsOpen(newProductsOpen);
    }, [forecastTypes, beachTypes]);

    useEffect(() => {
        // Handle drawing of GeoJSON features returned by getFeatureInfo
        // updates only when product accordion is opened/closed
        clearHighlight();
        if(Object.keys(clickData).length > 0) {
            for (const layer in clickData) {
                if (clickData && clickData[layer] && clickData[layer].features) {
                    for (const feature of clickData[layer].features) {
                        if(productsOpen[layer] && productsOpen[layer] === true) {
                            drawFeatureHighlight(feature);
                        }
                    }
                }
            }
        }
    }, [productsOpen, clearHighlight, clickData, drawFeatureHighlight]);

    //if not all feature service requests have responded
    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    //if no zone features at click location
    if (Object.keys(clickData).length > 0 && forecastTypes.size === 0 && beachTypes.size === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location.</Typography>
            </div>
        );
    }

    let zoneContent = [...forecastTypes].map((forecastType,index) => {
        let status = true;
        if(!productsOpen[forecastType] || productsOpen[forecastType] === false) {
            status = false;
        }
        return (
            <FeatureInfoAccordion key={index}
                styleOverride={{borderLeft: "10px solid " + ZONE_FORECAST_LAYERS[forecastType].color}}
                featureName={ZONE_FORECAST_LAYERS[forecastType].label}
                expanded={status}
                setFeatureIsOn={() => {setProductsOpen((prevState) => {return({...prevState, [forecastType]: !status})})}}
            >
                <div style={{padding: '5px 0px 10px 0px'}}>
                {
                    clickData[forecastType].features.map((feature, index) => {
                        return(
                            <TableContainer key={index}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow sx={sxStyles.tableHeader}>
                                            {feature.properties.zoneurl ?
                                            <TableCell><Link href={feature.properties.zoneurl} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Forecast</Link></TableCell>
                                            : <TableCell><Link href={feature.properties.url} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Forecast</Link></TableCell> }
                                            <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Zone ID:</TableCell>
                                            {feature.properties.zone ?
                                            <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.zone}</TableCell>
                                            : <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.id}</TableCell> }
                                        </TableRow>
                                        <TableRow>
                                        {forecastType === 'coastal_marine_zone_forecasts' || forecastType === 'public_weather_zones' || forecastType === 'fire_weather_zones' ?
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO ID:</TableCell>
                                        : forecastType === 'offshore_zone_forecasts' ?
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO or Center ID:</TableCell>
                                        : forecastType === 'high_seas_zone_forecasts' ?
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Center ID:</TableCell>
                                        : null }
                                            {feature.properties.cwa ?
                                            <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.cwa}</TableCell>
                                            : <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.wfo}</TableCell> }
                                        </TableRow>
                                        <TableRow>
                                            {forecastType === 'public_weather_zones' || forecastType === 'fire_weather_zones' ?
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Name:</TableCell>
                                            : <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>}
                                            <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.name}</TableCell>
                                        </TableRow>
                                        {forecastType === 'public_weather_zones' || forecastType === 'fire_weather_zones' ?
                                        <TableRow>
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>State:</TableCell>
                                            <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.state}</TableCell>
                                        </TableRow>
                                        : null }
                                        <TableRow>
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Service Date:</TableCell>
                                            <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(feature.properties.idp_filedate)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    })
                }
                </div>
            </FeatureInfoAccordion>
        )
    });

    let beachContent = [...beachTypes].map((beachType,index) => {
        let status = true;
        if(!productsOpen[beachType] || productsOpen[beachType] === false) {
            status = false;
        }
        return (
            <FeatureInfoAccordion key={index}
                styleOverride={{borderLeft: "10px solid " + getBorderColor(clickData[beachType].features[0])}}
                featureName={"Beach and Surf Zones"}
                expanded={status}
                setFeatureIsOn={() => {setProductsOpen((prevState) => {return({...prevState, [beachType]: !status})})}}
            >
                <div style={{padding: '5px 0px 10px 0px'}}>
                    <TableContainer key={index}>
                        <Table size="small">
                            <TableHead>
                                <TableRow sx={sxStyles.tableHeader}>
                                    {clickData[beachType].features[0].properties.srfprod ?
                                    <TableCell><Link href={clickData[beachType].features[0].properties.srfprod} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Complete Forecast</Link></TableCell>
                                    : <TableCell sx={sxStyles.linkCell}>Forecast Unavailable</TableCell> }
                                    <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Zone ID:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{clickData[beachType].features[0].properties.id.length > 6 ? clickData[beachType].features[0].properties.id.slice(-6).toUpperCase() : clickData[beachType].features[0].properties.id.toUpperCase()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO ID:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{clickData[beachType].features[0].properties.siteid.toUpperCase()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{clickData[beachType].features[0].properties.beachname}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Service Date:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(clickData[beachType].features[0].properties.idp_filedate)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={sxStyles.linkCell} colSpan={2}><Typography sx={sxStyles.subheaderCell}>Today's Forecast</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Rip Current/Swim Risk:</TableCell>
                                    {clickData[beachType].features[0].properties.rip ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{clickData["beach_forecasts_day1"].features[0].properties.rip.replace(/\.$/, '')}</TableCell>
                                    : <TableCell align="left" sx={sxStyles.tableCell}>Not Available</TableCell> }
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Surf Height:</TableCell>
                                    {clickData[beachType].features[0].properties.surf ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{clickData["beach_forecasts_day1"].features[0].properties.surf.replace(/\.$/, '')} ({convertSurfToMetric(clickData["beach_forecasts_day1"].features[0].properties.surf.replace(/\.$/, ''))})</TableCell>
                                    : <TableCell align="left" sx={sxStyles.tableCell}>Not Available</TableCell> }
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={sxStyles.linkCell} colSpan={2}><Typography sx={sxStyles.subheaderCell}>Tomorrow's Forecast</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.rowHeader}>Rip Current/Swim Risk:</TableCell>
                                    {clickData[beachType].features[0].properties.rip ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{clickData["beach_forecasts_day2"].features[0].properties.rip.replace(/\.$/, '')}</TableCell>
                                    : <TableCell align="left" sx={sxStyles.tableCell}>Not Available</TableCell> }
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Surf Height:</TableCell>
                                    {clickData[beachType].features[0].properties.surf ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{clickData["beach_forecasts_day2"].features[0].properties.surf.replace(/\.$/, '')} ({convertSurfToMetric(clickData["beach_forecasts_day2"].features[0].properties.surf.replace(/\.$/, ''))})</TableCell>
                                    : <TableCell align="left" sx={sxStyles.tableCell}>Not Available</TableCell> }
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" colSpan={2} align="center" sx={sxStyles.legendCell}>
                                        {beachLegend(props)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </FeatureInfoAccordion>
        )
    });

    return(
        <div style={classes.zoneForecastsFeatureInfoContainer}>
            {beachContent}
            {zoneContent}
        </div>
    );
}

function getBorderColor(feature) {
    var color;
    let rip = feature.properties.rip;
    if(RIP_CURRENT_COLORS.hasOwnProperty(rip)) {
        color = RIP_CURRENT_COLORS[rip];
    } else {
        color = RIP_CURRENT_COLORS["Not Provided"];
    }
    return color;
}

function convertSurfToMetric(string) {
    var newString = string.replace(/\d+/g, function(m) { return (0.3048*parseFloat(m)).toFixed(2)+''; }).replaceAll('feet', 'm').replaceAll('foot', 'm');
    return newString;
}