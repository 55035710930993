import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

const classes = {
    legendIMG:{
        maxWidth: '100%',
        maxHeight: '95em'
    },
};

// the layer used as a key for the legend info is hard coded here, this should always match the parse found in capabilities-handler-wms.js
const LAYER_KEY = "chesbay_vibrio_vulnificus";

/**
* Displays legend image with a label above it
*
* @prop (obj) PathogenStyleInfo - legend info that pertains only to vibrio (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function PathogenLegend(props){

    if (!props.PathogenStyleInfo) return null;

    return (
        <ListSubheader component="div" >
            <Typography sx={sxStyles.label}>{props.PathogenStyleInfo[LAYER_KEY][0].title} (%)</Typography>
            <img style={classes.legendIMG} alt="" src={props.PathogenStyleInfo[LAYER_KEY][0].url} />
        </ListSubheader>
    );
}

export default PathogenLegend;