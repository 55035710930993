import { Typography, CircularProgress, Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import LayerOptionsContainer from './layer-options-container.js';
import S111Legend from '../legend-menu-items/ofs-surface-currents-legend.js';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import LayerMenuItem from './layer-menu-item.js';
import { getSurfaceCurrentLayersOn, toggleSurfaceCurrents } from '../../../../features/mapClickSlice.js';
import { OFS_SFC_CURRENTS_INFO } from '../../../config.js';

const classes = {
    menuItemBodyContainer: {
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
    },
};

const sxStyles = {
    layerCheckBoxLabel: {
        fontSize: '80%',
    },
    regionCheckBoxLabel: {
        fontSize: '80%',
        color: '#777',
    },
    layerCheckBox: {
        marginLeft: '1.3em',
        marginBottom: -1,
    },
    regionCheckBox: {
        marginLeft: '-0.2em',
        marginBottom: -1,
    }
}

/**
* S111Select: Selects current variable to be displayed for s111
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function OfsControl(props) {
    const coastalOceanLayers = Object.keys(OFS_SFC_CURRENTS_INFO).filter(layer => OFS_SFC_CURRENTS_INFO[layer].group === 'coastal_ocean');
    const greatLakesLayers = Object.keys(OFS_SFC_CURRENTS_INFO).filter(layer => OFS_SFC_CURRENTS_INFO[layer].group === 'great_lakes');
    const globalOceanLayers = Object.keys(OFS_SFC_CURRENTS_INFO).filter(layer => OFS_SFC_CURRENTS_INFO[layer].group === 'global');
    const checkIfOn = (layerList) => {
        for(const layer of layerList) {
            if(props.olLayerState[layer].on) {
                return true;
            }
        }
        return false;
    };

    const [greatLakesChecked, setGreatLakesChecked] = useState(checkIfOn(greatLakesLayers));
    const [coastalOceanChecked, setCoastalOceanChecked] = useState(checkIfOn(coastalOceanLayers));
    const [globalOceanChecked, setGlobalOceanChecked] = useState(checkIfOn(globalOceanLayers));
    const sfcLayersOn = useSelector(getSurfaceCurrentLayersOn);
    const dispatch = useDispatch();

    const toggleGlobalOceans = (toggle, returnState=true) => {
        const globalOceanUpdates = {};
        setGlobalOceanChecked(toggle);
        Object.assign(globalOceanUpdates, {
            rtofs_east_sfc_currents : {
                ...props.olLayerState['rtofs_east_sfc_currents'],
                'on': toggle
            },
            rtofs_west_sfc_currents : {
                ...props.olLayerState['rtofs_west_sfc_currents'],
                'on': toggle
            }
        });
        if(toggle && coastalOceanChecked) {
            setCoastalOceanChecked(false);
            for(const layer of coastalOceanLayers) {
                Object.assign(globalOceanUpdates, {
                    [layer] : {
                        ...props.olLayerState[layer],
                        'on': false
                    },
                });
            }
        }
        if(returnState) {
            return globalOceanUpdates;
        } else {
            props.updateOlLayerState(globalOceanUpdates);
        }
    }

    const handleLayerToggle = (layer) => {
        const layerUpdates = {};
        const layerIsOn = props.olLayerState[layer].on;
        if(greatLakesLayers.includes(layer)) {
            if(greatLakesChecked) {
                Object.assign(layerUpdates, {
                    [layer] : {
                        ...props.olLayerState[layer],
                        'on': !layerIsOn
                    },
                });
            } else {
                if(!sfcLayersOn.includes(layer)) {
                    setGreatLakesChecked(true);
                    for(const sfcLayerOn of sfcLayersOn) {
                        if(greatLakesLayers.includes(sfcLayerOn)) {
                            Object.assign(layerUpdates, {
                                [sfcLayerOn] : {
                                    ...props.olLayerState[sfcLayerOn],
                                    'on': true
                                },
                            });
                        }
                    }
                    Object.assign(layerUpdates, {
                        [layer] : {
                            ...props.olLayerState[layer],
                            'on': !layerIsOn
                        },
                    });
                }
            }
        }
        if(coastalOceanLayers.includes(layer)) {
            if(coastalOceanChecked) {
                Object.assign(layerUpdates, {
                    [layer] : {
                        ...props.olLayerState[layer],
                        'on': !layerIsOn
                    },
                });
            } else {
                if(!sfcLayersOn.includes(layer)) {
                    if(globalOceanChecked) {
                        const globalOceanUpdates = toggleGlobalOceans(false);
                        Object.assign(layerUpdates, globalOceanUpdates);
                    }
                    setCoastalOceanChecked(true);
                    for(const sfcLayerOn of sfcLayersOn) {
                        if(coastalOceanLayers.includes(sfcLayerOn)) {
                            Object.assign(layerUpdates, {
                                [sfcLayerOn] : {
                                    ...props.olLayerState[sfcLayerOn],
                                    'on': true
                                },
                            });
                        }
                    }
                    Object.assign(layerUpdates, {
                        [layer] : {
                            ...props.olLayerState[layer],
                            'on': !layerIsOn
                        },
                    });
                }
            }
        }
        dispatch(toggleSurfaceCurrents(layer));
        props.updateOlLayerState(layerUpdates);
    };

    const handleGreatLakesToggle = () => {
        const layerUpdates = {};
        let noGreatLakesLayersOn = true;
        const isGreatLakesOn = greatLakesChecked;
        setGreatLakesChecked(!isGreatLakesOn);
        for(const layer of greatLakesLayers) {
            if(sfcLayersOn.includes(layer)) {
                if(!isGreatLakesOn) {
                    noGreatLakesLayersOn = false;
                }
                Object.assign(layerUpdates, {
                    [layer] : {
                        ...props.olLayerState[layer],
                        'on': !isGreatLakesOn
                    },
                });
            }
        }
        if(!isGreatLakesOn) {
            if(noGreatLakesLayersOn) {
                Object.assign(layerUpdates, {
                    'leofs_sfc_currents' : {
                        ...props.olLayerState['leofs_sfc_currents'],
                        'on': !isGreatLakesOn
                    },
                });
                dispatch(toggleSurfaceCurrents('leofs_sfc_currents'));
            }
        }
        props.updateOlLayerState(layerUpdates);
    };

    const handleCoastalToggle = () => {
        const layerUpdates = {};
        let noCoastalLayersOn = true;
        const isCoastalOn = coastalOceanChecked;
        setCoastalOceanChecked(!isCoastalOn);
        for(const layer of coastalOceanLayers) {
            if(isCoastalOn) {
                if(sfcLayersOn.includes(layer)) {
                    Object.assign(layerUpdates, {
                        [layer] : {
                            ...props.olLayerState[layer],
                            'on': !isCoastalOn
                        },
                    });
                }
            } else {
                if(sfcLayersOn.includes(layer)) {
                    noCoastalLayersOn = false;
                    Object.assign(layerUpdates, {
                        [layer] : {
                            ...props.olLayerState[layer],
                            'on': !isCoastalOn
                        },
                    });
                }
            }
        }
        if(!isCoastalOn) {
            if(noCoastalLayersOn) {
                Object.assign(layerUpdates, {
                    'cbofs_sfc_currents' : {
                        ...props.olLayerState['cbofs_sfc_currents'],
                        'on': !isCoastalOn
                    },
                });
                dispatch(toggleSurfaceCurrents('cbofs_sfc_currents'));
            }
            if(globalOceanChecked) {
                const globalOceanUpdates = toggleGlobalOceans(false);
                Object.assign(layerUpdates, globalOceanUpdates);
            }
        }
        props.updateOlLayerState(layerUpdates);
    };

    return(
        <FormGroup>
        <FormLabel><Typography variant="overline" >Gridded Model Forecast Guidance</Typography></FormLabel>
        <Typography variant="overline">Surface Currents (Speed)</Typography>
        <FormControlLabel
            label={<Typography sx={sxStyles.regionCheckBoxLabel}>Great Lakes (NOS)</Typography>}
            control={
                <Checkbox
                    checked={greatLakesChecked}
                    onClick={() => {handleGreatLakesToggle()}}
                    size="small"
                />
            }
            sx={sxStyles.regionCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Lake Erie</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('leofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('leofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Lakes Michigan & Huron</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('lmhofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('lmhofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Lake Ontario</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('loofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('loofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Lake Superior</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('lsofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('lsofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.regionCheckBoxLabel}>Estuary/Coastal Ocean (NOS)</Typography>}
            control={
                <Checkbox
                    checked={coastalOceanChecked}
                    onClick={() => {handleCoastalToggle()}}
                    size="small"
                />
            }
            sx={sxStyles.regionCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Chesapeake Bay</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('cbofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('cbofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Delaware Bay</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('dbofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('dbofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Gulf of Maine</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('gomofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('gomofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>NY & NJ Harbor</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('nyofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('nyofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Northern Gulf of America</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('ngofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('ngofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>San Francisco Bay</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('sfbofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('sfbofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>Tampa Bay</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('tbofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('tbofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.layerCheckBoxLabel}>West Coast</Typography>}
            control={
                <Checkbox
                    checked={sfcLayersOn.includes('wcofs_sfc_currents')}
                    onClick={() => {handleLayerToggle('wcofs_sfc_currents')}}
                    size="small"
                />
            }
            sx={sxStyles.layerCheckBox}
        />
        <FormControlLabel
            label={<Typography sx={sxStyles.regionCheckBoxLabel}>Global Ocean (NWS)</Typography>}
            control={
                <Checkbox
                    checked={globalOceanChecked}
                    onClick={() => {toggleGlobalOceans(!globalOceanChecked, false)}}
                    size="small"
                />
            }
            sx={sxStyles.regionCheckBox}
        />
        </FormGroup>
    );
}

/**
* S111LayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) s111Active - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setS111Active - callback for setting s111Active
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) styleInfo - obj containing style info for just S111
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*   @prop (func) updateBasemap - changes basemap
*   @prop (str) selectedBasemap - name of current basemap, for setting highlighted basemap in basemap menu
*   @prop (str) prevBasemap - name of previous basemap, for flipping back to old basemap when VLM is toggled off
*/
function S111LayerMenuItem(props){
    return (
        <LayerMenuItem
            layerName={"s111"}
            label={"Surface Water Currents"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.s111Active}
            setLayerIsActive={props.setS111Active}
            updateBasemap={props.updateBasemap}
            selectedBasemap={props.selectedBasemap}
            prevBasemap={props.prevBasemap}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"s111"}
                    infoContent={<Typography variant="caption"> Latest  forecast guidance of water currents from NOAA
                    Operational Oceanographic Forecast Modeling  Systems for oceans, coastal ocean and Great Lakes.
                    Data Source: NOS and NWS/NCEP</Typography>}
                    legendContent={
                        props.styleInfo ?
                            <S111Legend
                                S111StyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                >
                    <OfsControl
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default S111LayerMenuItem;