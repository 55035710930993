import { Typography } from '@mui/material';
import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import VLMLegend from '../legend-menu-items/vlm-legend.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* VLMOptions: Check boxes for toggling layers
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function VLMOptions(props) {
    return(
        <FormGroup>
            <FormLabel><Typography variant="overline">Vertical Land Motion<br></br></Typography></FormLabel>
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["vlm_velocity"].on}
                onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["vlm_velocity"].on}, "vlm_velocity")}} />}
                label={<Typography variant="caption">Average Annual Velocity (mm/yr)</Typography>}
            />
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["vlm_acceleration"].on}
                onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["vlm_acceleration"].on}, "vlm_acceleration")}} />}
                label={<Typography variant="caption" align="left">Average Annual Acceleration (mm/yr<sup>2</sup>)</Typography>}
            />
        </FormGroup>
    );

}

/**
* VLMLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) VLMActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setVLMActive - callback for setting VLMActive
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) styleInfo - obj containing style info for just VLM
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*   @prop (func) updateBasemap - changes basemap
*   @prop (str) selectedBasemap - name of current basemap, for setting highlighted basemap in basemap menu
*   @prop (str) prevBasemap - name of previous basemap, for flipping back to old basemap when VLM is toggled off
*/
function VLMLayerMenuItem(props){
    return (
        <LayerMenuItem
            layerName={"vlm"}
            label={"Vertical Land Motion"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.VLMActive}
            setLayerIsActive={props.setVLMActive}
            updateBasemap={props.updateBasemap}
            selectedBasemap={props.selectedBasemap}
            prevBasemap={props.prevBasemap}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"vlm"}
                    infoContent={<Typography variant="caption" style={{display: 'inline-block'}}>
                     Average annual velocity (mm/yr) and acceleration (mm/yr<sup>2</sup>) for the U.S. East Coast is based on Vertical Land Motion (VLM) data for the period from March 2017 to May 2023. The rates are with respect to the GRS80 ellipsoid. The VLM data were estimated using high-accuracy Interferometric Synthetic Aperture Radar (InSAR) data for extensive geographical areas. The InSAR data were obtained via several spaceborne SAR platforms that provided an observation every 10-11 days. VLM can be the gradual or sudden upward or downward motion of the Earth’s surface as a result of natural or anthropogenic activities.
                     <br /><br />Data Source: NOS/NGS
                    </Typography>}
                    legendContent={
                        <VLMLegend
                            VLMStyleInfo={props.styleInfo}
                        />
                    }
                    capUrlsContent={props.capUrlsContent}
                >
                    <VLMOptions
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default VLMLayerMenuItem;