import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
        mb: '10px',
    },
};

// WWA uses one legend for all layers thus only one layer is parsed from capabilities
// the layer used as a key for the legend info is hard coded here, this should always match the parse found in capabilities-handler-wms.js
const LAYER_KEY = "watches_warnings_advisories";

/**
* Displays legend image with a label above it
*
* @prop (obj) WWAStyleInfo - legend info that pertains only to WWA (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function WWALegend(props){

    if (!props.WWAStyleInfo) return null;

    const wwaLegendOpt = '&legend_options=countMatched:true;fontAntiAliasing:true;hideEmptyRules:true;forceLabels:on;dpi:91&SCALE=200001';

    return (
        <ListSubheader component="div">
            <Typography sx={sxStyles.label}>{props.WWAStyleInfo[LAYER_KEY][0].title}</Typography>
            <img alt="" src={props.WWAStyleInfo[LAYER_KEY][0].url + wwaLegendOpt} />
        </ListSubheader>
    );
}

export default WWALegend;