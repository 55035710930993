import { styled, useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery, Button, IconButton, Grow, Paper, ClickAwayListener, Grid} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoRounded';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const sxStyles = {
    siteInfoMenuContainer: { // Paper that contains the site info menu menu
        position: {
          sm: 'absolute',
          xs: 'fixed'
        },
        top: {
            sm: '3.5rem', // sm and up
            xs: '0rem'
        },
        right: {
            xs: '0rem'
        },
        padding: '0.75em',
        width: {
            sm: 'auto',
            xs: 'calc(100vw - 1.5em)' // Padding adds to width so 100vh without subtracting 2x the padding was causing overflow and hiding scroll
        },
        zIndex: '1000',
        overflow: 'auto',
        maxHeight: {
            xl: '75vh', //'46em',
            lg: '70vh', //'46em',
            md: '70vh', //'46em',
            sm: '70vh', //'42em',
            xs: '70vh',
        },
    },
    siteInfoToggleButton: { // button that toggles the site info menu on and off
        backgroundColor: '#FFFFFF',
        color: 'primary.main',
        '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
        },
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.5)',
        borderRadius: 25,
        opacity: '85%',
    },
    siteInfoToggleIcon: {
//        '&:hover': {
//            backgroundColor: '#ffffff',
//            borderRadius: 25,
//            color: 'primary.main',
//        },
    },
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
    footerText: {
    },
};

const classes = {
    noaaLogo: {
        maxHeight: '1.5em',
        width: '1.5em',
        marginRight: '0.25em',
        //boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 1)',
        userSelect: 'none',
        //borderRadius: '50%',
        //float: 'left',
        display: 'inline',
        position: 'relative',
        top: '0.4em',
    },
};

const IconLabelTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '65%',
        '& *' : {
            color: theme.palette.primary.light,
        }
    },
}));

/**
* Wrapper for conditionally rendering a tooltip only at cellphone size
*/
function CellPhoneToolTip(props) {
    const theme = useTheme();
    if (useMediaQuery(theme.breakpoints.down('md'))) { //sm and xs (.down is exclusive)
        return(
            <IconLabelTooltip arrow title="INFO" placement="top">
                {props.children}
            </IconLabelTooltip>
        );
    }
    return(
        <>
            {props.children}
        </>
    );
}

/*
* @prop toggleSiteIngoMenu (func) - Callback for toggling site info menu
**/
function SiteInfoToggleButton(props) {
    const theme = useTheme();
    const buttonContent = <><InfoOutlinedIcon sx={sxStyles.siteInfoToggleIcon} /><Typography variant="caption" style={{fontSize:  useMediaQuery(theme.breakpoints.down('xs')) ? "75%" : "" }}>{useMediaQuery(theme.breakpoints.down('md')) ? '' : 'Info'}</Typography></>;
    const cellphone = (useMediaQuery(theme.breakpoints.down('md'))); //sm and xs (.down is exclusive)

    return (
        <CellPhoneToolTip>
            { (cellphone) ?
                <IconButton size="small" onClick={props.toggleSiteInfoMenu} sx={sxStyles.siteInfoToggleButton} >
                    {buttonContent}
                </IconButton>
                :
                <Button size="small" onClick={props.toggleSiteInfoMenu} sx={sxStyles.siteInfoToggleButton} >
                    {buttonContent}
                </Button>
            }
        </CellPhoneToolTip>
    );
}

function SiteInfoMenuContent(props) {
    return(
        <>
            <Grid container  sx={{mb: '0.5em'}} style={{textAlign: "center"}}>
                <Grid item xs={10} >
                    <Typography align="center" variant="h5" sx={{marginBottom: '0.5em', marginLeft: '3em'}}><img style={classes.noaaLogo} alt="NOAA" src={require("../../images/noaa_logo_small_alpha_cropped.png")} />nowCOAST</Typography>
                </Grid>
                <Grid item xs={2} >
                    <IconButton size="large" color="primary" onClick={props.toggleSiteInfoMenu} >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12} >
                    <Typography align="center" variant="body1">Latest Marine Weather and Coastal Information</Typography>
                </Grid>
            </Grid>
            <div style={{borderBottom: '1px solid rgba(0, 0, 0, .125)', marginLeft: 10, marginRight: 10, marginBottom: 10}}></div>
            <Grid container  sx={{mb: '0.5em'}} style={{textAlign: "center"}}>
                <Grid item xs={12} >
                    <Typography variant="h6" color="primary">Vision</Typography>
                    <Typography variant="caption">Provide critical NOAA information to the maritime community (commercial and recreational mariners, port authorities) and the federal, state, and local emergency management community to support informed timely decisions on a daily basis.</Typography>
                    <Typography variant="h6" color="primary">Mission Statement</Typography>
                    <Typography variant="caption">To provide present and future oceanographic, meteorological, and hydrologic information (observations, analyses, forecasts, alerts) from NOAA along with bathymetric data for U.S. coastal areas to support public safety, coastal preparedness, response, and precision marine navigation.</Typography>
                    <Typography variant="h6" color="primary">Strategy</Typography>
                    <Typography variant="caption">Provide near-real-time NOAA information from NOS, NWS, and NESDIS via Open Geospatial Consortium compliant web mapping services and an interactive map viewer.</Typography><br/><br/>
                    <Typography variant="caption"><b>Information about the products available from nowCOAST can be found at </b></Typography>
                    <Typography variant="caption" sx={sxStyles.links}><a href="https://nauticalcharts.noaa.gov/updates/data-dissemination-web-interface-supports-navigation/" target="_blank" rel="noopener noreferrer">https://nauticalcharts.noaa.gov/updates/data-dissemination-web-interface-supports-navigation/</a></Typography>
                </Grid>
            </Grid>
            <Grid container  sx={{mb: '0.5em'}} style={{textAlign: "center"}}>
            </Grid>
            <div style={{borderBottom: '1px solid rgba(0, 0, 0, .125)', marginLeft: 10, marginRight: 10, marginBottom: 10}}></div>
            <Grid container  sx={{mb: '0.5em'}} style={{textAlign: "center"}}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Typography align="center" variant="caption">Website Not Certified for Navigational Use</Typography><br/>
                    <Typography variant="caption" sx={sxStyles.links}><a href="https://nauticalcharts.noaa.gov/about/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a href="https://oceanservice.noaa.gov/disclaimer.html" target="_blank" rel="noopener noreferrer">Disclaimer</a> | <a href="https://touchpoints.app.cloud.gov/touchpoints/41f29ea6/submit" target="_blank" rel="noopener noreferrer">Take our Survey</a></Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Typography variant="caption" sx={sxStyles.links}>Website Owned By: <a href="https://nauticalcharts.noaa.gov/" target="_blank" rel="noopener noreferrer">NOS/Office of Coast Survey</a></Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Typography variant="caption" sx={sxStyles.links}>Contact: nowcoast.team@noaa.gov</Typography>
                </Grid>
            </Grid>
            <Typography variant="caption" align="center" sx={sxStyles.footerText}>Developed by NOAA/NOS/Office of Coast Survey</Typography>
        </>
    );
}
/**
* SiteInfoMenu: contains info menu toggle button and container for info menu itself
*
* @prop (func) setLayerMenuOn - callback for updating layerMenuOn
* @prop (func) setLegendMenuOn  - callback for updating legend menu open/closed
* @prop (func) setInfoMenuOn  - callback for updating info menu open/closed
* @prop (bool) infoMenuOn - true if the info menu is open, else false
* @prop (bool) mapClickPopupOn - true if map-click-popup is on, else false
* @prop (func) updateMapClickPopup - callback for updating mapClickPopupOn
*/
function SiteInfoMenu(props) {

    const toggleSiteInfoMenu = () => {
        const curState = props.infoMenuOn;
        if (!curState) {
            props.setLayerMenuOn(false);
            props.setLegendMenuOn(false);
        }
        // Turn off feature info popup if menu is going from false to true and feature info popup is on
        if (props.mapClickPopupOn && !curState) {
            props.updateMapClickPopup(false);
        }
        props.setInfoMenuOn(!curState);
    }

    const handleClickAway = () => {
        props.setInfoMenuOn(false);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <SiteInfoToggleButton
                    toggleSiteInfoMenu={toggleSiteInfoMenu}
                />
                <Grow in={props.infoMenuOn} style={{ transformOrigin: 'top right' }}>
                    <Paper elevation={3} sx={sxStyles.siteInfoMenuContainer} >
                        <SiteInfoMenuContent toggleSiteInfoMenu={toggleSiteInfoMenu}/>
                    </Paper>
                </Grow>
            </div>
        </ClickAwayListener>
    );
}

export default SiteInfoMenu;