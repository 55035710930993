import { configureStore } from '@reduxjs/toolkit';
//import layerStateReducer from './features/layerStateSlice';
//import timeStateReducer from './features/timeStateSlice';
//import menuStateReducer from './features/menuStateSlice';
import mapClickReducer from './features/mapClickSlice';

const store = configureStore({
    reducer: {
        //layerState: layerStateReducer,
        //timeState: timeStateReducer,
        //menuState: menuStateReducer,
        mapClick: mapClickReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export default store;