import {Typography, CircularProgress} from '@mui/material';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import MRMSLegend from '../legend-menu-items/mrms-legend.js';

// NOTE: once we have developed more content for the menu item body contents, it may be useful to
// create a NowCoastMenuItemBody component that can be shared by all nowcoast menu items to make this
// formatting reusable for each menu item. (assuming they are uniform enough)
const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* MRMSLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) mrmsActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setMrmsActive - callback for setting mrmsActive
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) styleInfo - obj containing style info for just MRMS
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function MRMSLayerMenuItem(props){

    return (
        <LayerMenuItem fontSize="small"
            layerName={"mrms"}
            label={"Weather Radar"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.mrmsActive}
            setLayerIsActive={props.setMrmsActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"mrms"}
                    infoContent={<Typography variant="caption">
                        The Weather radar base reflectivity mosaics are created using data from U.S. Weather Surveillance
                        Radar 1988 Doppler (WSR-88D) sites operated by NWS and DOD and also from the Canadian Environment
                        and Climate Change (ECCC) network of S-band dual polarization radars. The horizontal resolution is
                        1 km (0.62 mi). The mosaics are updated approximately every 2 minutes. The colors on the map
                        represent the strength of the energy reflected back toward the radar and are given in dBZ (decibels
                        of z). Data source: NWS & OAR Multi-Radar/Multi-Sensor (MRMS) System.</Typography>
                    }
                    legendContent={
                        props.styleInfo ?
                            <MRMSLegend
                                MRMSStyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                />
            </div>
        </LayerMenuItem>
    );
}

export default MRMSLayerMenuItem;

