import { useEffect } from "react";

import OLLayer from './layer.js';
//import CustomLayer from './custom-layer.js';
import { LAYERS } from '../../config.js';

/**
* React component responsible for creating all OLLayer components within map
*
* @prop (obj) map - the OpenLayers map object used for app
* @prop (obj) dimensionState - dimension state update from dimension controller
*   ex. { <url>: {<sourceName>: {<dimension1>: <val>, <dimension2>: <val>, ...}, ...}, ...}
* @prop (obj) initializedCaps - contains initialization status of datasets that must wait on Get Capabilities requests to be enabled
* @prop (func) setInitializedCaps - setter function  for initializedCaps
* @prop (obj) customLayerInfo - All info related to user-added layers (functionality incomplete)
* @prop (obj) layerToggles - app state containing layer names mapped to true/false values for on/off
*   Note: layer names layer group names from highest level of LAYERS object from app/config.js
* @prop (obj) layerOpacities - object containing a layer opacity value (0-100) for each top-level layer
* @prop (obj) olLayerState - maps ol layer names to obj containing "on", "layerParam", "stylesParam, "currentSource"
* @prop (bool) refreshLayers - Use to trigger the re-adding of all layers to the map when toggled
*/
const OLLayerManager = (props) => {
    // Track initialization of capabilities based on arrival of dimensionStates
	useEffect(() => {
		if (!props.dimensionState || !props.initializedCaps || !props.setInitializedCaps) return;
		let tmpInitializedCaps = props.initializedCaps;
		let newLayerInitialized = false;
		for (const product in props.initializedCaps) {
		    //if a product is still uninitialized, check if the new dimension state has it
		    if (!props.initializedCaps[product]) {
		        if (product in props.dimensionState) {
		            newLayerInitialized = true;
		            Object.assign(tmpInitializedCaps, {[product]: true});
                }
		    }
		}
		if (newLayerInitialized) {
		    props.setInitializedCaps(tmpInitializedCaps);
		};
	}, [props]);

    const layers = Object.entries(LAYERS).map(([product, layerInfo]) => { // product refers to top level keys in config.js LAYERS obj
        let layerSubset = null;
        if (layerInfo && layerInfo.layers){
            layerSubset = Object.entries(layerInfo.layers).map(([olLayerName, olLayerInfo]) => {
                return (
                    <OLLayer key={olLayerName}
                        map={props.map}
                        olLayerState={props.olLayerState}
                        sourceInfo={olLayerInfo.sources}
                        layerObj={olLayerInfo.layerObj}
                        layerOn={(!props.layerToggles[product]) ? props.layerToggles[product] : props.olLayerState[olLayerName].on}
                        currentSourceName={props.olLayerState[olLayerName].currentSource}
                        layerList={props.olLayerState[olLayerName].layersParam}
                        styleList={props.olLayerState[olLayerName].stylesParam}
                        zIndex={olLayerInfo.zIndex}
                        opacity={(props.layerOpacities) ? props.layerOpacities[product] : null}
                        dimensionState={(props.dimensionState && layerInfo.animated && product in props.dimensionState) ? props.dimensionState[product] : null}
                        autoRefresh={(olLayerName === "wwa") ? 1000 * 90 : null} // NOTE: special case for wwa ONLY (may become a feature for other layers in the future)
                        refreshLayers={props.refreshLayers}
                    />
                );
            });
        }
        return (layerSubset);
    });


    // Handle Custom/User-Added layers
    // Note: These layers have limited functionality (just toggling and opacity)
    // So they do not actually need a OLSource object, we just create the layer here
    // as well as the source object and we only manipulate the layer obj over its lifetime
    // Note: Despite this being a common component, until the functionality is complete,
    // ensure that everything will work normally if no customLayerInfo prop is passed
    // since this is a common component and PN app.js is not accounting for this functionality
    // Note: Many props are left undefined, this is not an issue, the functionality related
    // to the unused props simply will not exist for these layers
    // TODO: Consider how to support multiple layer types (currently only creating vector layers for displaying geoJSON)
    //let customLayers = null;
    //if (props.customLayerInfo) {
    //    customLayers = Object.entries(props.customLayerInfo).map(([layerName, layerInfo]) => {
    //        return(
    //            <CustomLayer key={layerName}
    //                map={props.map}
    //                layerOn={props.customLayerInfo[layerName].on}
    //                zIndex={props.customLayerInfo[layerName].zIndex}
    //                opacity={props.customLayerInfo[layerName].opacity}
    //                url={props.customLayerInfo[layerName].url}
    //            />
    //        );
    //    });
    //}

	return (
	    <div>{layers}</div>
	);
};

export default OLLayerManager;