import { Typography, Grid } from '@mui/material';
import { CONVECTIVE_LAYERS } from '../../../config.js';

const sxStyles = {
    header: {
        color: '#252525',
        fontSize: '90%',
        mb: '5px'
    },
    legendLabel: {
        color: '#252525',
        fontSize: '75%',
        textAlign: 'center',
        mr: 3,
        mb: '5px'
    },
    gridContainer: {
        spacing: 2,
        columnGap: 2,
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        ml: 3
    }
};

const classes = {
    legendIMG:{
        paddingTop: 5,
        width: '100%'
    },
    label: {
        color: '#252525',
    },
};

/**
* Displays legend image with a label above it
*
* @prop olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*
*/
function ConvectiveLegend(props){
    //external WMS
    const legend_url = "https://mapservices.weather.noaa.gov/vector/services/outlooks/SPC_wx_outlks/MapServer/WMSServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=";

    let legendList = []

    let convectiveContent = Object.keys(CONVECTIVE_LAYERS).map((convectiveType,index) => {
        if(props.olLayerState[convectiveType].on && !legendList.includes(CONVECTIVE_LAYERS[convectiveType].label)) {
            legendList.push(CONVECTIVE_LAYERS[convectiveType].label);
            let url = legend_url
            return (
                <div key={CONVECTIVE_LAYERS[convectiveType].id} component="div" style={classes.label}>
                    <Typography sx={sxStyles.header}>{CONVECTIVE_LAYERS[convectiveType].label}</Typography>
                    {convectiveType.includes("categorical") ?
                    <Typography sx={sxStyles.header}>{CONVECTIVE_LAYERS[convectiveType].legend_text}</Typography>
                    : null}
                    <Grid container sx={sxStyles.gridContainer} key={CONVECTIVE_LAYERS[convectiveType].id}>
                        <Grid item >
                            <img style={classes.legendIMG} alt="" src={url + CONVECTIVE_LAYERS[convectiveType].id} />
                        </Grid>
                        <Grid item xs={convectiveType.includes("significant") || convectiveType.includes("probabilistic_tornado") ? 7 : 8}>
                            {convectiveType.includes("categorical") ? null
                            : convectiveType.includes("probabilistic_tornado") ?
                            <Typography sx={{ml: 3, ...sxStyles.legendLabel}}>{CONVECTIVE_LAYERS[convectiveType].legend_text}</Typography>
                            : <Typography sx={sxStyles.legendLabel}>{CONVECTIVE_LAYERS[convectiveType].legend_text}</Typography>}
                        </Grid>
                    </Grid>
                </div>
            )
        }
        else {return null}
    });

    return (
        <div style={{marginLeft:'0.5em' }}>
            {convectiveContent}
        </div>
    );
}

export default ConvectiveLegend;