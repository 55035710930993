import {Typography} from '@mui/material';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* GenericLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @props (str) layerName - Name of layer (for Generic this is both the key in customLayerInfo as well as name to display in menu)
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) layerActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setLayerActive - callback for setting GenericActive
*   @props (num) opacity - Current opacity value for the layer
*   @prop (func) updateLayerOpacities - Callback for updating layer opacity
*/
function GenericLayerMenuItem(props){

    return (
        <LayerMenuItem
            layerName={props.layerName}
            label={props.layerName}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.layerActive}
            setLayerIsActive={props.setLayerActive}
            activeLayers={props.activeLayers}
            setActiveLayers={props.setActiveLayers}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={props.layerName}

                    layersList={props.layersList}
                    infoContent={<Typography variant="caption" style={{display: 'inline-block'}}>
                        User-added layer: {props.layerName}
                    </Typography>}
                    legendContent={<Typography>Legend Unavailable</Typography>}
                />
            </div>
        </LayerMenuItem>
    );
}

export default GenericLayerMenuItem;