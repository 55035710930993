import { METAR_CLOUDS, METAR_WEATHER, METAR_WEATHER_TEXT } from '../config.js';

/**
* getData - basic fetching & JSON parsing function
* @param (string) url
*
* @returns (obj) - parsed JSON object
*/
export async function getData(url) {
    try {
        const response = await fetch(url);
        const data = await response.text();
        const parsedData = JSON.parse(data);
        return parsedData;
    } catch (e) { console.log(e); return null; };
};

/**
* getDataMultiple - function for fetching and parsing data from multiple urls
* @param (obj) urls - must have format {layerName1 : "url", layerName2 : "url2"}
*
* @returns (obj) - format {layerName1 : {JSON data}, layerName2 : {JSON data}}
*/
export async function getDataMultiple(urls) {
    const newData = {};
    try {
        for (const layerName in urls) {
            const response = await fetch(urls[layerName]);
            const data = await response.text();
            const parsedData = JSON.parse(data);
            Object.assign(newData, {[layerName] : parsedData});
        }
    } catch (e) { console.log(e); };
    return newData;
};

/**
* findIndexBS (Binary Search -- REQUIRES sorted array)
* Simple binary search for retrieving index of item in sorted array of ints
*
* @param (number) val - value to get index of
* @param (array - iterable/sorted) list - array to search
* @param (int) startIndex
* @param (int) endIndex
*
* @returns (number) - index of element in array, null if not found
*/

export function findIndexBS(val, list, startIndex, endIndex) {

    const midIndex = Math.floor((startIndex + endIndex) / 2);

    // Base Cases
    if (val === list[midIndex]) {
        return (midIndex);
    }
    if (startIndex > endIndex) {
        return (null);
    }

    // Recursive step, look below or above the midIndex
    if (val < list[midIndex]) {
        return findIndexBS(val, list, startIndex, midIndex - 1);
    } else {
        return findIndexBS(val, list, midIndex + 1, endIndex);
    }
}

// Note: currently using below function. Top function may be useful but consider removing in the future

/**
* findClosestIndexBS (Binary Search -- REQUIRES sorted array)
* Simple binary search for retrieving closest index of item in sorted array of ints
*
* @param (number) val - value to get index of
* @param (array - iterable/sorted) list - array to search
* @param (int) startIndex
* @param (int) endIndex
*
* @returns (number) - index of closest element in array
*/

export function findClosestIndexBS(val, list, startIndex, endIndex) {
    if (!list || list.length === 0) return null;

    const midIndex = Math.floor((startIndex + endIndex) / 2);

    // Base Cases
    // exact match
    if (val === list[midIndex]) {
        return (midIndex);
    }
    // val is between two indexes, so return the closest one
    if (Math.abs(startIndex - endIndex) === 1) {
        const diff1 = Math.abs(val - list[startIndex]);
        const diff2 = Math.abs(val - list[endIndex]);
        return (diff1 < diff2) ? startIndex : endIndex;
    }

    // Recursive step, look below or above the midIndex
    if (val < list[midIndex]) {
        if (val < list[midIndex-1]) {
            return findClosestIndexBS(val, list, startIndex, midIndex - 1);
        } else {
            return findClosestIndexBS(val, list, midIndex - 1, midIndex); // to base case
        }
    } else {
        if (val > list[midIndex+1]) {
            return findClosestIndexBS(val, list, midIndex + 1, endIndex);
        } else {
            return findClosestIndexBS(val, list, midIndex, midIndex + 1); // to base case
        }
    }
}

/**
* Function originally defined in time-slider.js - Consider moving to a common file
*
* Returns string from epoch time in form "<month>/<day> <hour>:<min> <am/pm>"
*/
export function shortDateString(val) {
    const dateVal = new Date(val);
    const month = dateVal.getMonth() + 1;
    const day = dateVal.getDate();
    let ampm = "am";
    let hours = dateVal.getHours();
    if (hours >= 12) { //0-11 is am, 12-23 is pm
        ampm = "pm";
        hours = hours%12;
    }
    hours = hours === 0 ? 12 : hours;
    let minutes = dateVal.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return (month + "/" + day + " " + hours + ":" + minutes + " " + ampm);
}

/*
* rangeDateString
* Convert given date object (actual Date or accepted date string) to string format
*
* Returns string from epoch time in form "<year><month><day>"
*/
export function rangeDateString(val) {
    const dateVal = new Date(val);
    const year = dateVal.getFullYear();
    const month = (dateVal.getMonth() + 1).toString().padStart(2, "0");
    const day = dateVal.getDate().toString().padStart(2, "0");
    return (`${year}${month}${day}`);
}

/*
* tooltipDateString
* Convert given Date object to string format
*
* Returns string from epoch time in form "<month name>, <day> <hour>:<min> <am/pm>"
*/
export function tooltipDateString(val) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
        month: 'short',
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: timeZone,

    }
    return(new Intl.DateTimeFormat('en-US', options).format(val))
}

/**
 * getTimeZone
 * getTimeZoneName sourced from: https://stackoverflow.com/a/73584867 ;
 * short' returns EST, 'long' returns Easter Standard time
 *
 * @returns (string) - string containing shorthand timezone from system timezone
 * (unsupported timezones will return an empty string)
**/
export function getTimeZone(style = 'short') {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const getTimeZoneName = (timeZone: string, nameType: 'short' | 'long') => {
        const formatter = new Intl.DateTimeFormat('en-US', { timeZone, timeZoneName: nameType })
        const formatted = formatter.formatToParts(new Date())

        return formatted.find(({ type }) => type === 'timeZoneName')?.value || ''
    }
    return getTimeZoneName(timeZone, style);
}

/**
 * changeTimezoneGMT
 * Convert the given GMT datetime to a new time zone
 *
 * @returns (date) - date object
 **/
export function changeTimezoneGMT(date, ianatz) {

  var indate = new Date(date.toLocaleString('en-US', {
    timeZone: "GMT"
  }));

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = indate.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

/**
 * tempToFormatString
 * Creates string of input value interpreted as temperature in fahrenheit and it's converted celcius value
 *
 * @returns (string) - string containting of a value interpreted as fahrenheit/celcius
 **/
export function tempToFormatString(val) {
    const tempString = val.toString() + " °F / " + ((val - 32) * 5/9).toFixed(1).toString() + " °C"
    return tempString
}

/**
* timeStringFromRequestUrl
* Pulls 'time' parameter from a URL request and returns it as a formatted string
*
* @param (string) val - Feature info request URL
*
* @returns (string) - formatted datetime string
**/

export function timeStringFromRequestUrl(val) {
    const urlParams = new URLSearchParams(val)
    return (shortDateString(urlParams.get("time")) + " " + getTimeZone())
}

/**
* Function used to parse surface obs timestamp
* @param (string) - date string provided by surface obs WFS
* @returns (Date obj) - Date object with observation timestamp
**/
export function parseObsTimestamp(dateString) {
    const dateTime = dateString.split(" ");
    const date = dateTime[0].split("/");
    const time = dateTime[1].split(":");
    let ZModifier;
    if(dateTime[2].toUpperCase() === "PM" && parseInt(time[0]) < 12) {
        ZModifier = 12;
    } else {
        if(parseInt(time[0]) === 12) {
            ZModifier = -1;
        } else {
            ZModifier = 0;
        }
    }
    const month = parseInt(date[0]) - 1;
    const day = parseInt(date[1]);
    const year = parseInt(date[2]);
    const hour = parseInt(time[0]) + ZModifier;
    const minute = parseInt(time[1]);
    const second = parseInt(time[2]);
    const timestamp = new Date(Date.UTC(year, month, day, hour, minute, second));
    return timestamp;
}

/**
* Function used to parse a METAR code for weather
* @param (string) - METAR code
* @returns (array) - array of weather code strings
**/
export function parseMETARWeather(metar) {
    const tokenizedMetarCode = metar.split(" ").slice(3);
    let weatherCodes = [];
    for(const token of tokenizedMetarCode) {
        if(token.substring(0,1) === "+" || token.substring(0,1) === "-") {
            if(token.length === 3 && METAR_WEATHER.includes(token.substring(1,3))) {
                weatherCodes.push(token.substring(0,1));
                weatherCodes.push(token.substring(1,3));
            } else if(token.length === 5 && METAR_WEATHER.includes(token.substring(3,5))) {
                weatherCodes.push(token.substring(0,1));
                weatherCodes.push(token.substring(1,3));
                weatherCodes.push(token.substring(3,5));
            }
        }
        if(METAR_WEATHER.includes(token.substring(0,2))) {
            if(token.length === 2) {
                weatherCodes.push(token.substring(0,2));
            } else if(token.length === 4 && METAR_WEATHER.includes(token.substring(2,4))) {
                weatherCodes.push(token.substring(0,2));
                weatherCodes.push(token.substring(2,4));
            } else if(token.length === 6 && METAR_WEATHER.includes(token.substring(4,6))) {
                weatherCodes.push(token.substring(0,2));
                weatherCodes.push(token.substring(2,4));
                weatherCodes.push(token.substring(4,6));
            }
        }
        if(token === "VIRGA") {
            weatherCodes.push("VIRGA");
        }
    }
    const parser = require("metar");
    try {
        const parsedMETAR = parser(metar);
        for(const code of parsedMETAR.weather) {
            if(!weatherCodes.includes(code.abbreviation)) {
                weatherCodes.push(code.abbreviation);
            }
        }
        return weatherCodes;
    } catch(e) { return weatherCodes; }
}

/**
* Function used to translate METAR weather codes into text
* @param (array) - array of weather codes
* @returns (string) - weather text
**/
export function getWeatherText(weatherCodes) {
    let weatherString = "";
    let endTags = [];
    if(weatherCodes && weatherCodes !== "") {
        for(const weather of weatherCodes) {
            if(weather !== "") {
                if(weather === 'VC') {
                    endTags.push(METAR_WEATHER_TEXT[weather]);
                } else {
                    weatherString = weatherString + " " + METAR_WEATHER_TEXT[weather].toUpperCase();
                    if(endTags.length > 0) {
                        weatherString = weatherString + " " + endTags.pop().toUpperCase();
                    }
                }
            }
        }
    }
    return weatherString;
}

/**
* Function used to parse a METAR code for cloud cover
* @param (string) - METAR code
* @returns (string) - string with densest cloud cover from METAR
**/
export function parseMETARClouds(metar) {
    const tokenizedMetarCode = metar.split(" ").slice(3);
    let cloudCodes = [];
    let cloudCover = null;
    for(const token of tokenizedMetarCode) {
        if(METAR_CLOUDS.includes(token.substring(0,3))) {
            cloudCodes.push(token.substring(0,3))
        }
        if(token.substring(0,2) === 'VV') {
            cloudCodes.push(token.substring(0,2))
        }
    }
    if(cloudCodes.includes("OVC") || cloudCodes.includes("10.")) {
        cloudCover = "OVC";
    } else if(cloudCodes.includes("8.8")) {
        cloudCover = "8.8";
    } else if(cloudCodes.includes("BKN") || cloudCodes.includes("7.5")) {
        cloudCover = "BKN";
    } else if(cloudCodes.includes("6.3")) {
        cloudCover = "6.3";
    } else if(cloudCodes.includes("5.0")) {
        cloudCover = "5.0";
    } else if(cloudCodes.includes("VV")) {
        cloudCover = "VV";
    } else if(cloudCodes.includes("3.7")) {
        cloudCover = "3.7";
    } else if(cloudCodes.includes("2.5") || cloudCodes.includes("SCT") || cloudCodes.includes("SKT")) {
        cloudCover = "SCT";
    } else if(cloudCodes.includes("1.2") || cloudCodes.includes("FEW")) {
        cloudCover = "FEW";
    } else if(cloudCodes.includes("0.0") || cloudCodes.includes("SKC") || cloudCodes.includes("CLR")) {
        cloudCover = "CLR";
    }
    return cloudCover;
}