import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery, Paper, Grid, Typography, Button, IconButton, ClickAwayListener, Grow } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MapIcon from '@mui/icons-material/Map';

/**
* BasemapMenu: All content/state/functionality within the basemap menu
*
* @prop (str) selectedBasemap - name of current basemap, for setting highlighted basemap in basemap menu
* @prop (func) updateBasemap - changes basemap
*
*/
const sxStyles = {
    'menuContainer': { // Paper that contains the menu
        position: 'absolute',
        top: '3.5em',
        right: {
            sm: '21.5em',
            xs: '0em',
        },
        padding: '0.75em',
        width: '20em',
        height: '8em',
        opacity: '0.85 !important', // ToDo: Figure out what was overriding opacity here and get rid of '!important'
    },
    'basemapToggleButton': { // button that toggles the menu on and off
        backgroundColor: '#ffffff',
        color: 'primary.main',
        '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
        },
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.5)',
        borderRadius: 25,
        opacity: '85%',
    },
    'basemapMenuItem': { // buttons within the menu to select new basemap layer
        padding: '0em',
        overflow: 'none',
        '& img': {
            height: '5em',
            borderRadius: 2
        },
    }
};

const IconLabelTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '65%',
        '& *' : {
            color: theme.palette.primary.light,
        }
    },
}));

/**
* Wrapper for conditionally rendering a tooltip only at cellphone size
*/
function CellPhoneToolTip(props) {
    const theme = useTheme();
    if (useMediaQuery(theme.breakpoints.only('xs'))) {
        return(
            <IconLabelTooltip arrow title="BASEMAPS" placement="top">
                {props.children}
            </IconLabelTooltip>
        );
    }
    return(
        <>
            {props.children}
        </>
    );
}

function BasemapToggleButton(props) {
    const theme = useTheme();
    const buttonContent = <><MapIcon /><Typography variant="caption" style={{fontSize:  useMediaQuery(theme.breakpoints.down('xs')) ? "75%" : "" }}>{useMediaQuery(theme.breakpoints.only('xs')) ? '' : 'Basemaps'}</Typography></>;
    const cellphone = (useMediaQuery(theme.breakpoints.only('xs')));

    return (
        <CellPhoneToolTip>
            { (cellphone) ?
                <IconButton size="small" onClick={props.toggleBasemapMenu} sx={sxStyles.basemapToggleButton} >
                    {buttonContent}
                </IconButton>
                :
                <Button size="small" onClick={props.toggleBasemapMenu} sx={sxStyles.basemapToggleButton} >
                    {buttonContent}
                </Button>
            }
        </CellPhoneToolTip>
    );
}

function BasemapMenuContent(props) {
    const theme = useTheme();
    const selectedButtonStyle = {
        border: '4px solid ' + theme.palette.secondary.light,
    };

    const handleChangeBasemap = basemap => () => {
        props.updateBasemap(basemap);
    };

    return (
        <Grid container
            spacing={1}
            direction="row"
            justify="space-evenly"
            alignItems="center"
        >
            <Grid item xs={12}>
                <Typography align="center" variant="subtitle2">Basemaps</Typography>
            </Grid>
            <Grid item xs={3} >
                <Grid container >
                    <Grid item xs={12} align="center">
                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("osm/streets")}>
                            <img src={require("../../images/basemap_icon_osmtopo.png")} alt="OSM" style={props.selectedBasemap === "osm/streets" ? selectedButtonStyle : {}} />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"} align="center">OSM</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} >
                <Grid container >
                    <Grid item xs={12} align="center">
                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/imagery")}>
                            <img src={require("../../images/basemap_icon_osmsatellite.png")} alt="satellite" style={props.selectedBasemap === "arcgis/imagery" ? selectedButtonStyle : {}} />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"} align="center">Satellite</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} >
                <Grid container >
                    <Grid item xs={12} align="center">
                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/oceans")}>
                            <img src={require("../../images/basemap_icon_ocean.png")} alt="ocean" style={props.selectedBasemap === "arcgis/oceans" ? selectedButtonStyle : {}} />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"} align="center">Ocean</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} >
                <Grid container >
                    <Grid item xs={12} align="center">
                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/imagery/standard")}>
                            <img src={require("../../images/basemap_icon_satellite.png")} alt="Imagery" style={props.selectedBasemap === "arcgis/imagery/standard" ? selectedButtonStyle : {}} />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"} align="center">Imagery</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

// Original return which renders all 8 basemap options (temporarily disabled 1/14/25)
//    return (
//        <Grid container
//            spacing={1}
//            direction="row"
//            justify="space-evenly"
//            alignItems="center"
//        >
//            <Grid item xs={12}>
//                <Typography align="center" variant="subtitle2">Basemaps</Typography>
//            </Grid>
//            <Grid item xs={3} >
//                <Grid container >
//                    <Grid item xs={12} align="center">
//                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/topographic")}>
//                            <img src={require("../../images/basemap_icon_topo.png")} alt="world topo" style={selectedBasemap === "arcgis/topographic" ? selectedButtonStyle : {}} />
//                        </Button>
//                    </Grid>
//                    <Grid item xs={12}>
//                        <Typography variant={"body2"} align="center">Topographic</Typography>
//                    </Grid>
//                </Grid>
//            </Grid>
//            <Grid item xs={3} >
//                <Grid container >
//                    <Grid item xs={12} align="center">
//                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/imagery")}>
//                            <img src={require("../../images/basemap_icon_osmsatellite.png")} alt="satellite" style={selectedBasemap === "arcgis/imagery" ? selectedButtonStyle : {}} />
//                        </Button>
//                    </Grid>
//                    <Grid item xs={12}>
//                        <Typography variant={"body2"} align="center">Satellite</Typography>
//                    </Grid>
//                </Grid>
//            </Grid>
//            <Grid item xs={3} >
//                <Grid container >
//                    <Grid item xs={12} align="center">
//                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/dark-gray")}>
//                            <img src={require("../../images/basemap_icon_darkgray.png")} alt="dark gray" style={selectedBasemap === "arcgis/dark-gray" ? selectedButtonStyle : {}} />
//                        </Button>
//                    </Grid>
//                    <Grid item xs={12}>
//                        <Typography variant={"body2"} align="center">Dark Gray</Typography>
//                    </Grid>
//                </Grid>
//            </Grid>
//            <Grid item xs={3} >
//                <Grid container >
//                    <Grid item xs={12} align="center">
//                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/oceans")}>
//                            <img src={require("../../images/basemap_icon_ocean.png")} alt="ocean" style={selectedBasemap === "arcgis/oceans" ? selectedButtonStyle : {}} />
//                        </Button>
//                    </Grid>
//                    <Grid item xs={12}>
//                        <Typography variant={"body2"} align="center">Ocean</Typography>
//                    </Grid>
//                </Grid>
//            </Grid>
//            <Grid item xs={3} >
//                <Grid container >
//                    <Grid item xs={12} align="center">
//                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/streets-relief")}>
//                            <img src={require("../../images/basemap_icon_osmoutdoor.png")} alt="Streets" style={selectedBasemap === "arcgis/streets-relief" ? selectedButtonStyle : {}} />
//                        </Button>
//                    </Grid>
//                    <Grid item xs={12}>
//                        <Typography variant={"body2"} align="center">Streets</Typography>
//                    </Grid>
//                </Grid>
//            </Grid>
//            <Grid item xs={3} >
//                <Grid container >
//                    <Grid item xs={12} align="center">
//                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/imagery/standard")}>
//                            <img src={require("../../images/basemap_icon_satellite.png")} alt="Imagery" style={selectedBasemap === "arcgis/imagery/standard" ? selectedButtonStyle : {}} />
//                        </Button>
//                    </Grid>
//                    <Grid item xs={12}>
//                        <Typography variant={"body2"} align="center">Imagery</Typography>
//                    </Grid>
//                </Grid>
//            </Grid>
//            <Grid item xs={3} >
//                <Grid container >
//                    <Grid item xs={12} align="center">
//                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("arcgis/human-geography")}>
//                            <img src={require("../../images/basemap_icon_osmtoner.png")} alt="Light Gray" style={selectedBasemap === "arcgis/human-geography" ? selectedButtonStyle : {}} />
//                        </Button>
//                    </Grid>
//                    <Grid item xs={12}>
//                        <Typography variant={"body2"} align="center">Light Gray</Typography>
//                    </Grid>
//                </Grid>
//            </Grid>
//            <Grid item xs={3} >
//                <Grid container >
//                    <Grid item xs={12} align="center">
//                        <Button sx={sxStyles.basemapMenuItem} onClick={handleChangeBasemap("osm/streets")}>
//                            <img src={require("../../images/basemap_icon_osmtopo.png")} alt="OSM" style={selectedBasemap === "osm/streets" ? selectedButtonStyle : {}} />
//                        </Button>
//                    </Grid>
//                    <Grid item xs={12}>
//                        <Typography variant={"body2"} align="center">OSM</Typography>
//                    </Grid>
//                </Grid>
//            </Grid>
//        </Grid>
//    );
}

function BasemapMenu(props) {
    const theme = useTheme();
    const [basemapMenuOn, setBasemapMenuOn] = useState(false);

    const toggleBasemapMenu = () => {
        const curState = basemapMenuOn;
        setBasemapMenuOn(!curState);
    }

    const handleClickAway = () => {
        setBasemapMenuOn(false);
    }

    // Note: The click away listener is firing on pretty much any click when the menu is closed... this doesnt seem ideal, look into this
    // maybe do conditional render of the clickawaylistener when the menu is open?

    // Note: Seem to get a warning related to Grow transition, only the first time it fires, about findDOMnode being deprecated

    let menuContainerStyle = sxStyles.menuContainer;
    if (useMediaQuery(theme.breakpoints.only('xs'))) {
        menuContainerStyle = {
            ...sxStyles.menuContainer,
            zIndex: '1000',
        };
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <BasemapToggleButton
                    toggleBasemapMenu={toggleBasemapMenu}
                />
                <Grow in={basemapMenuOn} style={{ transformOrigin: 'top right' }}>
                    <Paper elevation={3} sx={menuContainerStyle} >
                        <BasemapMenuContent
                            selectedBasemap={props.selectedBasemap}
                            updateBasemap={props.updateBasemap}
                        />
                    </Paper>
                </Grow>
            </div>
        </ClickAwayListener>
    );
}

export default BasemapMenu;
