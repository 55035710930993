import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

// STOFS uses one legend for all layers thus only one layer is parsed from capabilities
// the layer used as a key for the legend info is hard coded here, this should always match the parse found in capabilities-handler-wms.js
const LAYER_KEY = "stofs3d_atlantic_disturbance";

/**
* Displays legend image with a label above it
*
* @prop (obj) STOFSStyleInfo - legend info that pertains only to STOFS (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function STOFSLegend(props){
    if (!props.STOFSStyleInfo) return null;

    return (
        <ListSubheader component="div">
            <Typography sx={sxStyles.label}>{props.STOFSStyleInfo[LAYER_KEY][0].title}</Typography>
            <img style={{ margin: '0px 0px 0px -15px'}} alt="" src={props.STOFSStyleInfo[LAYER_KEY][0].url} />
        </ListSubheader>
    );
}

export default STOFSLegend;