import React from 'react';
import {createRoot} from "react-dom/client";
import { ThemeProvider as StyledThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';

import NCMapViewerApp from './map-viewer/app.js';
import theme from "./map-viewer/theme.js";
import store from './store.js';

// Note: strict mode should be applied during development; it helps identify potential issues. More info at https://reactjs.org/docs/strict-mode.html

const container = document.getElementById('root');
const root = createRoot(container);

// Render for nowCOAST (uncomment to use nowCOAST)
root.render(
    <Provider store={store}>
        <StyledThemeProvider theme={theme}>
            <NCMapViewerApp />
        </StyledThemeProvider>
    </Provider>
);

// Only uncomment for development as alternative to the render above without strict mode
//root.render(
//    <React.StrictMode>
//        <StyledThemeProvider theme={theme}>
//            <NCMapViewerApp />
//        </StyledThemeProvider>
//    </React.StrictMode>,
//);