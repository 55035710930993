import { Typography, CircularProgress } from '@mui/material';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import STOFSLegend from '../legend-menu-items/stofs-legend.js';

// NOTE: once we have developed more content for the menu item body contents, it may be useful to
// create a NowCoastMenuItemBody component that can be shared by all nowcoast menu items to make this
// formatting reusable for each menu item. (assuming they are uniform enough)
const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* STOFSLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) stofsActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setStofsActive - callback for setting stofsActive
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) styleInfo - Contains legend info just for STOFS
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*   @prop (func) updateBasemap - changes basemap
*   @prop (str) selectedBasemap - name of current basemap, for setting highlighted basemap in basemap menu
*   @prop (str) prevBasemap - name of previous basemap, for flipping back to old basemap when VLM is toggled off
*/
function STOFSLayerMenuItem(props){

    return (
        <LayerMenuItem
            layerName={"stofs"}
            label={"Inland-Coastal Flooding"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.stofsActive}
            setLayerIsActive={props.setStofsActive}
            updateBasemap={props.updateBasemap}
            selectedBasemap={props.selectedBasemap}
            prevBasemap={props.prevBasemap}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"stofs"}
                    infoContent={<Typography variant="caption" style={{display: 'inline-block'}}>
                    The STOFS-3D-Atlantic uses the Semi-implicit Cross-Scale Hydroscience Integrated System Model
                    (SCHISM) and is run on NOAA WCOSS2 supercomputing system once daily out to 48 hours producing numerical
                    water level guidance for compound flooding from storms (tides, storm surge, riverine flooding,
                    precipitation). The model is forced in the atmosphere by the NCEP Global Forecast System
                    (GFS) and High-Resolution Rapid Refresh (HRRR), in rivers by the National Water Model (NWM),
                    and in the open ocean by the Global Real-Time Ocean Forecast System (Global RTOFS).
                    The Atlantic region unstructured grid consists of 2.6 million nodes with up to 600 m
                    resolution along the coasts, up to 200 m resolution in the watersheds, and up to 10 m
                    resolution for the levees. The grid extends inland up to about 10 m above Mean Sea Level (MSL)
                    for the US East Coast, Gulf of Mexico, and Puerto Rico. STOFS-3D-Atlantic produces water
                    level point output at 164 CO-OPS NWLON stations
                    </Typography>}
                    legendContent={
                        props.styleInfo ?
                            <>
                                <Typography sx={{fontSize: '90%', borderBottom: 'solid 1px #ccc', marginBottom: "5px", paddingBottom: "12px"}}>NOS Surge & Tide Operational Forecast System Model Forecast Guidance</Typography>
                                <STOFSLegend
                                    STOFSStyleInfo={props.styleInfo}
                                />
                            </>
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                />
            </div>
        </LayerMenuItem>
    );
}

export default STOFSLayerMenuItem;