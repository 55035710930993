import DimensionState from './dimension-state-calculator.js';
import eventManager from './event-manager.js';
import logger from './logger.js';

/**
* Manages state for multiple dimension types by ingesting capabilities and maintaining a DimensionState
* object to generate state for each dimension registered with this class
*
* @member {obj} dimensionCalculators - supported dimension names to instances of DimensionState objects
* @member {obj} prevDimVals - stored dimension values from last update call to avoid pushing new state for same values
*/

class DimensionControl {
    /**
    * Constructor, must register dimension names that will be used with this class
    *
    * @param {[string]} dimensionNames - list of dimension names to be be supported by this instance
    */
    constructor(dimensionNames){
        // Create a DimensionState/dimension-state-calculator instance for each dimension given
        this._dimensionCalculators = {};
        // Save previous dimension values to avoid retrieving now state multiple times for the same value
        this._prevDimVals = {};

        dimensionNames.forEach((dimension) => {
            Object.assign(this._dimensionCalculators, {[dimension]: new DimensionState()});
            Object.assign(this._prevDimVals, {[dimension]: null});
        });

        // Adding mixin for event handling
        Object.assign(this, eventManager);
    }

    /**
    * Update capabilities - ingest new capabilities and update relevant state calculations via triggered event
    *   Expects an object containing entries of the form:
    * {
    *   product: product_name,
    *   capabilities: {
    *       cap_name: {
    *           dimensions: {
    *               time: validForecastTimes,
    *               ref_time: latestRefTime
    *           },
    *           attributes: {
    *               legend_info: {
    *                   product: "wutever it is find that parse wherever it was",
    *                   height: 30,
    *                   width: 35
    *               }
    *           }
    *       },
    *       ...
    *   },
    *   snap_thresholds: {
    *       time: thresholdVal,
    *       dim_time_reference: thresholdVal
    *   }
    * }
    * Note: snap_thresholds is optional, all values default to infinity
    *
    * ACHTUNG: snap thresholds should be set on a per product basis whereas this input is written on a per endpoint basis
    *       Possible fix once we have capabilitities retreival for more products could be to emit separate capabiltiesUpdated
    *       events on a per product bases, thus ensuring that snap threshold applies for each product, BUT that would
    *       result in more reinitialization of state controllers than needed, so probly want to classify caps by product type
    *
    *       Note that the caps xml has a <keyword> list, maybe we can have server add product name to that, or streamline
    *       layer names so that product can be parsed out (which it could not in theory)
    *
    *       UPDATE: We have switched from endpoint/url to product name. Some of the above may still be useful and has
    *       not yet been implemented
    */

    updateCapabilities = (caps) => {
        //transform caps obj into valid input for dimension calculator
        // { <dimension_bucket> : {
        //      <capID> : {
        //          values: [valid values],
        //          snap_threshold: threshold
        //      },
        //      ... },
        // ...}

        //create buckets to hold new capabilities
        const newCapsByDimension = {};
        Object.keys(this._dimensionCalculators).forEach((dimension) => {
            Object.assign(newCapsByDimension, {[dimension]: {}});
        });

        //iterate over capabilities and put in buckets by dimension along with snap thresholds
        for (const [capName, capData] of Object.entries(caps.capabilities)) {
            const capID = caps.product + "|" + capName;
            for (const [dimension, dimensionVals] of Object.entries(capData.dimensions)) {
                Object.assign(newCapsByDimension[dimension], {[capID]: {'values': dimensionVals, 'snap_threshold': caps.snap_thresholds[dimension]}});
            }
        }

        //update capabilities for each dimension calculator
        for (const [dimension, dimensionCalculator] of Object.entries(this._dimensionCalculators)) {
            dimensionCalculator.updateCapabilities(newCapsByDimension[dimension]);
            // if a dimension was passed in that is not supported (no calculator made for it at constructor)
            // nothing will be done with it
        }

        let timeValsUnion = null;

        // Publish dimension vals array union for time dimension if applicable
        // (consider extending to all dimensions when other time sliders are in use besides time)  <--------------------------- REFACTOR
        if ("time" in this._dimensionCalculators){
            timeValsUnion = this._dimensionCalculators.time.getDimensionValsUnion();
            this.dispatchEvent("timeValuesUpdated", timeValsUnion);
            // Also push out a new state object using last known selected time value (dimension val)
            const curTime = this._dimensionCalculators.time.getPrevDimensionVal();
            this.updateState({'time': curTime + 1, 'dim_time_reference': curTime + 1});
            // NOTE: the +1 above is needed to change the time. updataState wont do anything if the new time value matches the previous one
            // In just this case we alter it by 1 millisecond to force a new state to be pushed out, which is important since, even thought
            // selected time hasnt changed, the timevalues array may have, and selected time might snap to a different value.
            // (Also it helps with initialization by forcing extra dimensionState objects out which are the metric for determining
            //  if a given layer is done initializing)
        }
    }

    /**
    * updateState - get changes required to reach new state based on dimension values passed in
    *   If invalid dimensions are given or the same values as last execution, nothing will be done
    * @params {obj} newDimensionVal - object of the form:
    *   { "someDimensionName" : int Val, ... }
    *
    * Note: when updating time, be sure to pass the time value for both time and dim_time_reference dimensions
    */
    updateState = (newDimensionVals) => {

        logger("Starting updateState with arg:", 'debug', 'updateState');
        logger(newDimensionVals, 'debug', 'updateState');

        const newStateByDimension = {};

        for (const [dimension, val] of Object.entries(newDimensionVals)) {
            if (typeof(val) === "number" && dimension in this._prevDimVals){
                if (this._prevDimVals[dimension] !== val) {
                    Object.assign(newStateByDimension, {[dimension]: this._dimensionCalculators[dimension].setState(val)});
                    this._prevDimVals[dimension] = val;
                }
            }else{
                logger("[DimensionControl.UpdateState][ERROR] One or more invalid dimension names passed to updateState:", 'error');
                logger(newDimensionVals);
            }
        }

        // Trigger event to publish new dimension state, sends event of form:
        // { dimension : { capID : dimval, ... }, ... }
        if (Object.keys(newStateByDimension).length > 0) {
            logger("Pushing out Updated State with new time val, dispatching raw state update", 'debug', 'updateState');
            logger(newStateByDimension, 'debug', 'updateState');

            this.dispatchEvent("dimensionStateUpdated", newStateByDimension);
        }
    }
}

export default DimensionControl;