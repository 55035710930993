import { useState, useRef } from 'react';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


const sxStyles = {
    leftArrow: {
        backgroundColor: 'rgba(255,255,255,0.3)',
        color: 'rgba(0,0,0,0.3)',
        position: 'absolute',
        top: '19em',
        left: '5px',
        zIndex: '9999',
    },
    rightArrow: {
        backgroundColor: 'rgba(255,255,255,0.3)',
        color: 'rgba(0,0,0,0.3)',
        position: 'absolute',
        right: '5px',
        top: '19em',
        zIndex: '9999',
    },
};

const classes ={
    sliderContainer: {
        display: 'inline-flex',
    },
};

const MAX_MARGIN = 0; // Max left margin on slider = 0 (left side of slider flush with left side of parent container)

/**
* Container that scrolls horizontally by dragging without preventing click events on its contents
*
* @prop (obj) children - Whatever components you want to be able to drag horizontally
* @prop (evil) sliderType - string containing "nav", "forecast", "cellphone-forecast" which will effect positioning of the arrow buttons
* // This breaks the component but the whole thing probably needs to be rewritten to support its original
* // functionality with the addition of the arrow buttons (wasted a lot of time failing to refactor it properly)
**/
export default function HorizontalSlider(props) {
    const theme = useTheme();

    const [leftMargin, setLeftMargin] = useState(0);
    const [initialPosition, setInitialPosition] = useState(null);
    const [initialMargin, setInitialMargin] = useState(null);
    const [pointerIsDown, setPointerIsDown] = useState(false);
    const [displayLeftArrow, setDisplayLeftArrow] = useState(false);
    const [displayRightArrow, setDisplayRightArrow] = useState(true);

    const slider = useRef();

//    //ToDo: Resolve this functionality. These values do not get used, is this branch needed? Is it not doing what was intended?
//    if (slider.current !== undefined ){
//        const sliderWidth = slider.current.getBoundingClientRect().width;
//        const parentWidth = slider.current.parentElement.getBoundingClientRect().width;
//        const minMargin = (parentWidth - sliderWidth) | 0; // Right side of slider flush with right side of parent
//    }

    // ToDo: Address Bug where pointerDown is triggered (by mouse clicking down), then pointerMove (by dragging with mouse still held down)
    // then the cursor is dragged off of the element where the pointerUp listener is and the mouse button is released...
    // If this occurs, the pointerIsDown remains true thus when the cursor returns to this component it still drags even
    // though the mouse button is not being held down anymore. It will not stop sliding with the cursor's x-movement until
    // the user clicks again inside the element to ultimately trigger a pointer up event.
    // The solution is to add detection for the cursor leaving the slider component and then setting pointerIsDown to false when
    // that happens. (This is preferable to adding a pointerUp even to the Document since it is possible for the user to
    // drag the mouse entirely outside the browser which would likely result in the same bug. Also this bug should not exist on touch
    // screens since we cant trigger pointerMove without retriggering pointerDown.)

    let dynamicStyles = {
        marginLeft: leftMargin,
        transitionProperty: 'marginLeft',
        transitionDuration: '2s',
        touchAction: 'pan-y',
    };

    const grabSlider = (e) => {
//        //DEBUG
//        console.log("Slider Clicked at " + e.clientX + " and initial margin: " + leftMargin);
        const sliderWidth = slider.current.getBoundingClientRect().width;
        const parentWidth = slider.current.parentElement.getBoundingClientRect().width;
        const minMargin = (parentWidth - sliderWidth) | 0;
        if (minMargin >= MAX_MARGIN) return; // Slider does not overflow parent so dont let it slide
        if (pointerIsDown) return;
        setPointerIsDown(true);
        setInitialPosition(e.clientX);
        setInitialMargin(leftMargin)
    };

    const dragSlider = (e) => {
        if (!pointerIsDown) return;
//        // DEBUG
//        console.log("Slider DRAGGED, initial position was: " + initialPosition + " and clientX is: " + e.clientX + " and margin is " + leftMargin);
//        console.log(e.clientX);
        //slider.current.setPointerCapture(e.pointerId);
        const sliderWidth = slider.current.getBoundingClientRect().width;
        const parentWidth = slider.current.parentElement.getBoundingClientRect().width;
        const minMargin = (parentWidth - sliderWidth) | 0; // Right side of slider flush with right side of parent
        const positionOffset = e.clientX - initialPosition;
        let newMargin = initialMargin + positionOffset;
        if (newMargin > MAX_MARGIN) {
            newMargin = MAX_MARGIN;
            if (displayLeftArrow) setDisplayLeftArrow(false);
        }else{
            if (!displayLeftArrow) setDisplayLeftArrow(true);
        }

        if (newMargin < minMargin) {
            newMargin = minMargin;
            if (displayRightArrow) setDisplayRightArrow(false);
        }else{
            if (!displayRightArrow) setDisplayRightArrow(true);
        }

        setLeftMargin(newMargin);
//        // DEBUG
//        console.log("should have moved " + positionOffset + "from initial margin of " + initialMargin);
    };

    const releaseSlider = () => {
        setPointerIsDown(false);
        setInitialPosition(null);
    };

    // Expects offset arg: and int saying how far to move (positive for right, negative for left)
    const clickArrowButton = (offset) => {
        let newMargin = leftMargin + offset;

        const sliderWidth = slider.current.getBoundingClientRect().width;
        const parentWidth = slider.current.parentElement.getBoundingClientRect().width;
        const minMargin = (parentWidth - sliderWidth) | 0;
        if (newMargin > MAX_MARGIN) {
            newMargin = MAX_MARGIN;
            if (displayLeftArrow) setDisplayLeftArrow(false);
        }else{
            if (!displayLeftArrow) setDisplayLeftArrow(true);
        }

        if (newMargin < minMargin) {
            newMargin = minMargin;
            if (displayRightArrow) setDisplayRightArrow(false);
        }else{
            if (!displayRightArrow) setDisplayRightArrow(true);
        }

        setLeftMargin(newMargin);
    }

    let leftArrowStyles = sxStyles.leftArrow;
    let rightArrowStyles = sxStyles.rightArrow;
    if (props.sliderType === "cellphone-forecast") {
        leftArrowStyles = {
            display: 'none'
        };
        rightArrowStyles = {
            display: 'none'
        };
    }
    if (props.sliderType === "nav") {
        leftArrowStyles = {
            ...sxStyles.leftArrow,
            top: '2.75em',
            left: '0.25em',
            backgroundColor: 'rgba(255,255,255,0.5)',
            color: theme.palette.primary.main,
            borderRadius: 1,
            borderRight: '2px solid rgba(0, 0, 0, .125)',
            borderTop: '2px solid rgba(0, 0, 0, .025)',
            borderBottom: '2px solid rgba(0, 0, 0, .025)',
            height: '2.85em',
        };
        rightArrowStyles = {
            ...sxStyles.rightArrow,
            top: '2.75em',
            right: '0.25em',
            backgroundColor: 'rgba(255,255,255,0.5)',
            color: theme.palette.primary.main,
            borderRadius: 1,
            borderLeft: '2px solid rgba(0, 0, 0, .125)',
            borderTop: '2px solid rgba(0, 0, 0, .025)',
            borderBottom: '2px solid rgba(0, 0, 0, .025)',
            height: '2.85em',
        };
    };

    return(
        <div
            onPointerDown={grabSlider}
            onPointerMove={dragSlider}
            onPointerUp={releaseSlider}
            ref = {slider}
            style={{
                ...dynamicStyles,
                ...classes.sliderContainer
            }}
        >
            { (displayLeftArrow)
                ? <IconButton size="small" onClick={() => {clickArrowButton(220)}} sx={leftArrowStyles} >
                    <ChevronLeftIcon fontSize="large" />
                  </IconButton>
                : null
            }
            { (displayRightArrow)
                ? <IconButton size="small" onClick={() => {clickArrowButton(-220)}} sx={rightArrowStyles} >
                    <ChevronRightIcon fontSize="large" />
                  </IconButton>
                : null
            }
            {props.children}
        </div>
    );
}

// Note: this style override for IconButton is interesting but had an issue where it it renders in the wrong spot initial since the ref starts as undefined: style={(slider.current) ? {top: Math.floor(slider.current.clientHeight + 6)} : {}}