import { Typography, CircularProgress } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import SSTLegend from '../legend-menu-items/sst-legend.js';

// NOTE: once we have developed more content for the menu item body contents, it may be useful to
// create a NowCoastMenuItemBody component that can be shared by all nowcoast menu items to make this
// formatting reusable for each menu item. (assuming they are uniform enough)
const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* SSTControl: Check boxes for toggling layers
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
**/

function SSTControl(props) {

    return(
        <FormGroup>
            <FormLabel><Typography variant="caption">Sea & Lake Surface Water Temperature</Typography></FormLabel>
            <br></br>
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["nasa_sport_sea_surface_temperature"].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["nasa_sport_sea_surface_temperature"].on}, "nasa_sport_sea_surface_temperature")}} />}
                label={<Typography variant="caption" align='left'>NASA/SPoRT Analysis</Typography>}
            />
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["global_sea_surface_temperature"].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["global_sea_surface_temperature"].on}, "global_sea_surface_temperature")}} />}
                label={<Typography variant="caption" align='left'>NWS Global Analysis</Typography>}
            />
        </FormGroup>
    );

}

/**
* SSTLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) sstActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setSstActive - callback for setting sstActive
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) styleInfo - Contains legend info just for SST
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (func) updateBasemap - changes basemap
*   @prop (str) selectedBasemap - name of current basemap, for setting highlighted basemap in basemap menu
*   @prop (str) prevBasemap - name of previous basemap, for flipping back to old basemap when VLM is toggled off
**/
function SSTLayerMenuItem(props){

    return (
        <LayerMenuItem
            layerName={"sst"}
            label={"Sea Surface Temperature"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.sstActive}
            setLayerIsActive={props.setSstActive}
            updateBasemap={props.updateBasemap}
            selectedBasemap={props.selectedBasemap}
            prevBasemap={props.prevBasemap}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"sst"}
                    infoContent={
                    <>
                    <Typography variant="caption" style={{display: 'inline-block'}}>
                    The NASA/SPoRT Sea Surface Temperature (SST) composite provides satellite-retrieval estimates
                    of surface water temperatures for nearly the entire globe. The analysis is a 7-day weighted
                    composite of the following data and analyses: 1) SST retrieval by VIIRS on S-NPP, NOAA-20, 
                    and NOAA-21 satellites and 2) SST retrieval by AVHRR on MetOp-B and MetOp-C satellites.
                    Where no satellite retrieval has occurred over the last 7 days the NOAA/NESDIS daily global
                    blended GOES/POES SST Analysis and the United Kingdom Meteorological Office daily Operational
                    Sea Surface Temperature and Ice Analysis (OSTIA) system are used to fill gaps. However, for
                    inland lakes the GOES/POES SST analysis is not available. The SPoRT SST is calculated on a
                    2-km (1.1 nm) grid. The 7-day composite is generated twice daily and valid for the periods
                    0000-1159 UTC and 1200-2359 UTC. The composite is not 24 x 7 operationally supported by NASA,
                    so it should be considered 'Data of Opportunity'.
                    </Typography>
                    <br/> <br/>
                    <Typography variant="caption" style={{display: 'inline-block'}}>
                    The NWS Global SST Analysis is generated by the Near-Surface Sea Temperature (NSST) Scheme of the
                    NWS/NCEP Global Data Assimilation System and the Global Forecast System.  The analysis provides
                    estimates of surface water temperatures for the world oceans, seas (e.g. Black Sea) and the
                    Great Lakes.  The NSST analysis is specifically the GDAS 1200 UTC NSST “foundation temperature”
                    interpolated to the 1/12 degrees resolution grid of the decommissioned NWS Real-Time High Resolution
                    SST Analysis.  The NSST global analysis is generated by NCEP once per day.
                    </Typography>
                    <br/> <br/>
                    <Typography variant="caption" style={{display: 'inline-block'}}>
                    Sources: NASA Short-Term Prediction Research and Transition (SPoRT) Group and NWS/NCEP
                    Environmental Modeling Center’s Modeling and Data Assimilation Branch
                    </Typography>
                    </>
                    }
                    legendContent={
                        props.styleInfo ?
                            <>
                                <SSTLegend
                                    SSTStyleInfo={props.styleInfo}
                                />
                            </>
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                >
                    <SSTControl
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default SSTLayerMenuItem;