import { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { getData } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const classes ={
    PathogenFeatureInfoContainer: {
        padding: '10px'
    }
};

/**
* Component for rendering results from getFeatureInfo wms requests
*
**/
export default function PathogenFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["pathogen"].data;
    const clickCoords = useSelector(getMapClickInfo)["pathogen"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["pathogen"].url) return;

        const fetchData = async () => {
            getData(mapClickURLs["pathogen"].url)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"pathogen" : {"data" : data, "coords" : mapClickURLs["pathogen"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        if(clickCoords.x !== mapClickURLs["pathogen"].coords.x || clickCoords.y !== mapClickURLs["pathogen"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) { }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    if (clickData.features && clickData.features.length > 0 ) {
        const pointDataValue = Number.parseFloat(clickData.features[0].properties['Band1']).toFixed(2);
        if(pointDataValue >= 0) {
            return(
                <div className={classes.PathogenFeatureInfoContainer}>
                    <Typography align="center">Vibrio Vulnificus Probability of Occurrence</Typography>
                    <div style={{
                        borderBottom: '1px solid rgba(0, 0, 0, .125)',
                        marginLeft: 10, marginRight: 10,
                        }}
                    >
                    </div>
                    <br />
                        <Typography sx={{fontSize: '1.0em'}} align="center">{pointDataValue} %</Typography>
                    <br />
                </div>
            );
        } else {
            return(
                <div style={{padding: '50px 20px'}}>
                    <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
                </div>
            );
        }
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}