import { useState, useEffect } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import FeatureInfoAccordion from './feature-info-accordion';
import useHighlightLayer from './hooks/use-highlight-layer';

// THIS COMPONENT SHOULD NOT BE IN COMMON IF USING THIS IMPORT
import { OL_ZINDEXES, TROPICAL_SS_FEATURES } from '../../config';
import { getDataMultiple } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const LAYER_TO_LABEL = {
    tropical_cyclone_current_wind_extent: "Wind Extent",
    tropical_cyclone_track_forecast: "Forecast Track",
    tropical_cyclone_cone_of_uncertainty_forecast: "Cone of Uncertainty",
    tropical_cyclone_intensity_forecast: "Forecast Position & Intensity",
    tropical_cyclone_watches_warnings: "Watches & Warnings",
    tropical_cyclone_observed_locations: "Observed Locations",
    tropical_cyclone_observed_wind_extent: "Observed Wind Extent",
    tropical_cyclone_observed_track: "Observed Track",
    tropical_ss: "Tropical Storm Surge",
}

const sxStyles = {
    tableContainer: {
        paddingLeft: '10px',
    },
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        border: 'none',
    },
    advisoryLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        }
    },
};

// JSS styles for non-mui components
const classes = {
    cyclonesFeatureInfoContainer: {
        marginTop: '10px',
    },
    TropicalSSFeatureInfoContainer: {
        padding: '10px',
    }
};

// Map basin abbreviations to basin names
const BASIN_NAMES = {
    "al": "Atlantic",
    "ep": "Eastern Pacific",
    "cp": "Central Pacific"
};

/**
* Safely translate NHC's tropical cyclone Basin abbreviations to their full names
*/
function getFullBasinName(shortName) {
    if (shortName.toLowerCase() in BASIN_NAMES) {
        return(BASIN_NAMES[shortName.toLowerCase()]);
    }
    return shortName;
}

/**
* Render tables of feature info (2 columns with categories on the left and values on the right
* with option of one row header)
*
*   @prop (str) advisoryLink - url to text advisory, (optional) will be displayed at head of table if provided
*   @prop ([[str]] body - Array of length 2 Arrays [[<left body content>, <right body content>], ...]
*/
function RenderFeatureInfoTable(props) {

    const bodyRows = props.body.map((rowCells, i) => {
        return(
            <TableRow key={i}>
                <TableCell component="th" scope="row" sx={sxStyles.tableCell}>{rowCells[0]}</TableCell>
                <TableCell align="left" sx={sxStyles.tableCell}>{rowCells[1]}</TableCell>
            </TableRow>
        );
    });

    return(
        <TableContainer sx={sxStyles.tableContainer}>
            <Table size="small">
                { (props.advisoryLink) ?
                    <TableHead>
                        <TableRow sx={sxStyles.tableHeader}>
                            <TableCell><Link href={props.advisoryLink} sx={sxStyles.advisoryLink} target="_blank" rel="noopener noreferrer">Text Bulletin</Link></TableCell>
                            <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                        </TableRow>
                    </TableHead>
                : null
                }
                <TableBody>
                    {bodyRows}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (ol map obj) map - The open layers map to draw highlights on
* @prop (string) activeTropicalSSLayer - current tropical storm surge layer being displayed used to select proper legend
* @prop (obj) styleInfo - legend info that pertains only to tropical cyclones/storm surge (derived from top-level state obj: styleInfo)
*
**/
export default function TropicalCyclonesFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["tropical_cyclones"].data;
    const clickCoords = useSelector(getMapClickInfo)["tropical_cyclones"].coords;
    const [dataFound, setDataFound] = useState(false);
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    // layersOpen tracks state of feature info accordions, this component will only
    // highly/draw features on the map whose feature info accordion is open
    const [layersOpen, setLayersOpen] = useState({});

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.tropical_cyclones_highlight_layer);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["tropical_cyclones"].urls) return;

        const fetchData = async () => {
            getDataMultiple(mapClickURLs["tropical_cyclones"].urls)
                .then((data) => {
                    for (const key in data) {
                        if (data[key] && data[key].features) {
                            if (data[key].features.length > 0) {
                                setDataFound(true);
                                break;
                            }
                        }
                    }
                    dispatch(mapClickInfoUpdated({"tropical_cyclones" : {"data" : data, "coords" : mapClickURLs["tropical_cyclones"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        setLayersOpen({});

        if(clickCoords.x !== mapClickURLs["tropical_cyclones"].x || clickCoords.y !== mapClickURLs["tropical_cyclones"].y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) { }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

   useEffect(() => {
        // Handle drawing of GeoJSON features returned by getFeatureInfo as well as check for presence of data
        clearHighlight();
        if (Object.keys(clickData).length > 0) {
            for (const key in clickData) {
                if (key !== "tropical_ss") { // Do not try to outline storm surge features
                    if (clickData[key] && clickData[key].features) {
                        // There are features to draw, but only if the associated accordion is open
                        if (layersOpen[key] && layersOpen[key] === true) {
                            for (const feature of clickData[key].features) {
                                drawFeatureHighlight(feature);
                            }
                        }
                    }
                }
            }
        }
   }, [layersOpen, clearHighlight, clickData, drawFeatureHighlight]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    if (!dataFound) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location. Please try another location, toggle desired layers on/off, or adjust the time control, then try again.</Typography>
            </div>
        );
    }

    let content = Object.keys(clickData).map((layerName,index) => {
        let status = true;
        if(!layersOpen[layerName] || layersOpen[layerName] === false) {
            status = false;
        }
        if (clickData[layerName].features.length > 0) {
            return (
                <FeatureInfoAccordion key={index}
                    styleOverride={{backgroundColor: '#f9f9f9'}}
                    featureName={LAYER_TO_LABEL[layerName]}
                    expanded={status}
                    setFeatureIsOn={() => {setLayersOpen((prevState) => {return({...prevState, [layerName]: !status})})}}
                >
                    <div>
                    {
                        clickData[layerName].features.map((feature, index) => {
                            if (layerName === "tropical_cyclone_track_forecast") {
                                return(
                                    <div style={{padding: '10px'}} key={index}>
                                        <RenderFeatureInfoTable
                                            advisoryLink={"https://www.nhc.noaa.gov/"}
                                            body={[
                                                ["Storm Name:", feature.properties.stormname],
                                                ["Storm Type:", feature.properties.stormtype],
                                                ["Advisory Date/Time (UTC):", feature.properties.advdate],
                                                ["Advisory Num:", feature.properties.advisnum],
                                                ["Storm Num:", feature.properties.stormnum],
                                                ["Basin:", getFullBasinName(feature.properties.basin)]
                                            ]}
                                        />
                                    </div>
                                );
                            }else if (layerName === "tropical_cyclone_cone_of_uncertainty_forecast") {
                                return(
                                    <div style={{padding: '10px'}} key={index}>
                                        <RenderFeatureInfoTable
                                            advisoryLink={"https://www.nhc.noaa.gov/"}
                                            body={[
                                                ["Storm Name:", feature.properties.stormname],
                                                ["Storm Type:", feature.properties.stormtype],
                                                ["Forecast Period (hrs):", feature.properties.fcstprd],
                                                ["Advisory Date/Time (UTC):", feature.properties.advdate],
                                                ["Advisory Num:", feature.properties.advisnum],
                                            ]}
                                        />
                                    </div>
                                );
                            }else if (layerName === "tropical_cyclone_current_wind_extent") {
                                return(
                                    <div style={{padding: '10px'}} key={index}>
                                        <RenderFeatureInfoTable
                                            advisoryLink={"https://www.nhc.noaa.gov/"}
                                            body={[
                                                ["Max Sustained Wind (knots):", feature.properties.radii],
                                                ["Valid Date/Time (UTC):", feature.properties.validtime],
                                                ["Advisory Num:", feature.properties.advnum],
                                                ["Storm Number:", feature.properties.stormnum],
                                                ["Basin:", getFullBasinName(feature.properties.basin)],
                                            ]}
                                        />
                                    </div>
                                );
                            }else if (layerName === "tropical_cyclone_intensity_forecast") {
                                return(
                                    <div style={{padding: '10px'}} key={index}>
                                        <RenderFeatureInfoTable
                                            advisoryLink={"https://www.nhc.noaa.gov/"}
                                            body={[
                                                ["Storm Name:", feature.properties.stormname],
                                                ["Forecast Proj. (hrs):", feature.properties.tau], // tau is "hours into forecast cycle apparently... This matches old nowcoast anyway... this places each storm symbol 12 hours apart... but the datelbl seems to suggest otherwise so idk the deal exactly
                                                ["Valid Date/Time:", feature.properties.fldatelbl],
                                                ["Storm Type:", feature.properties.stormtype],
                                                ["Basin:", getFullBasinName(feature.properties.basin)],
                                                ["Max Sust. Wind (kts):", feature.properties.maxwind],
                                                ["Max Gust (kts):", feature.properties.gust],
                                                ["Mean Sea Level Pressure (mb)", feature.properties.mslp],
                                                ["Dir of Movement (degrees):", feature.properties.tcdir],
                                                ["Forward Speed:", feature.properties.tcspd],
                                                ["Latitude:", feature.properties.lat],
                                                ["Longitude:", feature.properties.lon],
                                                ["Advisory Number:", feature.properties.advisnum],
                                            ]}
                                        />
                                    </div>
                                );
                            }else if (layerName === "tropical_cyclone_observed_track") {
                                return(
                                    <div style={{padding: '10px'}} key={index}>
                                        <RenderFeatureInfoTable
                                            body={[
                                                ["Storm Number:", feature.properties.stormnum],
                                                ["Storm Type:", feature.properties.stormtype],
                                                ["Basin:", getFullBasinName(feature.properties.basin)],
                                            ]}
                                        />
                                    </div>
                                );
                            }else if (layerName === "tropical_cyclone_observed_locations") {
                                return(
                                    <div style={{padding: '10px'}} key={index}>
                                        <RenderFeatureInfoTable
                                            advisoryLink={"https://www.nhc.noaa.gov/"}
                                            body={[
                                                ["Storm Name:", feature.properties.stormname],
                                                ["Storm Number:", feature.properties.stormnum],
                                                ["Storm Type:", feature.properties.stormtype],
                                                ["Intensity:", feature.properties.intensity],
                                                ["Month:", feature.properties.month],
                                                ["Day:", feature.properties.day],
                                                ["Year:", feature.properties.year],
                                                ["Latitude:", feature.properties.lat],
                                                ["Longitude:", feature.properties.lon],
                                            ]}
                                        />
                                    </div>
                                );
                            }else if (layerName === "tropical_cyclone_watches_warnings") {
                                return(
                                    <div key={index}>
                                        <RenderFeatureInfoTable
                                            advisoryLink={"https://www.nhc.noaa.gov/"}
                                            body={[
                                                ["Storm Name:", feature.properties.stormname],
                                                ["Storm Type:", feature.properties.stormtype],
                                                ["Advisory Date/Time (EDT):", feature.properties.advdate],
                                                ["Issuance Date/Time (UTC):", feature.properties.issuance],
                                                ["Advisory Num: ", feature.properties.advisnu],
                                                ["Storm Num:", feature.properties.stormnum],
                                                ["Basin:", getFullBasinName(feature.properties.basin)],
                                            ]}
                                        />
                                    </div>
                                );
                            }else if (layerName === "tropical_cyclone_observed_wind_extent") {
                                return(
                                    <div style={{padding: '10px'}} key={index}>
                                    <RenderFeatureInfoTable
                                            advisoryLink={"https://www.nhc.noaa.gov/"}
                                            body={[
                                                ["Storm ID:", feature.properties.stormid],
                                                ["Storm Number:", feature.properties.stormnum],
                                                ["Wind Radii(kts):", feature.properties.radii],
                                                ["Basin:", getFullBasinName(feature.properties.basin)],
                                            ]}
                                        />
                                    </div>
                                );
                            }else if (layerName === "tropical_ss") {
                                return(
                                    <div key={index} style={classes.TropicalSSFeatureInfoContainer}>
                                        <br />
                                        <Typography align="center">{TROPICAL_SS_FEATURES[feature.properties.Band1].label}</Typography>
                                        <br />
                                        { props.styleInfo ?
                                            <div style={{textAlign: "center"}}>
                                                <Typography variant="caption">{props.styleInfo[props.activeTropicalSSLayer][0].title}</Typography>
                                                <img style={classes.legendIMG} alt="Legend" src={props.styleInfo[props.activeTropicalSSLayer][0].url} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                );
                            }else{
                                return(null);
                            }
                        })
                    }
                    </div>
                </FeatureInfoAccordion>
            );
        } else {
            return null;
        }
    });

    return(
        <div style={classes.cyclonesFeatureInfoContainer}>
            {content}
        </div>
    );
}
