import { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import MRMSLegend from '../menu/legend-menu-items/mrms-legend.js';
import { getData } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const classes = {
    mrmsFeatureInfoContainer: {
        padding: '10px',
        textAlign: "center"
    }
};

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) styleInfo - legend info that pertains only to MRMS (derived from top-level state obj: styleInfo)
**/
export default function MRMSFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["mrms"].data;
    const clickCoords = useSelector(getMapClickInfo)["mrms"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    // WWA info retrieved when click coordinates change
    useEffect(() => {
        if(!mapClickURLs["mrms"].url) return;

        const fetchData = async () => {
            getData(mapClickURLs["mrms"].url)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"mrms" : {"data" : data, "coords" : mapClickURLs["mrms"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        if(clickCoords.x !== mapClickURLs["mrms"].coords.x || clickCoords.y !== mapClickURLs["mrms"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) {
                dispatch(mapClickInfoUpdated({"mrms" : {"data" : {features: []}, "coords" : mapClickURLs["mrms"].coords}}));
            }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    if (clickData && clickData.features && clickData.features.length === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values were found for this location.</Typography>
            </div>
        );
    }

    if (clickData && clickData.features && clickData.features.length > 0) {
        return(
            <div style={classes.mrmsFeatureInfoContainer}>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10,
                    }}
                >
                 { (props.styleInfo) ?
                    <MRMSLegend
                        MRMSStyleInfo={props.styleInfo}
                    />
                    : null
                }
                </div>
                <br />
                <Typography align="center">{clickData.features[0].properties.Band1} dBZ</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}} align="center">Valid Date/Time:{clickData.timeStamp}</Typography>
                <br />

            </div>
        );
    } else {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>An error occurred.</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>Please try again.</Typography>
            </div>
        );
    }
}