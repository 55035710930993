import { Typography, ListSubheader } from '@mui/material';

const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

// MRMS uses one legend for its one layer BUT it actually uses a layergroup (unlike ndfd) so the parse does not dig into the sub-layers
// where the useful styles are. The parse is hard coded to grab the style for the FIRST sub-layer which happens to be "mrms:mrms_basereflect_conus"
// which much be used here for LAYER_KEY

const LAYER_KEY = "conus_base_reflectivity_mosaic";
// NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]

/**
* Displays legend image with a label above it
*
* @prop (obj) MRMSStyleInfo - legend info that pertains only to OFS surface currents (derived from top-level state obj: styleInfo)
*/
function MRMSLegend(props){

    if (!props.MRMSStyleInfo) return null;

    return (
        <ListSubheader component="div">
            <Typography sx={sxStyles.label}>{props.MRMSStyleInfo[LAYER_KEY][0].title}</Typography>
            <img  style={{ align: 'center', margin: '0px 0px 0px -10px'}} alt="" src={props.MRMSStyleInfo[LAYER_KEY][0].url} />
        </ListSubheader>
    );
}

export default MRMSLegend;