import { Select, InputLabel, MenuItem, Typography, CircularProgress } from '@mui/material';

import { NDFD_LAYER_INFO } from '../../../config.js';
import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import NDFDLegend from '../legend-menu-items/ndfd-legend.js';

// NOTE: once we have developed more content for the menu item body contents, it may be useful to
// create a NowCoastMenuItemBody component that can be shared by all nowcoast menu items to make this
// formatting reusable for each menu item. (assuming they are uniform enough)
const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

/**
* NDFDSelect: Selects current variable to be displayed for ndfd
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state, style and layer params, as well as current source
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function NDFDSelect(props) {

    const ndfdLayerTypeOptions = Object.entries(NDFD_LAYER_INFO).map(([layerType, info],i) => {
        return (
            <MenuItem key={i} value={info.layerName}>{info.label}</MenuItem>
        );
    });

    return (
        <div>
            <InputLabel id="ndfd_select_label" sx={{fontSize: '90%'}}>Select NDFD Variable</InputLabel>
            <Select
                autoWidth
                size="small"
                labelId="ndfd_select_label"
                value={props.olLayerState.ndfd.currentSource}
                onChange={(e) => props.updateOlLayerState({
                    ndfd: {
                        ...props.olLayerState.ndfd,
                        currentSource: e.target.value,
                        layersParam: e.target.value
                    }
                })}
            >
                {ndfdLayerTypeOptions}
            </Select>
        </div>
    );
}

/**
* NDFDLayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (bool) ndfdActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setNdfdActive - callback for setting ndfdActive
*   @prop (obj) opacity - the layer's opacity value (0-100)
*   @prop (func) updateLayerOpacities - callback func for updating opacities
*   @prop (obj) styleInfo - obj containing style info just for NDFD
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state, style and layer params, as well as current source
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function NDFDLayerMenuItem(props){

    return (
        <LayerMenuItem
            layerName={"ndfd"}
            label={"Gridded Weather Forecasts"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.ndfdActive}
            setLayerIsActive={props.setNdfdActive}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"ndfd"}
                    infoContent={<Typography variant="caption">
                        Gridded forecasts of sensible weather variables (e.g., cloud cover, wind speed, significant wave height)
                        generated by the weather forecasters at regional NWS Weather Forecast Offices (WFOs) and the
                        National Centers for Environmental Prediction (NCEP). The forecasts are generated and revised
                        on an event-driven basis by WFOs and on a scheduled basis with updates as needed from NCEP.
                        At a minimum, the forecasts are updated once per day.  The spatial resolution is about 2.5
                        km (1.6 mi) for CONUS, HI, and Guam, 1.25 km (0.8) for PR and 3km (1.9 mi) for Alaska.
                        The forecast horizon is 144 to 168 hours depending on the variable. Data Source: NWS National
                        Digital Forecast Database (NDFD)
                        </Typography>}
                    legendContent={
                        props.styleInfo ?
                            <NDFDLegend
                                activeVariable={props.olLayerState['ndfd'].currentSource} // This hardcoded key originates from config.js
                                ndfdStyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                >
                    <Typography>NWS National Digital Forecast Database</Typography>
                    <NDFDSelect
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default NDFDLayerMenuItem;
