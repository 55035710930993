import { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import LightningDensityLegend from '../menu/legend-menu-items/ltng-den-legend.js';
import { getData } from '../../utilities/utilities.js';
import { mapClickInfoUpdated, getMapClickInfo, getMapClickCoords, getMapClickURLs } from '../../../features/mapClickSlice.js';

const classes ={
    LightningDensityFeatureInfoContainer: {
        padding: '10px'
    },

};

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) styleInfo - legend info that pertains only to NLDN Lightning Density (derived from top-level state obj: styleInfo)
**/
export default function LightningDensityFeatureInfo(props) {
    const mapClickURLs = useSelector(getMapClickURLs);
    const mapClickCoords = useSelector(getMapClickCoords);
    const clickData = useSelector(getMapClickInfo)["ltng_den"].data;
    const clickCoords = useSelector(getMapClickInfo)["ltng_den"].coords;
    const [isDataPending, setDataPending] = useState(() => {
        if(clickCoords.x === mapClickCoords.x && clickCoords.y === mapClickCoords.y) {
            return false;
        } else { return true; }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(!mapClickURLs["ltng_den"].url) return;

        const fetchData = async () => {
            getData(mapClickURLs["ltng_den"].url)
                .then((data) => {
                    dispatch(mapClickInfoUpdated({"ltng_den" : {"data" : data, "coords" : mapClickURLs["ltng_den"].coords}}));
                })
                .finally(() => {
                    setDataPending(false);
                });
        };

        if(clickCoords.x !== mapClickURLs["ltng_den"].coords.x || clickCoords.y !== mapClickURLs["ltng_den"].coords.y) {
            try {
                setDataPending(true);
                fetchData();
            } catch (e) { }
        }
    }, [mapClickURLs, clickCoords, dispatch]);

    if (isDataPending) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    if (clickData.features && clickData.features.length > 0 ) {
        try {
            const pointDataValue = Number.parseFloat(clickData.features[0].properties['Band1']).toFixed(2)
            return(
                <div className={classes.LightningDensityFeatureInfoContainer}>
                    <Typography align="center">15 Min Lightning Strike Density</Typography>
                    <Typography align="center">(strikes/km<sup>2</sup>/min x 10<sup>3</sup>)</Typography>
                    <div style={{
                        borderBottom: '1px solid rgba(0, 0, 0, .125)',
                        marginLeft: 10, marginRight: 10,
                        }}
                    >
                    </div>
                    <br />
                        <Typography sx={{fontSize: '1.0em'}} align="center">{pointDataValue}</Typography>
                    <br />
                    { (props.styleInfo) ?
                        <div align="center">
                            <LightningDensityLegend
                                LightningDensityStyleInfo={props.styleInfo}
                            />
                        </div>
                    : null }
                </div>
            );
        } catch(e) {
            return(
                <div style={{padding: '50px 20px'}}>
                    <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
                </div>
            );
        }
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}